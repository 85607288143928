import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getSubCategoriesSuccess,
  getSubCategoriesFail,
  addSubCategorySuccess,
  addSubCategoryFail,
  getSubCategorySuccess,
  getSubCategoryFail,
  updateSubCategorySuccess,
  updateSubCategoryFail,
  deleteSubCategorySuccess,
  deleteSubCategoryFail,
  subCategoryLoading,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSubCategories,
  addSubCategory,
  updateSubCategory,
  deleteSubCategory,
  getSubCategory,
} from "../../helpers/fakebackend_helper"
import {
  GET_SUBCATEGORIES,
  GET_SUBCATEGORIES_FAIL,
  GET_SUBCATEGORIES_SUCCESS,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY,
  ADD_SUBCATEGORY_FAIL,
  GET_SUBCATEGORY_SUCCESS,
  GET_SUBCATEGORY_FAIL,
  GET_SUBCATEGORY,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAIL,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY,
} from "./actionTypes"

//SUBCATEGORIES
function* getSubCategoriesFetch({ payload: { id } }) {
  try {
    yield put(subCategoryLoading(true))
    var response = yield call(getSubCategories, id)
    if (response.status) {
      yield put(getSubCategoriesSuccess(GET_SUBCATEGORIES_SUCCESS, response.data, response))
    } else {
      yield put(
        getSubCategoriesFail(
          GET_SUBCATEGORIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SUBCATEGORIES-1001"
        )
      )
    }
    yield put(subCategoryLoading(false))
  } catch (error) {
    yield put(getSubCategoriesFail(GET_SUBCATEGORIES_FAIL, error))
    yield put(subCategoryLoading(false))
  }
}

function* addSubCategoryFetch({ payload: { data, history } }) {
  try {
    yield put(subCategoryLoading(true))
    var response = yield call(addSubCategory, data)
    if (response.status) {
      yield put(addSubCategorySuccess(ADD_SUBCATEGORY_SUCCESS, response.data))
      history.push(`/settings/categories/sub/${response.data.Category}`)
    } else {
      yield put(
        addSubCategoryFail(
          ADD_SUBCATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SUBCATEGORIES-1001"
        )
      )
    }

    yield put(subCategoryLoading(false))
  } catch (error) {
    yield put(addSubCategoryFail(ADD_SUBCATEGORY_FAIL, error))
    yield put(subCategoryLoading(false))
  }
}

function* updateSubCategoryFetch({ payload: { id, data, history } }) {
  try {
    yield put(subCategoryLoading(true))
    var response = yield call(updateSubCategory, id, data)
    if (response.status) {
      yield put(updateSubCategorySuccess(UPDATE_SUBCATEGORY_SUCCESS, response.data))
      history.push(`/settings/categories/sub/${response.data.Category}`)
    } else {
      yield put(
        updateSubCategoryFail(
          UPDATE_SUBCATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SUBCATEGORIES-1001"
        )
      )
    }
    yield put(subCategoryLoading(false))
  } catch (error) {
    yield put(updateSubCategoryFail(UPDATE_SUBCATEGORY_FAIL, error))
    yield put(subCategoryLoading(false))
  }
}

function* deleteSubCategoryFetch({ payload: { id, categoryId, history } }) {
  try {
    yield put(subCategoryLoading(true))
    var response = yield call(deleteSubCategory, id)
    if (response.status) {
      yield put(deleteSubCategorySuccess(DELETE_SUBCATEGORY_SUCCESS, response.data))
      history.push(`/settings/categories/sub/${categoryId}`)
    } else {
      yield put(
        deleteSubCategoryFail(
          DELETE_SUBCATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SUBCATEGORIES-1001"
        )
      )
    }
    yield put(subCategoryLoading(false))
  } catch (error) {
    yield put(deleteSubCategoryFail(DELETE_SUBCATEGORY_FAIL, error))
    yield put(subCategoryLoading(false))
  }
}

function* getSubCategoryByIdFetch({ payload: { id } }) {
  try {
    yield put(subCategoryLoading(true))
    var response = yield call(getSubCategory, id)
    if (response.status) {
      yield put(getSubCategorySuccess(GET_SUBCATEGORY_SUCCESS, response.data))
    } else {
      yield put(
        getSubCategoryFail(
          GET_SUBCATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SUBCATEGORIES-1001"
        )
      )
    }
    yield put(subCategoryLoading(false))
  } catch (error) {
    yield put(getSubCategoryFail(GET_SUBCATEGORY_FAIL, error))
    yield put(subCategoryLoading(false))
  }
}

function* subCategoriesSaga() {
  yield takeEvery(GET_SUBCATEGORIES, getSubCategoriesFetch)
  yield takeEvery(GET_SUBCATEGORY, getSubCategoryByIdFetch)
  yield takeEvery(ADD_SUBCATEGORY, addSubCategoryFetch)
  yield takeEvery(UPDATE_SUBCATEGORY, updateSubCategoryFetch)
  yield takeEvery(DELETE_SUBCATEGORY, deleteSubCategoryFetch)
}

export default subCategoriesSaga
