import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import {
  getProduct as onGet,
  updateProduct as onSave,
  deleteProduct as onDelete,
  productsResetError as onClearError,
  getBrands as onBrands,
  getClassNames as onClassNames,
  getCategories as onCategories,
  clearProduct as onClearProduct,
  getSubCategories as onSubCategories,
  subCategoriesResetAll as onSubCategoriesReset,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import { isEmpty } from "lodash"
import { default_variant } from "common/data/_terms"
import Loading from "components/Common/Loading"

const ProductEdit = props => {
  //meta title
  document.title = props.t("Edit Product")

  const [data, setData] = useState(null)

  const breadcrumbItems = [
    { title: props.t("Products"), link: "/v1/products" },
    { title: props.t("Edit Product"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const {
    error,
    loading,
    product,
    brands,
    classNames,
    categories,
    subCategories,
  } = useSelector(state => ({
    error: state.Products?.error,
    loading: state.Products?.loading || false,
    product: state.Products?.product,
    brands: state.Brands?.data || [],
    classNames: state.ClassNames?.data || [],
    categories: state.Categories?.data || [],
    subCategories: state.SubCategories?.data || [],
  }))
  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(brands)) {
      dispatch(onBrands())
    }
    if (isEmpty(classNames)) {
      dispatch(onClassNames())
    }
    if (isEmpty(categories)) {
      dispatch(onCategories())
      dispatch(onSubCategoriesReset())
    }
  }, [])

  useEffect(() => {
    if (params.id) {
      if (isEmpty(product) && isEmpty(data)) {
        dispatch(onGet(params.id))
      } else if (!isEmpty(product) && isEmpty(data)) {
        const temp_data = { ...product }
        setData(temp_data)
        for (let key of Object.keys(validation.values)) {
          try {
            console.log(key, temp_data[key])
            validation.setFieldValue(key, temp_data[key])
            // if (key != "Variants") {
            //   validation.setFieldValue(key, temp_data[key])
            // }
            // if (key == "Category" && !isEmpty(temp_data[key])) {
            //   dispatch(onSubCategories(temp_data[key]))
            // }
          } catch (err) {
            console.error("key converter failed", key, err)
          }
        }
        setTimeout(() => {
          dispatch(onClearProduct())
        }, 300)
      }
    }
  }, [product])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      Brand: "",
      ClassName: "",
      Category: "",
      SubCategory: "",
      sku: "",
      Variants: [
        {
          _id: "",
          barcode: "",
          name: "",
        },
      ],
    },
    validationSchema: Yup.object({
      Brand: Yup.string().required(props.t("Required")),
      name: Yup.string().required(props.t("Required")),
      // className: Yup.string().required(props.t("Required")),
      // category: Yup.string().required(props.t("Required")),
      // subCategory: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values)
      dispatch(onSave(params.id, values, props.history))
      //props.history.push(`/v1/products/create/step2/01`)
    },
  })

  useEffect(() => {
    dispatch(onSubCategoriesReset())
    if (!isEmpty(categories) && validation.values.Category) {
      dispatch(onSubCategories(validation.values.Category))
    }
  }, [categories, validation.values.Category])

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Products")}
            backLink={"/v1/products"}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("Edit Product")}</h4>
                  <p className="card-title-desc">
                    {props.t("Edit Product Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">
                            {props.t("Product Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("Product Name")}
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="Brand">{props.t("Brand")}</Label>
                          <select
                            id="Brand"
                            className={
                              validation.touched.Brand &&
                              validation.errors.Brand
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="Brand"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Brand || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {brands?.map((item, idx) => (
                              <option key={idx} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.Brand &&
                          validation.errors.Brand ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Brand}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="ClassName">{props.t("Class")}</Label>
                          <select
                            id="ClassName"
                            className={
                              validation.touched.ClassName &&
                              validation.errors.ClassName
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="ClassName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ClassName || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {classNames?.map((item, idx) => (
                              <option key={idx} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.ClassName &&
                          validation.errors.ClassName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ClassName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="Category">
                            {props.t("Category")}
                          </Label>
                          <select
                            id="Category"
                            className={
                              validation.touched.Category &&
                              validation.errors.Category
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="Category"
                            onChange={e => {
                              validation.setFieldValue("SubCategory", null)
                              validation.handleChange(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.Category || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {categories?.map((item, idx) => (
                              <option key={idx} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.Category &&
                          validation.errors.Category ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Category}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="SubCategory">
                            {props.t("SubCategory")}
                          </Label>
                          <select
                            id="SubCategory"
                            className={
                              validation.touched.SubCategory &&
                              validation.errors.SubCategory
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="SubCategory"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.SubCategory || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {subCategories?.map((item, idx) => (
                              <option key={idx} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.SubCategory &&
                          validation.errors.SubCategory ? (
                            <FormFeedback type="invalid">
                              {validation.errors.SubCategory}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="sku">{props.t("SKU")}</Label>
                          <Input
                            name="sku"
                            placeholder={props.t("SKU")}
                            type="text"
                            className="form-control"
                            id="sku"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sku || ""}
                            invalid={
                              validation.touched.sku && validation.errors.sku
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sku && validation.errors.sku ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sku}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    {validation.values.Variants.map((variant, idx) => (
                      <Row key={idx}>
                        <Col md="4">
                          <FormGroup className="mb-3 relative">
                            <Label htmlFor={`Variants[${idx}].name`}>
                              {props.t("Variant Title")}
                            </Label>
                            <Input
                              placeholder={props.t("Variant")}
                              type="text"
                              className="form-control"
                              id={`Variants[${idx}].name`}
                              name={`Variants[${idx}].name`}
                              onChange={e => {
                                validation.handleChange(e)
                                validation.setFieldValue(
                                  `Variants[${idx}].name`,
                                  e.target.value
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.Variants[idx].name || ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3 relative">
                            <Label htmlFor={`Variants[${idx}].barcode`}>
                              {props.t("Barcode")}
                            </Label>
                            <Input
                              placeholder={props.t("Barcode")}
                              type="text"
                              className="form-control"
                              id={`Variants[${idx}].barcode`}
                              name={`Variants[${idx}].barcode`}
                              onChange={e => {
                                validation.handleChange(e)
                                validation.setFieldValue(
                                  `Variants[${idx}].barcode`,
                                  e.target.value
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.Variants[idx].barcode || ""
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="1" className="me-3 mb-3">
                          <Label>{props.t("Add or Remove")}</Label>
                          <div className="d-flex">
                            <a
                              className="me-2 btn-add"
                              onClick={() => {
                                let newObj = default_variant
                                let newList = insert(
                                  validation.values.Variants,
                                  idx + 1,
                                  newObj
                                )
                                validation.setFieldValue("Variants", newList)
                              }}
                            >
                              <img />
                            </a>
                            {idx > 0 ? (
                              <a
                                className="btn-remove"
                                onClick={() => {
                                  let newList = [...validation.values.Variants]
                                  newList.splice(idx, 1)
                                  validation.setFieldValue("Variants", newList)
                                }}
                              >
                                <img />
                              </a>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    ))}

                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                    <Button
                      color="danger"
                      className="ms-2"
                      onClick={() => {
                        Swal.fire({
                          title: props.t("Are You Sure"),
                          text: props.t("DeleteWarning"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: props.t("Yes, delete it!"),
                          cancelButtonText: props.t("Cancel"),
                        }).then(result => {
                          if (result.isConfirmed) {
                             dispatch(onDelete(params.id, props.history))
                          }
                        }) 
                      }}
                    >
                      {props.t("Delete")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(ProductEdit)
