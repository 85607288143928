import { 
  BRANDS_RESET_ERROR,
  BRANDS_RESET, 
  BRANDS_RESET_SUCCESS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL, 
  ADD_BRAND_SUCCESS,
  UPDATE_BRAND_SUCCESS,
  DELETE_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND_FAIL,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  BRAND_RESET,
  BRAND_LOADING, 
} from "./actionTypes"

const INIT_STATE = {
  data: [], 
  brand: {}, 
  error: null,
  success: null,
  loading: true,
}

const Brands = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data, 
      }
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload?.data,
        loading: false
      }
    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }  
    case GET_BRANDS_FAIL: 
    case GET_BRAND_FAIL:
    case ADD_BRAND_FAIL:
    case UPDATE_BRAND_FAIL:
    case DELETE_BRAND_FAIL: 
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case BRANDS_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case BRANDS_RESET_ERROR:
      return {
        ...state,
        error: null,
      } 
    case BRAND_RESET:
      return {
        ...state,
        brand: {},
        branch: {},
      }
    case BRANDS_RESET:
      return {
        ...state,
        data: [],
        brand: null, 
        error: null,
        success: null, 
      }
    case BRAND_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default Brands
