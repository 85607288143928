export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES"
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS"
export const GET_SUBCATEGORIES_FAIL = "GET_SUBCATEGORIES_FAIL"

export const GET_SUBCATEGORY = "GET_SUBCATEGORY"
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS"
export const GET_SUBCATEGORY_FAIL = "GET_SUBCATEGORY_FAIL"

export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY"
export const ADD_SUBCATEGORY_SUCCESS = "ADD_SUBCATEGORY_SUCCESS"
export const ADD_SUBCATEGORY_FAIL = "ADD_SUBCATEGORY_FAIL"

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY"
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS"
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL"

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY"
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS"
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL"
 
export const SUBCATEGORY_RESET = "SUBCATEGORY_RESET"
export const SUBCATEGORIES_RESET_ERROR = "SUBCATEGORIES_RESET_ERROR"
export const SUBCATEGORIES_RESET = "SUBCATEGORIES_RESET"
export const SUBCATEGORIES_RESET_SUCCESS = "SUBCATEGORIES_RESET_SUCCESS"


export const SUBCATEGORY_LOADING = "SUBCATEGORY_LOADING"
