import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import LoginBranch from "../pages/Authentication/LoginBranch"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePasswordPage from "../pages/Authentication/ChangePassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Campaigns from "../pages/Campaigns/v1/index"
import CampaignDetails from "../pages/Campaigns/v1/details"
import CampaignBranches from "../pages/Campaigns/v1/branches"
import CampaignCreate from "../pages/Campaigns/v1/create"
import CampaignUpdate from "../pages/Campaigns/v1/update"
import CampaignCreateStep2 from "../pages/Campaigns/v1/create-step2"
import CampaignBranchesStep3 from "../pages/Campaigns/v1/campaignBranches-step3"
import CampaignProductsStep4 from "../pages/Campaigns/v1/product-step4"
import CampaignNewData from "../pages/Campaigns/v1/new-data"
import CampaignNewDataList from "../pages/Campaigns/v1/new-data-list"
import CampaignNewDataListControl from "../pages/Campaigns/v1/new-data-list-control"

import PuankList from "../pages/Campaigns/v1/puank-list"
import PuankListUserHistory from "../pages/Campaigns/v1/puank-list-user-history"

import Products from "../pages/Products/v1/index"
import ProductCreate from "../pages/Products/v1/create"
import ProductEdit from "../pages/Products/v1/edit"

import VariantsV2 from "../pages/Products/v2/variants/index"
import Variants from "../pages/Products/v1/variants/index"
import VariantEdit from "../pages/Products/v1/variants/edit"
import VariantCreate from "../pages/Products/v1/variants/create"

import Series from "../pages/Products/v1/series/index"
import SeriesCreate from "../pages/Products/v1/series/create"
import SeriesEdit from "../pages/Products/v1/series/edit"

import Companies from "../pages/Companies/v1/index"
import CompanyCreate from "../pages/Companies/v1/create"
import CompanyEdit from "../pages/Companies/v1/edit"

import Branches from "../pages/Companies/Branches/v1/index"
import BranchCreate from "../pages/Companies/Branches/v1/create"
import BranchEdit from "../pages/Companies/Branches/v1/edit"

import Persons from "../pages/Companies/Persons/v1/index"
import PersonCreate from "../pages/Companies/Persons/v1/create"
import PersonEdit from "../pages/Companies/Persons/v1/edit"

import Settings from "../pages/Settings/settings"
import ClassNames from "../pages/Settings/ClassName/v1/index"
import ClassNameCreate from "../pages/Settings/ClassName/v1/create"
import ClassNameEdit from "../pages/Settings/ClassName/v1/edit"

import Categories from "../pages/Settings/Categories/v1/index"
import CategoryCreate from "../pages/Settings/Categories/v1/create"
import CategoryEdit from "../pages/Settings/Categories/v1/edit"

import SubCategories from "../pages/Settings/SubCategories/v1/index"
import SubCategoryCreate from "../pages/Settings/SubCategories/v1/create"
import SubCategoryEdit from "../pages/Settings/SubCategories/v1/edit"

import BranchGroups from "../pages/Companies/Group/v1/index"
import BranchGroupCreate from "../pages/Companies/Group/v1/create"
import BranchGroupEdit from "../pages/Companies/Group/v1/edit"

import { Role, authorize } from "helpers/authorize"

const dev_routes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/settings", component: Settings },
  { path: "/settings/class-names", component: ClassNames },
  { path: "/settings/class-names/create", component: ClassNameCreate },
  { path: "/settings/class-names/edit/:id", component: ClassNameEdit },
  { path: "/settings/categories", component: Categories },
  { path: "/settings/categories/create", component: CategoryCreate },
  { path: "/settings/categories/edit/:id", component: CategoryEdit },
  {
    path: "/settings/categories/sub/create/:id",
    component: SubCategoryCreate,
  },
  {
    path: "/settings/categories/sub/edit/:categoryId/:id",
    component: SubCategoryEdit,
  },
  { path: "/settings/categories/sub/:id", component: SubCategories },

  { path: "/settings", component: ClassNames },
  { path: "/settings/branch-groups", component: BranchGroups },
  { path: "/settings/branch-groups/create", component: BranchGroupCreate },
  { path: "/settings/branch-groups/edit/:id", component: BranchGroupEdit },

  { path: "/v1/campaigns", component: Campaigns },
  {
    path: "/v1/campaigns/create/step2/:id",
    component: CampaignCreateStep2,
  },
  {
    path: "/v1/campaigns/create/step3/:id",
    component: CampaignBranchesStep3,
  },
  {
    path: "/v1/campaigns/create/step4/:id",
    component: CampaignProductsStep4,
  },
  { path: "/v1/campaigns/update/:id", component: CampaignUpdate },
  // { path: "/v1/campaigns/new-data/:id", component: CampaignNewData },
  {
    path: "/v1/campaigns/new-data-list/:id",
    component: CampaignNewDataList,
  },
  {
    path: "/v1/campaigns/new-data-list-control/:id",
    component: CampaignNewDataListControl,
  },
  {
    path: "/v1/campaigns/puank-list/:id",
    component: PuankList,
  },  
  {
    path: "/v1/profile/puank-list-user-history",
    component: PuankListUserHistory,
  },
  { path: "/v1/campaigns/create", component: CampaignCreate },
  { path: "/v1/campaigns/:id", component: CampaignDetails },
  { path: "/v1/campaigns/branches/:id", component: CampaignBranches },

  { path: "/v1/products", component: Products },
  { path: "/v1/products/create", component: ProductCreate },
  { path: "/v1/products/edit/:id", component: ProductEdit },

  { path: "/v1/products/variants/series/:id", component: Series },
  {
    path: "/v1/products/variants/series/create/:id",
    component: SeriesCreate,
  },
  { path: "/v1/products/variants/series/edit/:id", component: SeriesEdit },

  { path: "/v2/products/variants/:id", component: VariantsV2 },
  { path: "/v1/products/variants/:id", component: Variants },
  { path: "/v1/products/variants/create/:id", component: VariantCreate },
  { path: "/v1/products/variants/edit/:id", component: VariantEdit },

  { path: "/v1/companies", component: Companies },
  { path: "/v1/companies/create", component: CompanyCreate },
  { path: "/v1/companies/edit/:id", component: CompanyEdit },

  { path: "/v1/companies/branches/:id", component: Branches },
  { path: "/v1/companies/branch/create/:id", component: BranchCreate },
  { path: "/v1/companies/branch/edit/:id", component: BranchEdit },
  //companies/person/edit/
  { path: "/v1/companies/persons", component: Persons },
  { path: "/v1/companies/person/create/:id", component: PersonCreate },
  { path: "/v1/companies/person/edit/:id", component: PersonEdit },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/v1/campaigns" />,
  },
]

const admin_routes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/settings", component: Settings },
  { path: "/settings", component: ClassNames },
  { path: "/v1/campaigns", component: Campaigns },
  { path: "/v1/campaigns/new-data/:id", component: CampaignNewData },
  {
    path: "/v1/campaigns/new-data-list/:id",
    component: CampaignNewDataList,
  },
  { path: "/v1/campaigns/:id", component: CampaignDetails },

  { path: "/v1/companies", component: Companies },
  { path: "/v1/companies/branches/:id", component: Branches },
  { path: "/v1/companies/branch/create/:id", component: BranchCreate },
  { path: "/v1/companies/branch/edit/:id", component: BranchEdit },
  //companies/person/edit/
  { path: "/v1/companies/persons", component: Persons },
  { path: "/v1/companies/person/create/:id", component: PersonCreate },
  { path: "/v1/companies/person/edit/:id", component: PersonEdit },

  {
    path: "/v1/campaigns/puank-list/:id",
    component: PuankList,
  }, 
  {
    path: "/v1/profile/puank-list-user-history",
    component: PuankListUserHistory,
  },
  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/v1/campaigns" />,
  },
]

const manager_routes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/settings", component: Settings },
  { path: "/settings", component: ClassNames },
  { path: "/v1/campaigns", component: Campaigns },
  { path: "/v1/campaigns/new-data/:id", component: CampaignNewData },
  {
    path: "/v1/campaigns/new-data-list/:id",
    component: CampaignNewDataList,
  },
  { path: "/v1/campaigns/:id", component: CampaignDetails },

  { path: "/v1/companies", component: Companies },
  //companies/person/edit/
  { path: "/v1/companies/persons", component: Persons },

  {
    path: "/v1/campaigns/puank-list/:id",
    component: PuankList,
  }, 
  {
    path: "/v1/profile/puank-list-user-history",
    component: PuankListUserHistory,
  },
  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/v1/campaigns" />,
  },
]

const user_routes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/settings", component: Settings },
  { path: "/settings", component: ClassNames },
  { path: "/v1/campaigns", component: Campaigns },
  { path: "/v1/campaigns/new-data/:id", component: CampaignNewData },
  {
    path: "/v1/campaigns/new-data-list/:id",
    component: CampaignNewDataList,
  },
  { path: "/v1/campaigns/:id", component: CampaignDetails },

  // //profile
  { path: "/profile", component: UserProfile },

  {
    path: "/v1/campaigns/puank-list/:id",
    component: PuankList,
  }, 
  {
    path: "/v1/profile/puank-list-user-history",
    component: PuankListUserHistory,
  },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/v1/campaigns" />,
  },
]

const authProtectedRoutes = authorize([Role.Dev])
  ? dev_routes
  : authorize([Role.Admin])
  ? admin_routes
  : authorize([Role.Manager, Role.Coordinator])
  ? manager_routes
  : user_routes

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/login-branch", component: LoginBranch },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/changePassword/:id/:hash", component: ChangePasswordPage },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
