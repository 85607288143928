import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userChangePassword as onChange } from "store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { useState } from "react"
import Loading from "components/Common/Loading"
import { useEffect } from "react"
import Swal from "sweetalert2"
import axios from "axios"
import { isEmpty } from "lodash"

const ChangePasswordPage = props => {
  //meta title
  document.title = "Şifre Yenile"

  const dispatch = useDispatch()
  const [checkHash, setCheckHash] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const {
    match: { params },
  } = props
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      re_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(props.t("Please Enter Your Password"))
        .min(4, props.t("Minimum 4 Character")),
      re_password: Yup.string()
        .required(props.t("Please Enter Your Password"))
        .min(4, props.t("Minimum 4 Character"))
        .oneOf([Yup.ref("password"), null], props.t("Passwords must match")),
    }),
    onSubmit: values => {
      let v = { ...values }
      v["id"] = params.id
      v["hash"] = params.hash
      dispatch(onChange(v, props.history))
    },
  })

  const { changeError, changeSuccessMsg } = useSelector(state => ({
    changeError: state.ChangePassword.changeError,
    changeSuccessMsg: state.ChangePassword.changeSuccessMsg,
  }))

  useEffect(() => {
    onCheckControl(params.id, params.hash)
  }, [])

  const onCheckControl = async (id, hash) => {
    setLoading(true)
    let data = JSON.stringify({
      id: id,
      hash: hash,
    })
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/auth/check-hash`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          setCheckHash(true)
        } else {
          setCheckHash(false)
          setError(error.response?.data?.message)
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading(false)
      })
      .catch(error => {
        setCheckHash(false)
        setLoading(false)
        console.error(error)
        setError(error.response?.data?.message)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden"> 
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("APPNAME")}</h5>
                        <p>{props.t("Reset Password")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {(changeError && changeError) || !isEmpty(error) ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {changeError || error}
                      </Alert>
                    ) : null}
                    {changeSuccessMsg && changeSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {changeSuccessMsg}
                      </Alert>
                    ) : null}
                    <Loading status={loading} />
                    {checkHash && checkHash ? (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("New Password")}
                          </Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder={props.t("Enter New Password")}
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("RePassword")}
                          </Label>
                          <Input
                            name="re_password"
                            className="form-control"
                            placeholder={props.t("Enter RePassword")}
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.re_password || ""}
                            invalid={
                              validation.touched.re_password &&
                              validation.errors.re_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.re_password &&
                          validation.errors.re_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.re_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              {props.t("Reset Password")}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  <Link to="/login" className="font-weight-medium text-primary">
                    {props.t("Login 2")}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {props.t("copyright")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ChangePasswordPage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(ChangePasswordPage)
