import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { persons } from "./../../../../common/data/companies"
import { useDispatch, useSelector } from "react-redux"
import { getPersons as onList } from "store/actions"
import TableContainerServerSide from "components/Common/TableContainerServerSide"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import TableContainer from "../../../../components/Common/TableContainer"
import { isEmpty } from "lodash"
import Swal from "sweetalert2"
import { PERSON_TYPES, Role, authorize } from "helpers/authorize"

const Persons = props => {
  //meta title
  document.title = props.t("Persons")

  const dispatch = useDispatch()
  const { error, persons, personsTableOptions, loading } = useSelector(
    state => ({
      error: state.Companies?.error,
      persons: state.Companies?.persons || [],
      personsTableOptions: state.Companies?.personsTableOptions || null,
      loading: state.Companies?.loading || false,
    })
  )

  const breadcrumbItems = [
    { title: props.t("Companies"), link: "/v1/companies" },
    { title: props.t("Persons"), link: "/" },
  ]

  const [selectedItem, setSelectedItem] = useState("all")
  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 100,
    page: 0,
    select: "name personType Branch",
    search: "",
  })

  const [data, setData] = useState([])
  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  // useEffect(() => {
  //   if (
  //     isEmpty(data) &&
  //     !isEmpty(query.get("cid")) &&
  //     !isEmpty(query.get("bid"))
  //   ) {
  //     setData(
  //       persons.filter(
  //         x => x.companyId == query.get("cid") && x.Branch._id == query.get("bid")
  //       )
  //     )
  //   } else if (isEmpty(data) && !isEmpty(query.get("cid"))) {
  //     setData(persons.filter(x => x.companyId == query.get("cid")))
  //   } else if (isEmpty(data) && !isEmpty(query.get("bid"))) {
  //     setData(persons.filter(x => x.Branch._id == query.get("bid")))
  //   }
  // }, [])

  useEffect(() => {
    dispatch(onList(query.get("bid"), query.get("cid"), tableSetting))
  }, [tableSetting])

  useEffect(() => {
    setData(persons)
  }, [persons])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      ).then(isConfirmed => {
        if (isConfirmed) {
          location.reload()
        }
      })
      dispatch(onClearError())
    }
  }, [error])

  const columns = useMemo(
    () => [
      {
        Header: props.t("Person Name"),
        accessor: "name",
        search: false,
      },
      {
        Header: props.t("PersonType"),
        accessor: "personType",
        search: false,
        Cell: ({ value }) => (
          <span>{PERSON_TYPES.find(x => x.value == value)?.labelTR}</span>
        ),
      },
      {
        Header: "",
        search: false,
        accessor: "Branches",
        Cell: ({ value }) => (
          <div>
            {value?.map((branch, idx) => (
              <a
                key={idx}
                className="me-2"
                href={`/v1/companies/persons?cid=${
                  query.get("cid") || params.id
                }&bid=${branch._id}`}
              >
                {branch.name + (value.length - 1 > idx ? "," : "")}
              </a>
            ))}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {authorize([Role.Dev, Role.Admin]) ? (
              <>
                <Link
                  to={`/v1/companies/person/edit/${value}?${
                    !isEmpty(query.get("cid"))
                      ? `cid=${query.get("cid")}${
                          !isEmpty(query.get("bid")) ? "&" : ""
                        }`
                      : ""
                  }${
                    !isEmpty(query.get("bid")) ? `bid=${query.get("bid")}` : ""
                  }`}
                  className="btn btn-sm btn-warning text-white me-2"
                >
                  {props.t("Edit")}
                </Link>
              </>
            ) : null}
          </div>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Persons")}
            backLink={
              !isEmpty(query.get("cid")) && !isEmpty(query.get("bid"))
                ? `/v1/companies/branches/${query.get("cid")}`
                : "/v1/companies"
            }
            breadcrumbItems={breadcrumbItems}
            isAdd={authorize([Role.Dev, Role.Admin])}
            addTitle={props.t("Create Person")}
            addHandle={() => {
              props.history.push(
                "/v1/companies/person/create/" +
                  query.get("cid") +
                  (!isEmpty(query.get("bid")) ? `?bid=${query.get("bid")}` : "")
              )
            }}
            className={"mb-0 pb-0"}
          />

          <TableContainerServerSide
            loading={loading}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            tableSetting={tableSetting}
            customPageSize={tableSetting.limit}
            className="custom-header-css"
            totalCount={personsTableOptions?.productTotal || 0}
            queryPageIndex={tableSetting.page}
            queryPageSize={tableSetting.limit}
            tableOptions={personsTableOptions}
            action={(pageIndex, pageSize, search) => {
              setTableSetting({
                orderBy: "createdAt",
                ascDesc: -1,
                limit: pageSize,
                page: pageIndex,
                select: tableSetting.select,
                search: search,
              })
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Persons.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(Persons)
