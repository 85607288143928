import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { branches } from "./../../../../common/data/companies"
import { useDispatch, useSelector } from "react-redux"
import {
  getSubCategories as onList,
  getCategory as onCategory,
} from "store/actions"
import TableContainerServerSide from "components/Common/TableContainerServerSide"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import TableContainer from "../../../../components/Common/TableContainer"
import { isEmpty } from "lodash"

const SubCategories = props => {
  //meta title
  document.title = props.t("SubCategories")

  const dispatch = useDispatch()
  const { error, category, subCategories, loading } = useSelector(state => ({
    error: state.SubCategories?.error,
    category: state.Categories?.category || [],
    subCategories: state.SubCategories?.data || [],
    loading: state.SubCategories?.loading || false,
  }))

  const breadcrumbItems = [
    { title: props.t("Settings"), link: "/settings" },
    { title: props.t("Companies"), link: "/settings/categories" },
    { title: props.t("SubCategories"), link: "/" },
  ]

  const [selectedItem, setSelectedItem] = useState("all")
  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 5,
    page: 0,
    select: "name",
    search: "",
  })
  const [data, setData] = useState([])
  const [load, setLoad] = useState(false)

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (isEmpty(category)) {
      dispatch(onCategory(params.id))
    }
  }, [])

  useEffect(() => {
    if (isEmpty(subCategories) && load == false) {
      dispatch(onList(params.id))
      setLoad(true)
    } else {
      setData(subCategories)
    }
  }, [subCategories])

  // useEffect(()=>{
  //   if(isEmpty(data) && !isEmpty(params.id)){
  //     setData(branches.filter(x=>x.companyId == params.id))
  //   }
  // },[])

  const columns = useMemo(
    () => [
      {
        Header: props.t("SubCategory Name"),
        accessor: "name",
        search: false,
      },
      {
        Header: "",
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            <Link
              to={`/settings/categories/sub/edit/${params.id}/${value}`}
              className="btn btn-sm btn-warning text-white me-2"
            >
              {props.t("Edit")}
            </Link>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={
              props.t("SubCategories") +
              `${category ? ` (${category?.name})` : ""}`
            }
            backLink={"/settings/categories"}
            breadcrumbItems={breadcrumbItems}
            isAdd={true}
            addTitle={props.t("Create SubCategory")}
            addHandle={() => {
              props.history.push(`/settings/categories/sub/create/${params.id}`)
            }}
            className={"mb-0 pb-0"}
          />
          {/* <TableContainerServerSide
            loading={loading}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            tableSetting={tableSetting}
            customPageSize={tableSetting.limit}
            className="custom-header-css"
            totalCount={classNamesTableOptions?.productTotal || 0}
            queryPageIndex={tableSetting.page}
            queryPageSize={tableSetting.limit}
            tableOptions={classNamesTableOptions}
            action={(pageIndex, pageSize, search) => {
              setTableSetting({
                orderBy: "createdAt",
                ascDesc: -1,
                limit: pageSize,
                page: pageIndex,
                select: tableSetting.select,
                search: search,
              })
            }}
          /> */}
 
          <TableContainer
            loading={loading}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

SubCategories.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(SubCategories)
