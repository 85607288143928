import PropTypes from "prop-types"
import React from "react"
import { Col } from "reactstrap"
const Loading = ({ status }) => {
  return status ? (
    <div
      style={{
        position: "absolute",
        zIndex: 99,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#b5b5b529",
      }}
    >
      <div id="preloader2">
        <div id="status2">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    </div>
  ) : null
}
Loading.propTypes = {
  status: PropTypes.bool,
}

export default Loading
