import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

import { 
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  
  try {
    const response = yield call(postJwtForgetPwd, {
      email: user.email,
    })
    console.log(response)
    if (response.data.status == true) {
      yield put(userForgetPasswordSuccess(response.data.message))
    } else {
      yield put(userForgetPasswordError(response.data.message || "Hata Oluştu"))
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* ForgetSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export default ForgetSaga
