import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  NOTIFICATION_MINI, 
} from "./actionTypes"
import { 
  notificationApiError,
  notificationMiniUserSuccess, 
} from "./actions"
 
import {
  getNotifications
} from "../../../helpers/fakebackend_helper"
 
 

function* notificationMiniFetch({ payload: { user, history, goto } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(getNotifications, 0, 5)
      if (response.status == true) { 
        yield put(notificationMiniUserSuccess(response)) 
      } else {
        yield put(notificationApiError(response.message || "Hata Oluştu"))
      }
    }
  } catch (error) {
    yield put(notificationApiError(error))
  }
}
 
 

function* NotificationSaga() {
  yield takeEvery(NOTIFICATION_MINI, notificationMiniFetch) 
}

export default NotificationSaga
