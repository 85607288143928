import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getCategoriesSuccess,
  getCategoriesFail,
  addCategorySuccess,
  addCategoryFail,
  getCategorySuccess,
  getCategoryFail,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
  categoryLoading,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
  getCategory,
} from "../../helpers/fakebackend_helper"
import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY,
} from "./actionTypes"

//CATEGORIES
function* getCategoriesFetch({ payload: {  } }) {
  try {
    yield put(categoryLoading(true))
    var response = yield call(getCategories)
    if (response.status) {
      yield put(getCategoriesSuccess(GET_CATEGORIES_SUCCESS, response.data, response))
    } else {
      yield put(
        getCategoriesFail(
          GET_CATEGORIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CATEGORIES-1001"
        )
      )
    }
    yield put(categoryLoading(false))
  } catch (error) {
    yield put(getCategoriesFail(GET_CATEGORIES_FAIL, error))
    yield put(categoryLoading(false))
  }
}

function* addCategoryFetch({ payload: { data, history } }) {
  try {
    yield put(categoryLoading(true))
    var response = yield call(addCategory, data)
    if (response.status) {
      yield put(addCategorySuccess(ADD_CATEGORY_SUCCESS, response.data))
      history.push("/settings/categories")
    } else {
      yield put(
        addCategoryFail(
          ADD_CATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CATEGORIES-1001"
        )
      )
    }

    yield put(categoryLoading(false))
  } catch (error) {
    yield put(addCategoryFail(ADD_CATEGORY_FAIL, error))
    yield put(categoryLoading(false))
  }
}

function* updateCategoryFetch({ payload: { id, data, history } }) {
  try {
    yield put(categoryLoading(true))
    var response = yield call(updateCategory, id, data)
    if (response.status) {
      yield put(updateCategorySuccess(UPDATE_CATEGORY_SUCCESS, response.data))
      history.push("/settings/categories")
    } else {
      yield put(
        updateCategoryFail(
          UPDATE_CATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CATEGORIES-1001"
        )
      )
    }
    yield put(categoryLoading(false))
  } catch (error) {
    yield put(updateCategoryFail(UPDATE_CATEGORY_FAIL, error))
    yield put(categoryLoading(false))
  }
}

function* deleteCategoryFetch({ payload: { id, history } }) {
  try {
    yield put(categoryLoading(true))
    var response = yield call(deleteCategory, id)
    if (response.status) {
      yield put(deleteCategorySuccess(DELETE_CATEGORY_SUCCESS, response.data))
      history.push("/settings/categories")
    } else {
      yield put(
        deleteCategoryFail(
          DELETE_CATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CATEGORIES-1001"
        )
      )
    }
    yield put(categoryLoading(false))
  } catch (error) {
    yield put(deleteCategoryFail(DELETE_CATEGORY_FAIL, error))
    yield put(categoryLoading(false))
  }
}

function* getCategoryByIdFetch({ payload: { id } }) {
  try {
    yield put(categoryLoading(true))
    var response = yield call(getCategory, id)
    if (response.status) {
      yield put(getCategorySuccess(GET_CATEGORY_SUCCESS, response.data))
    } else {
      yield put(
        getCategoryFail(
          GET_CATEGORY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CATEGORIES-1001"
        )
      )
    }
    yield put(categoryLoading(false))
  } catch (error) {
    yield put(getCategoryFail(GET_CATEGORY_FAIL, error))
    yield put(categoryLoading(false))
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, getCategoriesFetch)
  yield takeEvery(GET_CATEGORY, getCategoryByIdFetch)
  yield takeEvery(ADD_CATEGORY, addCategoryFetch)
  yield takeEvery(UPDATE_CATEGORY, updateCategoryFetch)
  yield takeEvery(DELETE_CATEGORY, deleteCategoryFetch)
}

export default categoriesSaga
