import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { products } from "./../../../common/data/product"
import {
  getProducts as onGet,
  productsResetError as onClearError,
  productsResetSuccess as onSuccessReset,
  importXLSXProducts as onImportXLSX,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import TableContainer from "../../../components/Common/TableContainer"
import Loading from "components/Common/Loading"
import { isEmpty } from "lodash"
import Swal from "sweetalert2"
import TableContainerServerSide from "components/Common/TableContainerServerSide"

const Products = props => {
  //meta title
  document.title = props.t("Products")

  const breadcrumbItems = [{ title: props.t("Products"), link: "/" }]

  const [selectedItem, setSelectedItem] = useState("all")

  // const [data, setData] = useState(products)

  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 5,
    page: 0,
    select: "name",
    search: "",
  })

  const dispatch = useDispatch()
  const { error, loading, importData, data, tableOptions } = useSelector(state => ({
    error: state.Products?.error,
    loading: state.Products?.loading || false,
    importData: state.Products?.importData,
    data: state.Products?.data,
    tableOptions: state.Products?.tableOptions
  }))

  // useEffect(() => {
  //   if (isEmpty(data)) {
  //     dispatch(onGet(tableSetting))
  //   } 
  // }, [])
  useEffect(() => {
    dispatch(onGet(tableSetting))
  }, [tableSetting])

  useEffect(() => {
    if (!isEmpty(importData)) {
      let errorHTML = ""
      if (importData?.successCount > 0) {
        errorHTML = `${importData?.successCount} Adet Ürün Kaydedildi <br />`
      }
      errorHTML += `<ul class="nodot">`
      for (let err of importData?.failed) {
        errorHTML += `<li>${err.product["Barkod"]} - ${err.product["Seri No"]} - ${err.product["Ürün Adı"]}  <b>Hata: ${err.error}</b></li>`
      }
      errorHTML += `</ul>`
      Swal.fire(
        importData?.failed?.length > 0 ? props.t("Error") : props.t("Success"),
        errorHTML,
        importData?.failed?.length > 0 ? "error" : "success"
      ).then(result => {
        if (result.isConfirmed) {
          dispatch(onSuccessReset())
          location.reload();
        }
      })
    }
  }, [importData])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      ).then((isConfirmed)=>{
        if(isConfirmed){
          location.reload();
        }
      })
      dispatch(onClearError())
    }
  }, [error])

  const columns = useMemo(
    () => [
      {
        Header: props.t("Product Name"),
        accessor: "name",
        search: false,
      }, 
      {
        Header: props.t("Brand"),
        accessor: "Brand",
        search: false,
        Cell: ({ value }) => (<span>{value?.name}</span>)
      }, 
      {
        Header: props.t("ClassName"),
        accessor: 'ClassName',
        search: false,
        Cell: ({ value }) => (<span>{value?.name}</span>)
      },
      {
        Header: props.t("Category"),
        accessor: 'Category',
        search: false,
        Cell: ({ value }) => (<span>{value?.name}</span>)
      },
      {
        Header: props.t("SubCategory"),
        accessor: 'SubCategory',
        search: false,
        Cell: ({ value }) => (<span>{value?.name}</span>)
      },
      {
        Header: '',
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
          <a
            className="btn btn-sm btn-warning text-white me-2"
            href={`/v1/products/edit/${value}`}
          >
            {props.t("Edit")}
          </a>
            <a
              className="btn btn-sm btn-warning text-white"
              href={`/v1/products/variants/${value}`}
            >
              {props.t("Variant List")}
            </a>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItems={[]}
            isAdd={true}
            isImportExcel={true}
            isDownloadLink={true}
            addTitle={props.t("Create Product")}
            addHandle={() => {
              props.history.push("/v1/products/create")
            }}
            handleDownloadLink={() => {
              window.location.href =
                process.env.REACT_APP_BASE_API +
                "/static/example/ProductExample.xlsx"
            }}
            handleImportExcel={file => {
              if (file) {
                //post file and loading for wait
                dispatch(onImportXLSX(file))
              }
            }}
            className={"mb-0 pb-0"}
          />
          <Loading status={loading} />
          {/* <TableContainer
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          /> */}
          <TableContainerServerSide
            loading={loading}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            tableSetting={tableSetting}
            customPageSize={tableSetting.limit}
            className="custom-header-css"
            totalCount={tableOptions?.productTotal || 0}
            queryPageIndex={tableSetting.page}
            queryPageSize={tableSetting.limit}
            tableOptions={tableOptions}
            action={(pageIndex, pageSize, search) => {
              console.log('action', pageIndex, pageSize, search)
              setTableSetting({
                orderBy: "createdAt",
                ascDesc: -1,
                limit: pageSize,
                page: pageIndex,
                select: tableSetting.select,
                search: search,
              })
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Products.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(Products)
