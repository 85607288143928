import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  getCampaign as onGet,
  postCampaignStatus as onStatus,
  campaignSuccess as onSuccess,
  deleteTermCampaign as onDeleteTerm,
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import img_forward from "./../../../assets/images/custom/forward.png"
import { isEmpty } from "lodash"
import img_empty from "./../../../assets/images/jobs.png"

import * as DOMPurify from "dompurify"
import Loading from "components/Common/Loading"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { Role, authorize } from "helpers/authorize"

const CampaignDetails = props => {
  //meta title
  document.title = props.t("Campaign Details")

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE
  const [data, setData] = useState({})
  const [imageZoom, setimageZoom] = useState([img_empty])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Campaign Details"), link: "#" },
  ]
  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, campaign, loading, success } = useSelector(state => ({
    error: state.Campaigns?.error,
    campaign: state.Campaigns?.campaign,
    loading: state.Campaigns?.loading || false,
    success: state.Campaigns?.success,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])
  useEffect(() => {
    console.log("status change", success)
    if (!isEmpty(success)) {
      Swal.fire(success.title, success.message, success.status).then(
        ({ isConfirmed }) => {
          dispatch(onSuccess(null))
        }
      )
    }
  }, [success])

  useEffect(() => {
    if (isEmpty(campaign)) {
      dispatch(onGet(params.id))
    } else {
      if (!isEmpty(campaign.image)) {
        setimageZoom([IMAGE_BASE + campaign.image])
      }
      setData(campaign)
      if (!isEmpty(query.get("search"))) {
        setTimeout(() => {
          window.find(query.get("search"))
        }, 300)
      }
    }
  }, [campaign])

  const cardStatus = status => {
    switch (status) {
      case "continue":
        return "border border-success"
      case "wait": case "publish":
      case "design":
        return "border border-warning"
      case "finish":
        return "border border-danger"
      default:
        return "border border-light"
    }
  }
  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Campaign_Continues")}
          </span>
        )
      case "wait":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Wait")}
          </span>
        ) 
        case "publish":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Publish")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Campaign_Finish")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-light">
            {props.t("Campaign_Other")}
          </span>
        )
    }
  }

  const TermType = type =>
    type == "1"
      ? "Personel Hedefi"
      : type == "2"
      ? "Şube Hedefi"
      : type == 3
      ? "Şirket Hedefi"
      : ""
  const TermTypeLong_Step1 = (type, startCount, endCount) =>
    type == "1"
      ? endCount == null
        ? `Personellerin ${startCount} adetten fazla satışında aşağıdaki PuanK'lar Kazanılır`
        : `Personellerin ${startCount}-${endCount} adet arasındaki satışında aşağıdaki PuanK'lar Kazanılır`
      : type == "2"
      ? endCount == null
        ? `Şubenin toplam ${startCount} adetten fazla satışında aşağıdaki PuanK'lar Kazanılır`
        : `Şubenin toplam ${startCount}-${endCount} adet arasındaki satışında aşağıdaki PuanK'lar Kazanılır`
      : type == 3
      ? endCount == null
        ? `Şirketin toplam ${startCount} adetten fazla satışında aşağıdaki PuanK'lar Kazanılır`
        : `Şirketin toplam ${startCount}-${endCount} adet arasındaki satışında aşağıdaki PuanK'lar Kazanılır`
      : ""
  const TermTypeLong_Step2 = (type, count) =>
    type == "1"
      ? `Personellerin her ${count} satışı için aşağıdaki PuanK'lar Kazanılır`
      : type == "2"
      ? `Şubenin her ${count} satışı için aşağıdaki PuanK'lar Kazanılır`
      : type == 3
      ? `Şirketin her ${count} satışı için aşağıdaki PuanK'lar Kazanılır`
      : ""

  const PuantItem = (title, description, roles = []) =>
    description != null && authorize(roles) ? (
      <Col md={3}>
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                <h4 className="mb-0">{description} PuanK</h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    ) : null

  const Term_Detail_Step1 = (term, idx) => (
    <>
      <CardBody>
        <div className="d-flex d-flex-row justify-content-between">
          <h4>
            X Adetten Fazla Sat Adet Başı Y PuanK Kazan{" - "}
            <b>{TermType(term.step_2)}</b>
          </h4>{" "}
          {authorize([Role.Dev]) && data.status == "design" ? (
            <div>
              <a
                className="text-warning me-2"
                href={`/v1/campaigns/create/step2/${params.id}?l=${idx}`}
              >
                {props.t("Edit")} <i className="bx bx-pencil"></i>
              </a>
              <a
                className="text-danger"
                onClick={() => {
                  Swal.fire({
                    title: props.t("Are You Sure"),
                    text: props.t("DeleteWarning"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: props.t("Yes, delete it!"),
                    cancelButtonText: props.t("Cancel"),
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      dispatch(onDeleteTerm(params.id, idx, props.history))
                    }
                  })
                }}
              >
                {props.t("Delete")} <i className="bx bx-trash"></i>
              </a>
            </div>
          ) : null}
        </div>
        {term.terms.map((item, index) => (
          <Row key={index}>
            {parseFloat(item.gainPerson || 0) +
              parseFloat(item.gainManager || 0) +
              parseFloat(item.gainCoordinator || 0) +
              parseFloat(item.gainBranch || 0) >
            0 ? (
              <>
                <Col md={12}>
                  {TermTypeLong_Step1(
                    term.step_2,
                    item.startCount,
                    item.endCount
                  )}
                </Col>

                {PuantItem(props.t("Person"), item.gainPerson, [
                  Role.Admin,
                  Role.Coordinator,
                  Role.Manager,
                  Role.User,
                  Role.Dev,
                ])}
                {PuantItem(props.t("Manager"), item.gainManager, [
                  Role.Admin,
                  Role.Coordinator,
                  Role.Manager,
                  Role.Dev,
                ])}
                {PuantItem(props.t("Coordinator"), item.gainCoordinator, [
                  Role.Admin,
                  Role.Coordinator,
                  Role.Dev,
                ])}
                {PuantItem(props.t("Branch"), item.gainBranch, [
                  Role.Admin,
                  Role.Dev,
                ])}
              </>
            ) : null}
          </Row>
        ))}
      </CardBody>
    </>
  )

  const all_role_control = item => {
    return (
      (authorize([Role.User]) && parseFloat(item.gainPerson || 0) > 0) ||
      (authorize([Role.Manager]) &&
        parseFloat(item.gainPerson || 0) + parseFloat(item.gainManager || 0) >
          0) ||
      (authorize([Role.Coordinator]) &&
        parseFloat(item.gainPerson || 0) +
          parseFloat(item.gainManager || 0) +
          parseFloat(item.gainCoordinator || 0) >
          0) ||
      (authorize([Role.Admin]) &&
        parseFloat(item.gainPerson || 0) +
          parseFloat(item.gainManager || 0) +
          parseFloat(item.gainCoordinator || 0) +
          parseFloat(item.gainBranch || 0) >
          0) ||
      authorize([Role.Dev])
    )
  }

  const Term_Detail_Step2 = (term, idx) => (
    <>
      <div className="d-flex d-flex-row justify-content-between">
        <h4>
          X Adet Sat Toplam Y PuanK Kazan{" - "}
          <b>{TermType(term.step_2)}</b>
        </h4>{" "}
        {authorize([Role.Dev]) && data.status == "design" ? (
          <div>
            <a
              className="text-warning me-2"
              href={`/v1/campaigns/create/step2/${params.id}?l=${idx}`}
            >
              {props.t("Edit")} <i className="bx bx-pencil"></i>
            </a>
            <a
              className="text-danger"
              onClick={() => {
                Swal.fire({
                  title: props.t("Are You Sure"),
                  text: props.t("DeleteWarning"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: props.t("Yes, delete it!"),
                  cancelButtonText: props.t("Cancel"),
                }).then(({ isConfirmed }) => {
                  if (isConfirmed) {
                    dispatch(onDeleteTerm(params.id, idx, props.history))
                  }
                })
              }}
            >
              {props.t("Delete")} <i className="bx bx-trash"></i>
            </a>
          </div>
        ) : null}
      </div>
      <CardBody>
        {term.terms.map((item, index) => (
          <Row key={index}>
            {parseFloat(item.gainPerson || 0) +
              parseFloat(item.gainManager || 0) +
              parseFloat(item.gainCoordinator || 0) +
              parseFloat(item.gainBranch || 0) >
              0 && all_role_control(item) ? (
              <>
                <Col md={12}>{TermTypeLong_Step2(term.step_2, item.count)}</Col>

                {PuantItem(props.t("Person"), item.gainPerson, [
                  Role.Admin,
                  Role.Coordinator,
                  Role.Manager,
                  Role.User,
                  Role.Dev,
                ])}
                {PuantItem(props.t("Manager"), item.gainManager, [
                  Role.Admin,
                  Role.Coordinator,
                  Role.Manager,
                  Role.Dev,
                ])}
                {PuantItem(props.t("Coordinator"), item.gainCoordinator, [
                  Role.Admin,
                  Role.Coordinator,
                  Role.Dev,
                ])}
                {PuantItem(props.t("Branch"), item.gainBranch, [
                  Role.Admin,
                  Role.Dev,
                ])}
              </>
            ) : null}
          </Row>
        ))}
      </CardBody>
    </>
  )

  const statusChangeButton = () => {
    let _status = data.status
    let _title = ""
    let _desc = ""
    let _confirmButtonText = ""
    let _btn_status = ""
    let _action_data = {}
    let _status_btn = false
    let _btn_text = ""
    console.log("_status", _status)
    switch (_status) {
      case "design":
      case "stop":
        _title = props.t("Save and Publish")
        _desc = props.t("Save and Publish Description")
        _confirmButtonText = props.t("Yes, Publish!")
        _btn_text = props.t("Save and Publish")
        _btn_status = "btn-success"
        _action_data = { status: "wait" }
        _status_btn = true
        break
      case "wait":
        case "publish":
        _title = props.t("Campaign Close")
        _desc = props.t("Campaign Close Description")
        _confirmButtonText = props.t("Yes, Close Campaign!")
        _btn_text = props.t("Campaign Close")
        _btn_status = "btn-warning"
        _action_data = { status: "design" }
        _status_btn = true
        break 
      case "continue":
        _title = props.t("Campaign Stop")
        _desc = props.t("Campaign Stop Description")
        _confirmButtonText = props.t("Yes, Stop Campaign!")
        _btn_text = props.t("Campaign Stop")
        _btn_status = "btn-danger"
        _action_data = { status: "stop" }
        _status_btn = true
        break
      default:
        _status_btn = false
        break
    }

    return _status_btn &&
      !isEmpty(data.Terms) &&
      !isEmpty(data.Branches) &&
      !isEmpty(data.ProductSelection?.searchType) ? (
      <a
        className={"btn " + _btn_status}
        onClick={() => {
          Swal.fire({
            title: _title,
            html: _desc,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: _confirmButtonText,
            cancelButtonText: props.t("Cancel"),
          }).then(({ isConfirmed }) => {
            if (isConfirmed === true) {
              dispatch(onStatus(params.id, _action_data, props.history))
            }
          })
        }}
      >
        {_btn_text}
      </a>
    ) : null
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaigns")}
            backLink={"/v1/campaigns"}
            breadcrumbItems={breadcrumbItems}
          />

          <Loading status={loading} />
          {isGalleryZoom ? (
            <Lightbox
              mainSrc={imageZoom[photoIndex]}
              nextSrc={imageZoom[(photoIndex + 1) % imageZoom.length]}
              prevSrc={
                imageZoom[
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                ]
              }
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % imageZoom.length)
              }}
            />
          ) : null}

          {!isEmpty(data) ? (
            <Row>
              <Col md={12}>
                <Card className={cardStatus(data.status)}>
                  <CardBody>
                    <Row>
                      <Col md={3} className="mb-4">
                        <Row>
                          <Col md={12}>
                            {data.image ? (
                              <img
                                src={IMAGE_BASE + data.image}
                                className="campaign-img"
                                onClick={() => {
                                  setisGalleryZoom(true)
                                  setphotoIndex(0)
                                }}
                              />
                            ) : (
                              <img src={img_empty} className="campaign-img" />
                            )}
                          </Col>
                          <Col md={12}>
                            <span className="title">{data.Brand?.name}</span>
                          </Col>
                          <Col className="text-end">
                            {cardStatusText(data.status)}
                          </Col>
                          <Col md={12} className="text-end">
                            <div className="date-div">
                              <div className="date-title">
                                <span>{props.t("Start Date")} : </span>
                              </div>
                              <div className="date">
                                {moment(data.startDate).format("DD.MM.YYYY")}
                              </div>
                            </div>
                            <div className="date-div">
                              <div className="date-title">
                                <span>{props.t("End Date")} : </span>
                              </div>
                              <div className="date">
                                {moment(data.finishDate).format("DD.MM.YYYY")}
                              </div>
                            </div>
                          </Col>
                          <Col md={12} className="mt-2">
                            <span className="title">{data.name}</span>
                          </Col>

                          <Col md={12} className="mt-2">
                            <span className="title">
                              {props.t("Info to be Requested in the Campaign")}:
                            </span>
                          </Col>

                          {data.requestList?.map((_reqStr, idx) => (
                            <Col key={idx} md={12} className="mt-2">
                              <span className="title">{_reqStr}</span>
                            </Col>
                          ))}

                          {authorize([Role.Dev]) ? (
                            <>
                              <Col md={12} className="mt-4 mb-2">
                                <a
                                  // to={`/v1/campaigns/branches/${params.id}`}
                                  href={`/v1/campaigns/create/step3/${params.id}`}
                                  className="relative a-btn w-100"
                                >
                                  {props.t("Branchs")}{" "}
                                  <img
                                    src={img_forward}
                                    className="img-forward"
                                  />
                                </a>
                              </Col>
                              <Col md={12} className="mt-2 mb-2">
                                <a
                                  href={`/v1/campaigns/create/step4/${params.id}`}
                                  className="relative a-btn w-100"
                                >
                                  {props.t("Products")}{" "}
                                  <img
                                    src={img_forward}
                                    className="img-forward"
                                  />
                                </a>
                              </Col>
                              <Col md={12} className="mt-2 mb-2">
                                <a
                                  href={`/v1/campaigns/new-data-list-control/${params.id}`}
                                  className="relative a-btn w-100"
                                >
                                  {props.t("Data Control")}{" "}
                                  <img
                                    src={img_forward}
                                    className="img-forward"
                                  />
                                </a>
                              </Col>
                              {/**
                               * TODO: campaign.status == "stop" Kaldirilacak
                               */}
                              {campaign.status == "design" ||
                              campaign.status == "stop" ? (
                                <Col md={12} className="mt-2 mb-2">
                                  <a
                                    href={`/v1/campaigns/update/${params.id}`}
                                    className="relative a-btn w-100"
                                  >
                                    {props.t("Edit")}{" "}
                                    <img
                                      src={img_forward}
                                      className="img-forward"
                                    />
                                  </a>
                                </Col>
                              ) : null}
                            </>
                          ) : null}
                          {campaign.status == "continue" ? (
                            <>
                              {authorize([
                                Role.Admin,
                                Role.Coordinator,
                                Role.Manager,
                                Role.User,
                              ]) ? (
                                <Col md={12} className="mt-2 mb-2">
                                  <a
                                    href={`/v1/campaigns/new-data/${params.id}`}
                                    className="relative a-btn w-100"
                                  >
                                    {props.t("Enter Data")}{" "}
                                    <img
                                      src={img_forward}
                                      className="img-forward"
                                    />
                                  </a>
                                </Col>
                              ) : null}
                              {authorize([
                                Role.Admin,
                                Role.Coordinator,
                                Role.Manager,
                                Role.User,
                              ]) ? (
                                <Col md={12} className="mt-2 mb-2">
                                  <a
                                    href={`/v1/campaigns/new-data-list/${params.id}`}
                                    className="relative a-btn w-100"
                                  >
                                    {props.t("Past Data")}{" "}
                                    <img
                                      src={img_forward}
                                      className="img-forward"
                                    />
                                  </a>
                                </Col>
                              ) : null}
                            </>
                          ) : null}

                          {campaign.calculateStatus == "calculated" ? (
                            <Col md={12} className="mt-2 mb-2">
                              <a
                                href={`/v1/campaigns/puank-list/${params.id}`}
                                className="relative a-btn w-100"
                              >
                                {props.t("Puank List")}
                                <img
                                  src={img_forward}
                                  className="img-forward"
                                />
                              </a>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                      <Col md={9}>
                        {/* <Row>
                          <Col md={6} sm={12} lg={8}>
                            <img src={data.Brand.image} className="brand-img" />
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <CardBody>
                                <Col md={12} className="mt-2 mb-2">
                                  <span className="title">
                                    {props.t("Campaign Description")}
                                  </span>
                                </Col>
                                <Col md={12}>
                                  <span className="description-max">
                                    {
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            data.description
                                          ),
                                        }}
                                      />
                                    }
                                  </span>
                                </Col>
                              </CardBody>
                            </Card>
                            <Card className="shadow-sm">
                              <CardBody>
                                <Col md={12} className="mt-2 mb-2">
                                  <span className="title">
                                    {props.t("Campaign Terms")}
                                  </span>
                                </Col>
                                <Col md={12}>
                                  {data?.Terms?.map((term, idx) => (
                                    <Row key={idx}>
                                      <Col md={12}>
                                        <Card>
                                          {term.step_1 == "1"
                                            ? Term_Detail_Step1(term, idx)
                                            : term.step_1 == "2"
                                            ? Term_Detail_Step2(term, idx)
                                            : null}
                                        </Card>
                                      </Col>
                                    </Row>
                                  ))}
                                  {authorize([Role.Dev]) &&
                                  data.status == "design" ? (
                                    <a
                                      className="btn btn-primary me-2"
                                      href={`/v1/campaigns/create/step2/${params.id}?l=new`}
                                    >
                                      {props.t("New Terms")}
                                    </a>
                                  ) : null}

                                  {authorize([Role.Dev]) &&
                                  data.status == "design" &&
                                  (isEmpty(data.Terms) ||
                                    isEmpty(data.Branches) ||
                                    isEmpty(
                                      data.ProductSelection?.searchType
                                    )) ? (
                                    <div className="alert alert-warning mt-2 mb-2">
                                      <h5>{props.t("Required")}</h5>
                                      <ul>
                                        {authorize([Role.Dev]) &&
                                        isEmpty(data.Terms) ? (
                                          <li>
                                            <a
                                              className="ms-2"
                                              href={`/v1/campaigns/create/step2/${params.id}?l=new`}
                                            >
                                              {props.t("Terms Required")}
                                              <i className="mdi mdi-cursor-default-click-outline ms-2 font-size-18"></i>
                                            </a>
                                          </li>
                                        ) : null}
                                        {authorize([Role.Dev]) &&
                                        isEmpty(data.Branches) ? (
                                          <li>
                                            <a
                                              className="ms-2"
                                              href={`/v1/campaigns/create/step3/${params.id}`}
                                            >
                                              {props.t("Branches Required")}
                                              <i className="mdi mdi-cursor-default-click-outline ms-2 font-size-18"></i>
                                            </a>
                                          </li>
                                        ) : null}
                                        {authorize([Role.Dev]) &&
                                        isEmpty(
                                          data.ProductSelection?.searchType
                                        ) ? (
                                          <li>
                                            <a
                                              className="ms-2"
                                              href={`/v1/campaigns/create/step4/${params.id}`}
                                            >
                                              {props.t(
                                                "ProductSelection Required"
                                              )}
                                              <i className="mdi mdi-cursor-default-click-outline ms-2 font-size-18"></i>
                                            </a>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  ) : null}

                                  {authorize([Role.Dev])
                                    ? statusChangeButton()
                                    : null}
                                </Col>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CampaignDetails)
