import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  CardHeader,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import {
  getBranchGroup as onGet,
  updateBranchGroup as onSave,
  companiesResetError as onClearError,
  companiesResetAll as onClearCompany,
  companyLoading as onLoading,
  deleteBranchGroup as onDelete, 
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"
import CustomCheckbox from "components/Common/CustomCheckbox"

const BranchGroupEdit = props => {
  //meta title
  document.title = props.t("Edit BranchGroup")

  const query = new URLSearchParams(props.location.search)

  const dispatch = useDispatch()
  const { error, loading, branchGroup, status_branches, status_companies } =
    useSelector(state => ({
      error: state.Companies?.error,
      loading: state.Companies?.loading || false,
      branchGroup: state.Companies?.branchGroup,
      status_branches: state.Companies?.status_branches || [],
      status_companies: state.Companies?.status_companies || [],
    }))
  const [data, setData] = useState(null)
  const [check_branch_groups, set_check_branch_groups] = useState("false")
  const [check_branches, set_check_branches] = useState("false")
  const [companies, setCompanies] = useState([])
  const [branches, setBranches] = useState([])
  const [branchesForGroups, setBranchesForGroups] = useState([])

  const breadcrumbItems = [
    { title: props.t("Settings"), link: "/settings" },
    { title: props.t("BranchGroups"), link: "/settings/branch-groups" },
    { title: props.t("Edit BranchGroup"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params.id) {
      if (isEmpty(branchGroup) && isEmpty(data)) { 
        dispatch(onGet(params.id))
      } else if (!isEmpty(branchGroup) && isEmpty(data)) {
        const temp_data = { ...branchGroup }
        setData(temp_data)
        let nCompanies = [...status_companies]
        let nBranches = [...status_branches]
        for (let key of Object.keys(validation.values)) {
          try {
            if (key == "Companies") {
              for (let cid of temp_data[key]) {
                let cIndex = nCompanies.findIndex(x => x._id == cid)
                if (cIndex != -1) {
                  nCompanies[cIndex].status = "true"
                }
              } 
              //minus control
              for (let i = 0; i < nCompanies.length; i++) {
                if (nCompanies[i].status == "true") {
                  let branchFilter = nBranches.filter(
                    x => x.Group == nCompanies[i]._id
                  )
                  let branchFilterCount = branchFilter.length
                  let controlCount = 0
                  for (let b of branchFilter) {
                    if (temp_data["Branches"].includes(b._id)) {
                      controlCount++
                    }
                  }
                  if (controlCount != branchFilterCount) {
                    nCompanies[i].status = "minus"
                  }
                }
              }
              setCompanies(nCompanies)
            } else if (key == "Branches") {
              let newBranchesGroupList = []
              for (let bid of temp_data[key]) {
                let bIndex = nBranches.findIndex(x => x._id == bid)
                if (bIndex != -1) {
                  nBranches[bIndex].status = "true"
                }
              }
              setBranchesForGroups(nBranches)
              //Exist Company Control
              let existCompanyList = []
              for (let branch of nBranches) {
                if (temp_data["Companies"].includes(branch.Group)) {
                  existCompanyList.push(branch)
                }
              }
              setBranches(existCompanyList)
            } else {
              validation.setFieldValue(key, temp_data[key])
            }
          } catch (err) {
            console.error("key converter failed", key, err)
          }
        }
        setTimeout(() => {
          dispatch(onClearCompany())
        }, 300)
      }
    }
  }, [branchGroup])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      Branches: [],
      Companies: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      let newValues = { ...values }

      console.log("values", values)
      console.log("branches", branchesForGroups)

      let bList = []
      branchesForGroups
        .filter(x => x.status == "true")
        .map((item, idx) => {
          bList.push(item._id)
        })
      let cList = []
      companies
        .filter(x => x.status == "true" || x.status == "minus")
        .map((item, idx) => {
          cList.push(item._id)
        })
      newValues["Branches"] = bList
      newValues["Companies"] = cList
      console.log("newValues", newValues)
      dispatch(onSave(params.id, newValues, props.history))
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  const branchCheckAllControl = () => {
    let temp = [...branches]
    if (temp.filter(x => x.status == "true").length == temp.length) {
      set_check_branches("true")
    } else if (temp.filter(x => x.status == "false").length == temp.length) {
      set_check_branches("false")
    } else {
      set_check_branches("minus")
    }
  }
  const branchGroupCheckAllControl = () => {
    let temp = [...companies]
    if (temp.filter(x => x.status == "true").length == temp.length) {
      set_check_branch_groups("true")
    } else if (temp.filter(x => x.status == "false").length == temp.length) {
      set_check_branch_groups("false")
    } else {
      set_check_branch_groups("minus")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("BranchGroups")}
            backLink={"/settings/branch-groups"}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("Edit BranchGroup")}</h4>
                  <p className="card-title-desc">
                    {props.t("Edit BranchGroup Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">
                            {props.t("BranchGroup Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("BranchGroup Name")}
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {companies && companies ? (
                      <Row>
                        <Col md={6}>
                          <Card>
                            <CardHeader className="bg-white border-bottom">
                              <div className="group-item">
                                <CustomCheckbox
                                  label={props.t("Companies")}
                                  status={check_branch_groups}
                                  onChange={v => {
                                    set_check_branch_groups(v)
                                    let temp = [...companies]
                                    let global_status = ""
                                    for (let i = 0; i < temp.length; i++) {
                                      if (
                                        v == "true" &&
                                        temp[i].status == "false"
                                      ) {
                                        temp[i].status = "true"
                                        global_status = "true"
                                      } else if (
                                        v == "false" &&
                                        temp[i].status == "true"
                                      ) {
                                        temp[i].status = "false"
                                        global_status = "false"
                                      }
                                    }
                                    setCompanies(temp)

                                    if (global_status == "true") {
                                      let l1 = branchesForGroups
                                      for (let i = 0; i < l1.length; i++) {
                                        l1[i].status = "true"
                                      }
                                      setBranches(l1)
                                    } else if (global_status == "false") {
                                      setBranches([])
                                    }
                                  }}
                                />
                              </div>
                            </CardHeader>
                            <CardBody className="group-list">
                              {companies.map((item, idx) => (
                                <div key={idx} className="m-2 group-item">
                                  <CustomCheckbox
                                    label={item.name}
                                    status={item.status}
                                    onChange={v => {
                                      if (v) {
                                        let temp = [...companies]
                                        temp[idx].status = v
                                        setCompanies(temp)

                                        if (v == "true") {
                                          let l1 = branchesForGroups.filter(
                                            x =>
                                              x.Group.toString() ===
                                              temp[idx]._id.toString()
                                          )
                                          for (let i = 0; i < l1.length; i++) {
                                            l1[i].status = "true"
                                          }
                                          setBranches(currentObject =>
                                            currentObject.concat(l1)
                                          )
                                        } else {
                                          setBranches(
                                            branches.filter(
                                              data =>
                                                data.Group.toString() !==
                                                temp[idx]._id.toString()
                                            )
                                          )
                                        }
                                        branchGroupCheckAllControl()
                                        branchCheckAllControl()
                                      }
                                    }}
                                  />
                                </div>
                              ))}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <CardHeader className="bg-white border-bottom">
                              <div className="group-item">
                                <Label className="ms-2">
                                  {props.t("Branches")}
                                </Label>
                              </div>
                            </CardHeader>
                            <CardBody className="group-list">
                              {branches.map((item, idx) => (
                                <div key={idx} className="m-2 group-item">
                                  <CustomCheckbox
                                    label={item.name}
                                    status={item.status}
                                    onChange={v => {
                                      if (v) {
                                        let tempGroup = [...companies]
                                        let temp = [...branches]
                                        temp[idx].status = v
                                        for (let i = 0; i < temp.length; i++) {
                                          if (temp[i]._id == temp[idx]._id) {
                                            temp[i].status = v
                                          }
                                        }
                                        //group control
                                        let index = tempGroup.findIndex(
                                          x => x._id == item.Group
                                        )
                                        if (v == "false" && index != -1) {
                                          if (
                                            temp.findIndex(
                                              x =>
                                                x.Group == item.Group &&
                                                x.status == "true"
                                            ) == -1
                                          ) {
                                            tempGroup[index].status = "false"
                                            setCompanies(tempGroup)
                                          } else {
                                            tempGroup[index].status = "minus"
                                            setCompanies(tempGroup)
                                          }
                                        } else if (v == "true" && index != -1) {
                                          if (
                                            temp.findIndex(
                                              x =>
                                                x.Group == item.Group &&
                                                x.status == "false"
                                            ) == -1
                                          ) {
                                            tempGroup[index].status = "true"
                                            setCompanies(tempGroup)
                                          } else {
                                            tempGroup[index].status = "minus"
                                            setCompanies(tempGroup)
                                          }
                                        }
                                        setBranches(temp)

                                        branchCheckAllControl()
                                      }
                                    }}
                                  />
                                </div>
                              ))}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : null}
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                    <Button
                      color="danger"
                      className="ms-2"
                      onClick={() => {
                        Swal.fire({
                          title: props.t("Are You Sure"),
                          text: props.t("DeleteWarning"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: props.t("Yes, delete it!"),
                          cancelButtonText: props.t("Cancel"),
                        }).then(result => {
                          if (result.isConfirmed) {
                            dispatch(onDelete(params.id, props.history))
                          }
                        })
                      }}
                    >
                      {props.t("Delete")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BranchGroupEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(BranchGroupEdit)
