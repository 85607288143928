import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  FormGroup,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin, loginUserToken } from "../../store/actions"

//i18n
import { withTranslation } from "react-i18next"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"
import Swal from "sweetalert2"
import Loading from "components/Common/Loading"
import { isEmpty } from "lodash"

const LoginBranch = props => {
  //meta title
  document.title = props.t("Login") + " | " + props.t("APPNAME")

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.Login?.loading || false,
  }))

  const [branchList, setBranchList] = useState(
    localStorage.getItem("branches")
      ? JSON.parse(localStorage.getItem("branches"))
      : []
  )

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      branch: "",
    },
    validationSchema: Yup.object({
      branch: Yup.string().required("Required"),
    }),
    onSubmit: values => {
      let temp = [...branchList]
      let selectedArr = values.branch.split("-")
      if (selectedArr.length == 3) {
        let branchId = selectedArr[0]
        let companyId = selectedArr[1]
        let userId = selectedArr[2]
        let selectedItem = temp.find(
          x =>
            x.Branch == branchId.toString() &&
            x.Company == companyId.toString() &&
            x.User == userId.toString()
        )
        if (selectedItem) {
          console.log(selectedItem)
          dispatch(
            loginUserToken(
              {
                uid: selectedItem.User,
                cid: selectedItem.Company,
                bid: selectedItem.Branch,
              },
              props.history,
              null
            )
          )
        } else {
          Swal.fire(
            "Hata",
            "Seçim Tespit Edilemedi: " + JSON.stringify(selectedItem),
            "error"
          )
        }

        //dispatch(loginUser(values, props.history))
      } else {
        Swal.fire("Hata", JSON.stringify(values), "error")
      }
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  

  useEffect(()=>{
    if(!isEmpty(branchList)){ 
      let defaultFirst = branchList[0].Branch + "-" + branchList[0].Company + "-" + branchList[0].User 
      console.log("defaultFirst", defaultFirst)
      validation.setFieldValue("branch", defaultFirst)
      if(branchList.length == 1){
        dispatch(
          loginUserToken(
            {
              uid: branchList[0].User,
              cid: branchList[0].Company,
              bid: branchList[0].Branch,
            },
            props.history,
            null
          )
        )
      }
    }
  },[])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("APPNAME")}</h5>
                        <p>{props.t("Select Branch")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Loading status={loading} />
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <FormGroup className="mb-3 relative">
                        <Label htmlFor="validationBranch">
                          {props.t("Branch")}
                        </Label>
                        <select
                          id="validationBranch"
                          className={
                            validation.touched.branch &&
                            validation.errors.branch
                              ? "form-select is-invalid"
                              : "form-select"
                          }
                          name="branch"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.branch || ""}
                        >
                          <option value="">{props.t("Select")}</option>
                          {branchList.map((item, idx) => (
                            <option
                              key={idx}
                              value={
                                item.Branch +
                                "-" +
                                item.Company +
                                "-" +
                                item.User
                              }
                            >
                              {item.title}
                            </option>
                          ))}
                        </select>
                        <img className="form-select-img" />
                        {validation.touched.branch &&
                        validation.errors.branch ? (
                          <FormFeedback type="invalid">
                            {validation.errors.branch}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {props.t("Log In")}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                           <TwitterLogin
                             loginUrl={
                               "http://localhost:4000/api/v1/auth/twitter"
                             }
                             onSuccess={this.twitterResponse}
                             onFailure={this.onFailure}
                             requestTokenUrl={
                               "http://localhost:4000/api/v1/auth/twitter/revers"
                             }
                             showIcon={false}
                             tag={"div"}
                           >
                             <a
                               href=""
                               className="social-list-item bg-info text-white border-info"
                             >
                               <i className="mdi mdi-twitter"/>
                             </a>
                           </TwitterLogin>
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />
                          </li>
                        </ul>
                      </div> */}

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {props.t("Forgot your password?")}
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  {props.t("Dont have an account ?")}{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    {props.t("Signup now")}{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} {props.t("copyright")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
LoginBranch.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(LoginBranch)
