export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL"

export const ADD_COMPANY = "ADD_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

export const GET_BRANCHES = "GET_BRANCHES"
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS"
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL"

export const GET_BRANCH = "GET_BRANCH"
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS"
export const GET_BRANCH_FAIL = "GET_BRANCH_FAIL"

export const ADD_BRANCH = "ADD_BRANCH"
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS"
export const ADD_BRANCH_FAIL = "ADD_BRANCH_FAIL"

export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS"
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL"

export const DELETE_BRANCH = "DELETE_BRANCH"
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS"
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL"

export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"

export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL"
export const DISTRICTS_RESET = "DISTRICTS_RESET"

export const GET_PERSONS = "GET_PERSONS"
export const GET_PERSONS_SUCCESS = "GET_PERSONS_SUCCESS"
export const GET_PERSONS_FAIL = "GET_PERSONS_FAIL"

export const GET_PERSON = "GET_PERSON"
export const GET_PERSON_SUCCESS = "GET_PERSON_SUCCESS"
export const GET_PERSON_FAIL = "GET_PERSON_FAIL"

export const ADD_PERSON = "ADD_PERSON"
export const ADD_PERSON_SUCCESS = "ADD_PERSON_SUCCESS"
export const ADD_PERSON_FAIL = "ADD_PERSON_FAIL"

export const UPDATE_PERSON = "UPDATE_PERSON"
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS"
export const UPDATE_PERSON_FAIL = "UPDATE_PERSON_FAIL"

export const DELETE_PERSON = "DELETE_PERSON"
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS"
export const DELETE_PERSON_FAIL = "DELETE_PERSON_FAIL"

export const GET_BRANCHGROUPS = "GET_BRANCHGROUPS"
export const GET_BRANCHGROUPS_SUCCESS = "GET_BRANCHGROUPS_SUCCESS"
export const GET_BRANCHGROUPS_FAIL = "GET_BRANCHGROUPS_FAIL"

export const GET_BRANCHGROUP = "GET_BRANCHGROUP"
export const GET_BRANCHGROUP_SUCCESS = "GET_BRANCHGROUP_SUCCESS"
export const GET_BRANCHGROUP_FAIL = "GET_BRANCHGROUP_FAIL"

export const ADD_BRANCHGROUP = "ADD_BRANCHGROUP"
export const ADD_BRANCHGROUP_SUCCESS = "ADD_BRANCHGROUP_SUCCESS"
export const ADD_BRANCHGROUP_FAIL = "ADD_BRANCHGROUP_FAIL"

export const UPDATE_BRANCHGROUP = "UPDATE_BRANCHGROUP"
export const UPDATE_BRANCHGROUP_SUCCESS = "UPDATE_BRANCHGROUP_SUCCESS"
export const UPDATE_BRANCHGROUP_FAIL = "UPDATE_BRANCHGROUP_FAIL"

export const DELETE_BRANCHGROUP = "DELETE_BRANCHGROUP"
export const DELETE_BRANCHGROUP_SUCCESS = "DELETE_BRANCHGROUP_SUCCESS"
export const DELETE_BRANCHGROUP_FAIL = "DELETE_BRANCHGROUP_FAIL"

export const GROUP_STATUS_RESET = "GROUP_STATUS_RESET"
export const COMPANY_RESET = "COMPANY_RESET"
export const COMPANIES_RESET_ERROR = "COMPANIES_RESET_ERROR"
export const COMPANIES_RESET = "COMPANIES_RESET"
export const COMPANIES_RESET_SUCCESS = "COMPANIES_RESET_SUCCESS"

export const COMPANY_LOADING = "COMPANY_LOADING"
