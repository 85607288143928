import {
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANIES,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  ADD_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY,
  GET_BRANCHES,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAIL,
  COMPANIES_RESET,
  COMPANIES_RESET_ERROR,
  GET_PERSONS,
  GET_PERSONS_SUCCESS,
  GET_PERSONS_FAIL,
  COMPANIES_RESET_SUCCESS,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_CITIES,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICTS,
  DISTRICTS_RESET,
  GET_COMPANY,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  COMPANY_RESET,
  COMPANY_LOADING,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAIL,
  GET_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  ADD_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  UPDATE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  DELETE_BRANCH,
  GET_PERSON_SUCCESS,
  GET_PERSON_FAIL,
  GET_PERSON,
  ADD_PERSON_SUCCESS,
  ADD_PERSON_FAIL,
  ADD_PERSON,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAIL,
  UPDATE_PERSON,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAIL,
  DELETE_PERSON,
  GET_BRANCHGROUPS_SUCCESS,
  GET_BRANCHGROUPS_FAIL,
  GET_BRANCHGROUPS,
  GET_BRANCHGROUP_SUCCESS,
  GET_BRANCHGROUP_FAIL,
  GET_BRANCHGROUP,
  ADD_BRANCHGROUP_SUCCESS,
  ADD_BRANCHGROUP_FAIL,
  ADD_BRANCHGROUP,
  UPDATE_BRANCHGROUP_SUCCESS,
  UPDATE_BRANCHGROUP_FAIL,
  UPDATE_BRANCHGROUP,
  DELETE_BRANCHGROUP_SUCCESS,
  DELETE_BRANCHGROUP_FAIL,
  DELETE_BRANCHGROUP,
  GROUP_STATUS_RESET,
} from "./actionTypes"

export const companyLoading = status => ({
  type: COMPANY_LOADING,
  payload: { status },
})

//COMPANIES
export const getCompaniesSuccess = (actionType, data, tableOptions) => ({
  type: GET_COMPANIES_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getCompaniesFail = (actionType, error) => ({
  type: GET_COMPANIES_FAIL,
  payload: { actionType, error },
})

export const getCompanies = data => ({
  type: GET_COMPANIES,
  payload: { data },
})

export const getCompanySuccess = (actionType, data) => ({
  type: GET_COMPANY_SUCCESS,
  payload: { actionType, data },
})

export const getCompanyFail = (actionType, error) => ({
  type: GET_COMPANY_FAIL,
  payload: { actionType, error },
})

export const getCompany = id => ({
  type: GET_COMPANY,
  payload: { id },
})

export const addCompanySuccess = (actionType, data) => ({
  type: ADD_COMPANY_SUCCESS,
  payload: { actionType, data },
})

export const addCompanyFail = (actionType, error) => ({
  type: ADD_COMPANY_FAIL,
  payload: { actionType, error },
})

export const addCompany = (data, history) => ({
  type: ADD_COMPANY,
  payload: { data, history },
})

export const updateCompanySuccess = (actionType, data) => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: { actionType, data },
})

export const updateCompanyFail = (actionType, error) => ({
  type: UPDATE_COMPANY_FAIL,
  payload: { actionType, error },
})

export const updateCompany = (id, data, history) => ({
  type: UPDATE_COMPANY,
  payload: { id, data, history },
})

export const deleteCompanySuccess = (actionType, data) => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: { actionType, data },
})

export const deleteCompanyFail = (actionType, error) => ({
  type: DELETE_COMPANY_FAIL,
  payload: { actionType, error },
})

export const deleteCompany = (id, history) => ({
  type: DELETE_COMPANY,
  payload: { id, history },
})

//BRANCHES
export const getBranchesSuccess = (actionType, data, tableOptions) => ({
  type: GET_BRANCHES_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getBranchesFail = (actionType, error) => ({
  type: GET_BRANCHES_FAIL,
  payload: { actionType, error },
})

export const getBranches = (id, data) => ({
  type: GET_BRANCHES,
  payload: { id, data },
})

export const getBranchSuccess = (actionType, data) => ({
  type: GET_BRANCH_SUCCESS,
  payload: { actionType, data },
})

export const getBranchFail = (actionType, error) => ({
  type: GET_BRANCH_FAIL,
  payload: { actionType, error },
})

export const getBranch = id => ({
  type: GET_BRANCH,
  payload: { id },
})

export const addBranchSuccess = (actionType, data) => ({
  type: ADD_BRANCH_SUCCESS,
  payload: { actionType, data },
})

export const addBranchFail = (actionType, error) => ({
  type: ADD_BRANCH_FAIL,
  payload: { actionType, error },
})

export const addBranch = (cid, data, history) => ({
  type: ADD_BRANCH,
  payload: { cid, data, history },
})

export const updateBranchSuccess = (actionType, data) => ({
  type: UPDATE_BRANCH_SUCCESS,
  payload: { actionType, data },
})

export const updateBranchFail = (actionType, error) => ({
  type: UPDATE_BRANCH_FAIL,
  payload: { actionType, error },
})

export const updateBranch = (id, cid, data, history) => ({
  type: UPDATE_BRANCH,
  payload: { id, cid, data, history },
})

export const deleteBranchSuccess = (actionType, data) => ({
  type: DELETE_BRANCH_SUCCESS,
  payload: { actionType, data },
})

export const deleteBranchFail = (actionType, error) => ({
  type: DELETE_BRANCH_FAIL,
  payload: { actionType, error },
})

export const deleteBranch = (id, cid, history) => ({
  type: DELETE_BRANCH,
  payload: { id, cid, history },
})

//CITY
export const getCitiesSuccess = (actionType, data) => ({
  type: GET_CITIES_SUCCESS,
  payload: { actionType, data },
})

export const getCitiesFail = (actionType, error) => ({
  type: GET_CITIES_FAIL,
  payload: { actionType, error },
})

export const getCities = () => ({
  type: GET_CITIES,
  payload: {},
})

export const getDistrictsSuccess = (actionType, data) => ({
  type: GET_DISTRICTS_SUCCESS,
  payload: { actionType, data },
})

export const getDistrictsFail = (actionType, error) => ({
  type: GET_DISTRICTS_FAIL,
  payload: { actionType, error },
})

export const getDistricts = id => ({
  type: GET_DISTRICTS,
  payload: { id },
})

//--------
export const districtReset = () => ({
  type: DISTRICTS_RESET,
})

//-------

//PERSONS
export const getPersonsSuccess = (actionType, data, tableOptions) => ({
  type: GET_PERSONS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getPersonsFail = (actionType, error) => ({
  type: GET_PERSONS_FAIL,
  payload: { actionType, error },
})

export const getPersons = (bid, cid, data) => ({
  type: GET_PERSONS,
  payload: { bid, cid, data },
})

export const getPersonSuccess = (actionType, data) => ({
  type: GET_PERSON_SUCCESS,
  payload: { actionType, data },
})

export const getPersonFail = (actionType, error) => ({
  type: GET_PERSON_FAIL,
  payload: { actionType, error },
})

export const getPerson = id => ({
  type: GET_PERSON,
  payload: { id },
})

export const addPersonSuccess = (actionType, data) => ({
  type: ADD_PERSON_SUCCESS,
  payload: { actionType, data },
})

export const addPersonFail = (actionType, error) => ({
  type: ADD_PERSON_FAIL,
  payload: { actionType, error },
})

export const addPerson = (cid, bid, data, history) => ({
  type: ADD_PERSON,
  payload: { cid, bid, data, history },
})

export const updatePersonSuccess = (actionType, data) => ({
  type: UPDATE_PERSON_SUCCESS,
  payload: { actionType, data },
})

export const updatePersonFail = (actionType, error) => ({
  type: UPDATE_PERSON_FAIL,
  payload: { actionType, error },
})

export const updatePerson = (id, cid, bid, data, history) => ({
  type: UPDATE_PERSON,
  payload: { id, cid, bid, data, history },
})

export const deletePersonSuccess = (actionType, data) => ({
  type: DELETE_PERSON_SUCCESS,
  payload: { actionType, data },
})

export const deletePersonFail = (actionType, error) => ({
  type: DELETE_PERSON_FAIL,
  payload: { actionType, error },
})

export const deletePerson = (id, history) => ({
  type: DELETE_PERSON,
  payload: { id, history },
})

//BRANCH GROUPS

export const getBranchGroupsSuccess = (actionType, data, tableOptions) => ({
  type: GET_BRANCHGROUPS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getBranchGroupsFail = (actionType, error) => ({
  type: GET_BRANCHGROUPS_FAIL,
  payload: { actionType, error },
})

export const getBranchGroups = data => ({
  type: GET_BRANCHGROUPS,
  payload: { data },
})

export const getBranchGroupSuccess = (
  actionType,
  data,
  companyList,
  branchList
) => ({
  type: GET_BRANCHGROUP_SUCCESS,
  payload: { actionType, data, companyList, branchList },
})

export const getBranchGroupFail = (actionType, error) => ({
  type: GET_BRANCHGROUP_FAIL,
  payload: { actionType, error },
})

export const getBranchGroup = id => ({
  type: GET_BRANCHGROUP,
  payload: { id },
})

export const addBranchGroupSuccess = (actionType, data) => ({
  type: ADD_BRANCHGROUP_SUCCESS,
  payload: { actionType, data },
})

export const addBranchGroupFail = (actionType, error) => ({
  type: ADD_BRANCHGROUP_FAIL,
  payload: { actionType, error },
})

export const addBranchGroup = (data, history) => ({
  type: ADD_BRANCHGROUP,
  payload: { data, history },
})

export const updateBranchGroupSuccess = (actionType, data) => ({
  type: UPDATE_BRANCHGROUP_SUCCESS,
  payload: { actionType, data },
})

export const updateBranchGroupFail = (actionType, error) => ({
  type: UPDATE_BRANCHGROUP_FAIL,
  payload: { actionType, error },
})

export const updateBranchGroup = (id, data, history) => ({
  type: UPDATE_BRANCHGROUP,
  payload: { id, data, history },
})

export const deleteBranchGroupSuccess = (actionType, data) => ({
  type: DELETE_BRANCHGROUP_SUCCESS,
  payload: { actionType, data },
})

export const deleteBranchGroupFail = (actionType, error) => ({
  type: DELETE_BRANCHGROUP_FAIL,
  payload: { actionType, error },
})

export const deleteBranchGroup = (id, history) => ({
  type: DELETE_BRANCHGROUP,
  payload: { id, history },
})


//-------

export const clearStatusGroup = () => ({
  type: GROUP_STATUS_RESET,
})

export const clearCompany = () => ({
  type: COMPANY_RESET,
})
export const companiesResetAll = () => ({
  type: COMPANIES_RESET,
})

export const companiesResetError = () => ({
  type: COMPANIES_RESET_ERROR,
})

export const companiesResetSuccess = () => ({
  type: COMPANIES_RESET_SUCCESS,
})
