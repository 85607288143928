import {
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL,
  GET_BRANDS,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  ADD_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  UPDATE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  DELETE_BRAND, 
  BRANDS_RESET,
  BRANDS_RESET_ERROR, 
  BRANDS_RESET_SUCCESS, 
  GET_BRAND,
  GET_BRAND_FAIL,
  GET_BRAND_SUCCESS,
  BRAND_RESET,
  BRAND_LOADING, 
} from "./actionTypes"

export const brandLoading = status => ({
  type: BRAND_LOADING,
  payload: { status },
})

//BRANDS
export const getBrandsSuccess = (actionType, data, tableOptions) => ({
  type: GET_BRANDS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getBrandsFail = (actionType, error) => ({
  type: GET_BRANDS_FAIL,
  payload: { actionType, error },
})

export const getBrands = () => ({
  type: GET_BRANDS,
  payload: { },
})

export const getBrandSuccess = (actionType, data) => ({
  type: GET_BRAND_SUCCESS,
  payload: { actionType, data },
})

export const getBrandFail = (actionType, error) => ({
  type: GET_BRAND_FAIL,
  payload: { actionType, error },
})

export const getBrand = id => ({
  type: GET_BRAND,
  payload: { id },
})

export const addBrandSuccess = (actionType, data) => ({
  type: ADD_BRAND_SUCCESS,
  payload: { actionType, data },
})

export const addBrandFail = (actionType, error) => ({
  type: ADD_BRAND_FAIL,
  payload: { actionType, error },
})

export const addBrand = (data, history) => ({
  type: ADD_BRAND,
  payload: { data, history },
})

export const updateBrandSuccess = (actionType, data) => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: { actionType, data },
})

export const updateBrandFail = (actionType, error) => ({
  type: UPDATE_BRAND_FAIL,
  payload: { actionType, error },
})

export const updateBrand = (id, data, history) => ({
  type: UPDATE_BRAND,
  payload: { id, data, history },
})

export const deleteBrandSuccess = (actionType, data) => ({
  type: DELETE_BRAND_SUCCESS,
  payload: { actionType, data },
})

export const deleteBrandFail = (actionType, error) => ({
  type: DELETE_BRAND_FAIL,
  payload: { actionType, error },
})

export const deleteBrand = (id, history) => ({
  type: DELETE_BRAND,
  payload: { id, history },
})
    

export const clearBrand = () => ({
  type: BRAND_RESET,
})
export const brandsResetAll = () => ({
  type: BRANDS_RESET,
})

export const brandsResetError = () => ({
  type: BRANDS_RESET_ERROR,
})

export const brandsResetSuccess = () => ({
  type: BRANDS_RESET_SUCCESS,
})
