import {
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAIL,
  COMPANIES_RESET_ERROR,
  COMPANIES_RESET,
  GET_PERSONS_SUCCESS,
  GET_PERSONS_FAIL,
  COMPANIES_RESET_SUCCESS,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_DISTRICTS_SUCCESS,
  GET_CITIES_FAIL,
  GET_DISTRICTS_FAIL,
  DISTRICTS_RESET,
  ADD_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  COMPANY_RESET,
  COMPANY_LOADING,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAIL,
  GET_PERSON_SUCCESS,
  ADD_PERSON_SUCCESS,
  UPDATE_PERSON_SUCCESS,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAIL,
  UPDATE_PERSON_FAIL,
  GET_PERSON_FAIL,
  ADD_PERSON_FAIL,
  GET_BRANCHGROUPS_SUCCESS,
  GET_BRANCHGROUP_SUCCESS,
  ADD_BRANCHGROUP_SUCCESS,
  UPDATE_BRANCHGROUP_SUCCESS,
  DELETE_BRANCHGROUP_SUCCESS,
  GET_BRANCHGROUPS_FAIL,
  GET_BRANCHGROUP_FAIL,
  ADD_BRANCHGROUP_FAIL,
  UPDATE_BRANCHGROUP_FAIL,
  DELETE_BRANCHGROUP_FAIL,
  GROUP_STATUS_RESET,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  tableOptions: null,
  company: {},
  branches: [],
  branchesTableOptions: null,
  branch: {},
  branchGroups: [],
  branchGroupsTableOptions: null,
  branchGroup: {},
  persons: [],
  person: {},
  personsTableOptions: null,
  cities: [],
  districts: [],
  error: null,
  success: null,
  loading: true,
  status_branches: [],
  status_companies: [],
}

const Companies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        tableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload?.data,
      }
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: action.payload?.data,
        branchesTableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
      }
    case GET_BRANCH_SUCCESS:
      return {
        ...state,
        branch: action.payload?.data,
      }
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload?.data,
      }
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload?.data,
      }
    case GET_PERSONS_SUCCESS:
      return {
        ...state,
        persons: action.payload?.data,
        personsTableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
      }
    case GET_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload?.data,
      }
    case ADD_PERSON_SUCCESS:
      return {
        ...state,
        persons: [action.payload.data, ...state.data],
      }
    case UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        persons: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        persons: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case GET_BRANCHGROUPS_SUCCESS:
      return {
        ...state,
        branchGroups: action.payload?.data,
        branchGroupsTableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
      }
    case GET_BRANCHGROUP_SUCCESS:
      return {
        ...state,
        branchGroup: action.payload?.data,
        status_branches: action.payload?.branchList || [],
        status_companies: action.payload?.companyList || [],
      }
    case ADD_BRANCHGROUP_SUCCESS:
      return {
        ...state,
        branchGroups: [action.payload.data, ...state.branchGroups],
      }
    case UPDATE_BRANCHGROUP_SUCCESS:
      return {
        ...state,
        branchGroup:{},
        status_branches: [],
        status_companies: [],
        branchGroups: state.branchGroups.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_BRANCHGROUP_SUCCESS:
      return {
        ...state,
        branchGroups: state.branchGroups.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }
    case GET_COMPANIES_FAIL:
    case GET_BRANCHES_FAIL:
    case GET_BRANCH_FAIL:
    case GET_CITIES_FAIL:
    case GET_DISTRICTS_FAIL:
    case GET_COMPANY_FAIL:
    case ADD_COMPANY_FAIL:
    case UPDATE_COMPANY_FAIL:
    case DELETE_COMPANY_FAIL:
    case GET_PERSONS_FAIL:
    case GET_PERSON_FAIL:
    case ADD_PERSON_FAIL:
    case UPDATE_PERSON_FAIL:
    case DELETE_PERSON_FAIL:
    case GET_BRANCHGROUPS_FAIL:
    case GET_BRANCHGROUP_FAIL:
    case ADD_BRANCHGROUP_FAIL:
    case UPDATE_BRANCHGROUP_FAIL:
    case DELETE_BRANCHGROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case COMPANIES_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case COMPANIES_RESET_ERROR:
      return {
        ...state,
        error: null,
        loading: false
      }
    case DISTRICTS_RESET:
      return {
        ...state,
        districts: [],
      }
    case COMPANY_RESET:
      return {
        ...state,
        company: {},
        branch: {},
      }
    case GROUP_STATUS_RESET:
      return {
        ...state, 
        branchGroup:{},
        status_branches: [],
        status_companies: [],
      }
    case COMPANIES_RESET:
      return {
        ...state,
        data: [],
        company: null,
        branches: [],
        persons: [],
        cities: [],
        districts: [],
        error: null,
        success: null,
        tableOptions: null,
        branchesTableOptions: null,
        branch: {},
        person: {},
        personsTableOptions: null, 
      }
    case COMPANY_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default Companies
