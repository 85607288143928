import { 
  CLASSNAMES_RESET_ERROR,
  CLASSNAMES_RESET, 
  CLASSNAMES_RESET_SUCCESS,
  GET_CLASSNAMES_SUCCESS,
  GET_CLASSNAMES_FAIL, 
  ADD_CLASSNAME_SUCCESS,
  UPDATE_CLASSNAME_SUCCESS,
  DELETE_CLASSNAME_SUCCESS,
  ADD_CLASSNAME_FAIL,
  UPDATE_CLASSNAME_FAIL,
  DELETE_CLASSNAME_FAIL,
  GET_CLASSNAME_SUCCESS,
  GET_CLASSNAME_FAIL,
  CLASSNAME_RESET,
  CLASSNAME_LOADING, 
} from "./actionTypes"

const INIT_STATE = {
  data: [], 
  className: {}, 
  error: null,
  success: null,
  loading: true,
}

const ClassNames = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASSNAMES_SUCCESS:
      return {
        ...state,
        data: action.payload?.data, 
      }
    case GET_CLASSNAME_SUCCESS:
      return {
        ...state,
        className: action.payload?.data,
      }
    case ADD_CLASSNAME_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_CLASSNAME_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_CLASSNAME_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }  
    case GET_CLASSNAMES_FAIL: 
    case GET_CLASSNAME_FAIL:
    case ADD_CLASSNAME_FAIL:
    case UPDATE_CLASSNAME_FAIL:
    case DELETE_CLASSNAME_FAIL: 
      return {
        ...state,
        error: action.payload,
      }
    case CLASSNAMES_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case CLASSNAMES_RESET_ERROR:
      return {
        ...state,
        error: null,
      } 
    case CLASSNAME_RESET:
      return {
        ...state,
        className: {},
        branch: {},
      }
    case CLASSNAMES_RESET:
      return {
        ...state,
        data: [],
        className: null, 
        error: null,
        success: null, 
      }
    case CLASSNAME_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default ClassNames
