import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import {
  productsResetError as onClearError,
  clearProduct as onClearProduct,
  addVariant as onSave,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./../custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"

const VariantCreate = props => {
  //meta title
  document.title = props.t("Create Variant")

  const [data, setData] = useState(null)

  const {
    match: { params },
  } = props

  const breadcrumbItems = [
    { title: props.t("Products"), link: "/v1/products" },
    {
      title: props.t("Serial No List"),
      link: `/v1/products/series/${params.id}`,
    },
  ]

  const dispatch = useDispatch()
  const { error, variant, loading } = useSelector(state => ({
    error: state.Products?.error,
    loading: state.Products?.loading || false,
    variant: state.Products?.variant,
  }))
  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      ).then((isConfirmed)=>{
        if(isConfirmed){
          location.reload();
        }
      })
      dispatch(onClearError())
    }
  }, [error])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      barcode: "", 
      Product: params.id,
    },
    validationSchema: Yup.object({
      barcode: Yup.string().required(props.t("Required")),
      name: Yup.string().required(props.t("Required")),
      // className: Yup.string().required(props.t("Required")),
      // category: Yup.string().required(props.t("Required")),
      // subCategory: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values)
      //props.history.push(`/v1/products/create/step2/01`)

      dispatch(onSave(values, props.history))
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Serial No List")}
            backLink={`/v1/products/variants/${params.id}`}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("Create Variant")}</h4>
                  <p className="card-title-desc">
                    {props.t("Create Variant Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Variant Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("Variant Name")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Barcode")}
                          </Label>
                          <Input
                            name="barcode"
                            placeholder={props.t("Barcode")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.barcode || ""}
                            invalid={
                              validation.touched.barcode &&
                              validation.errors.barcode
                                ? true
                                : false
                            }
                          />
                          {validation.touched.barcode &&
                          validation.errors.barcode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.barcode}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col> 
                    </Row>
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VariantCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(VariantCreate)
