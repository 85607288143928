import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { 
  getClassName as onGet,
  clearClassName as onClear,
  updateClassName as onSave,
  deleteClassName as onDelete,
  classNamesResetError as onClearError,
} from "store/actions" 
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"

const ClassNameEdit = props => {
  //meta title
  document.title = props.t("Edit ClassName")

  const query = new URLSearchParams(props.location.search)

  const dispatch = useDispatch()
  const { error, loading, className } = useSelector(state => ({
    error: state.ClassNames?.error, 
    loading: state.ClassNames?.loading || false,
    className: state.ClassNames?.className
  }))


  const [data, setData] = useState(null)

  const breadcrumbItems = [
    { title: props.t("Settings"), link: "/settings" },
    { title: props.t("Edit ClassName"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  // useEffect(() => {
  //   if (isEmpty(cities)) {
  //     dispatch(onCities())
  //   }
  // }, [])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => { 
    if (params.id) {
      if (isEmpty(className) && isEmpty(data)) { 
        dispatch(onGet(params.id))
      } else if (!isEmpty(className) && isEmpty(data)) {
        const temp_data = { ...className }
        setData(temp_data)
        for (let key of Object.keys(validation.values)) {
          try {
            // console.log(key, temp_data[key], typeof temp_data[key])
            validation.setFieldValue(key, temp_data[key])
          } catch (err) {
            console.error("key converter failed", key, err)
          }
        } 
        setTimeout(() => {
          dispatch(onClear())
        }, 300)
      }
    }
  }, [className])

 

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "", 
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required")), 
      // className: Yup.string().required(props.t("Required")),
      // category: Yup.string().required(props.t("Required")),
      // subCategory: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values)
      //props.history.push(`/v1/products/create/step2/01`)
      dispatch(onSave(params.id, values, props.history))
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("ClassName")}
            backLink={'/settings'}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("Edit ClassName")}</h4>
                  <p className="card-title-desc">
                    {props.t("Edit ClassName Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">
                            {props.t("ClassName Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("ClassName Name")}
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col> 
                    </Row>
              
 
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                    <Button
                      color="danger"
                      className="ms-2"
                      onClick={() => {
                        Swal.fire({
                          title: props.t("Are You Sure"),
                          text: props.t("DeleteWarning"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: props.t("Yes, delete it!"),
                          cancelButtonText: props.t("Cancel"),
                        }).then(result => {
                          if (result.isConfirmed) {
                             dispatch(onDelete(params.id, props.history))
                          }
                        }) 
                      }}
                    >
                      {props.t("Delete")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ClassNameEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(ClassNameEdit)
