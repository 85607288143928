import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getBrandsSuccess,
  getBrandsFail,
  addBrandSuccess,
  addBrandFail,
  getBrandSuccess,
  getBrandFail,
  updateBrandSuccess,
  updateBrandFail,
  deleteBrandSuccess,
  deleteBrandFail,
  brandLoading,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBrands,
  addBrand,
  updateBrand,
  deleteBrand,
  getBrand,
} from "../../helpers/fakebackend_helper"
import {
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  ADD_BRAND_SUCCESS,
  ADD_BRAND,
  ADD_BRAND_FAIL,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  GET_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  UPDATE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  DELETE_BRAND,
} from "./actionTypes"

//BRANDS
function* getBrandsFetch({ payload: {  } }) {
  try {
    yield put(brandLoading(true))
    var response = yield call(getBrands)
    if (response.status) {
      yield put(getBrandsSuccess(GET_BRANDS_SUCCESS, response.data, response))
    } else {
      yield put(
        getBrandsFail(
          GET_BRANDS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANDS-1001"
        )
      )
    }
    yield put(brandLoading(false))
  } catch (error) {
    yield put(getBrandsFail(GET_BRANDS_FAIL, error))
    yield put(brandLoading(false))
  }
}

function* addBrandFetch({ payload: { data, history } }) {
  try {
    yield put(brandLoading(true))
    var response = yield call(addBrand, data)
    if (response.status) {
      yield put(addBrandSuccess(ADD_BRAND_SUCCESS, response.data))
      history.push("/v1/brands")
    } else {
      yield put(
        addBrandFail(
          ADD_BRAND_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANDS-1001"
        )
      )
    }

    yield put(brandLoading(false))
  } catch (error) {
    yield put(addBrandFail(ADD_BRAND_FAIL, error))
    yield put(brandLoading(false))
  }
}

function* updateBrandFetch({ payload: { id, data, history } }) {
  try {
    yield put(brandLoading(true))
    var response = yield call(updateBrand, id, data)
    if (response.status) {
      yield put(updateBrandSuccess(UPDATE_BRAND_SUCCESS, response.data))
      history.push("/v1/brands")
    } else {
      yield put(
        updateBrandFail(
          UPDATE_BRAND_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANDS-1001"
        )
      )
    }
    yield put(brandLoading(false))
  } catch (error) {
    yield put(updateBrandFail(UPDATE_BRAND_FAIL, error))
    yield put(brandLoading(false))
  }
}

function* deleteBrandFetch({ payload: { id, history } }) {
  try {
    yield put(brandLoading(true))
    var response = yield call(deleteBrand, id)
    if (response.status) {
      yield put(deleteBrandSuccess(DELETE_BRAND_SUCCESS, response.data))
      history.push("/v1/brands")
    } else {
      yield put(
        deleteBrandFail(
          DELETE_BRAND_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANDS-1001"
        )
      )
    }
    yield put(brandLoading(false))
  } catch (error) {
    yield put(deleteBrandFail(DELETE_BRAND_FAIL, error))
    yield put(brandLoading(false))
  }
}

function* getBrandByIdFetch({ payload: { id } }) {
  try {
    yield put(brandLoading(true))
    var response = yield call(getBrand, id)
    if (response.status) {
      yield put(getBrandSuccess(GET_BRAND_SUCCESS, response.data))
    } else {
      yield put(
        getBrandFail(
          GET_BRAND_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANDS-1001"
        )
      )
    }
    yield put(brandLoading(false))
  } catch (error) {
    yield put(getBrandFail(GET_BRAND_FAIL, error))
    yield put(brandLoading(false))
  }
}

function* brandsSaga() {
  yield takeEvery(GET_BRANDS, getBrandsFetch)
  yield takeEvery(GET_BRAND, getBrandByIdFetch)
  yield takeEvery(ADD_BRAND, addBrandFetch)
  yield takeEvery(UPDATE_BRAND, updateBrandFetch)
  yield takeEvery(DELETE_BRAND, deleteBrandFetch)
}

export default brandsSaga
