import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { Role, authorize } from "helpers/authorize"
import './datatables.scss'
//redux

const Settings = props => {
  const dispatch = useDispatch()

  //meta title
  document.title = props.t("Settings")

  const [obj, setObj] = useState(JSON.parse(localStorage.getItem('authUser')))

  const links = [
    {
      title: props.t("Companies"),
      description: props.t("Companies Description"),
      href: `/v1/companies`,
      iconClass: "bx-git-branch",
      role:[Role.Dev]
    }, 
    {
      title: props.t("Branches"),
      description: props.t("Branches Description"),
      href: `/v1/companies/branches/${obj.cid}`,
      iconClass: "bx-git-branch",
      role:[Role.Admin]
    },
    {
      title: props.t("Persons"),
      description: props.t("Persons Description"),
      href: `/v1/companies/persons?cid=${obj.cid}`,
      iconClass: "bx-user",
      role:[Role.Admin]
    }, 
    {
      title: props.t("Persons"),
      description: props.t("Persons Description"),
      href: `/v1/companies/persons?cid=${obj.cid}&bid=${obj.bid}`,
      iconClass: "bx-user",
      role:[Role.Manager, Role.Coordinator]
    },
    {
      title: props.t("Branch Groups"),
      description: props.t("Branch Groups Description"),
      href: `/settings/branch-groups`,
      iconClass: "bx-git-pull-request",
      role:[Role.Dev]
    }, 
    {
      title: props.t("Products"),
      description: props.t("Products Description"),
      href: `/v1/products`,
      iconClass: "bx-package",
      role:[Role.Dev]
    }, 
    {
      title: props.t("ClassNames"),
      description: props.t("ClassNames Description"),
      href: `/settings/class-names`,
      iconClass: "bx-store",
      role:[Role.Dev]
    }, 
    {
      title: props.t("Categories"),
      description: props.t("Categories Description"),
      href: `/settings/categories`,
      iconClass: "bx-purchase-tag",
      role:[Role.Dev]
    },
  ]

  const breadcrumbItems = [
    { title: props.t("Settings"), link: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItems={breadcrumbItems} 
            title={props.t("Settings")}
            backLink={`/`}
            isImportExcel={false}
            isDownloadLink={false} 
            className={"mb-0 pb-0"}
          /> 
          <Row className="mt-3">
            {links.map((item, idx) => authorize(item.role)?(
              <Col md={3} key={idx}>
                <a href={item.href}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                    <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {item.title}
                            </p>
                            <h4 className="mb-0">{item.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + item.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>

                    </CardBody>
                  </Card>
                </a>
              </Col>
            ):null)}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Settings.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(Settings)
