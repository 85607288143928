import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import {
  campaigns,
  branchGroup,
  branchesForGroups,
} from "./../../../common/data/campaign"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import img_forward from "./../../../assets/images/custom/forward.png"
import { isEmpty } from "lodash"
import CustomCheckbox from "components/Common/CustomCheckbox"

const CampaignBranches = props => {
  //meta title
  document.title = props.t("Campaign Details")

  const [groups, setGroups] = useState(branchGroup)
  const [branches, setBranches] = useState([])
  const [check_branch_groups, set_check_branch_groups] = useState("false")
  const [check_branches, set_check_branches] = useState("false")

  const {
    match: { params },
  } = props

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Campaign Details"), link: `/v1/campaigns/${params.id}` },
    { title: props.t("Branches"), link: `#` },
  ]

  useEffect(() => {
    // if (isEmpty(groups)) {
    //   setGroups(branchGroup)
    // } else {
    //   Swal.fire(props.t("Not Found"), "", "error")
    // }
  }, [])

  const branchCheckAllControl = () => {
    let temp = [...branches]
    if (temp.filter(x => x.status == "true").length == temp.length) {
      set_check_branches("true")
    } else if (temp.filter(x => x.status == "false").length == temp.length) {
      set_check_branches("false")
    } else {
      set_check_branches("minus")
    }
  }
  const branchGroupCheckAllControl = () => {
    let temp = [...groups]
    if (temp.filter(x => x.status == "true").length == temp.length) {
      set_check_branch_groups("true")
    } else if (temp.filter(x => x.status == "false").length == temp.length) {
      set_check_branch_groups("false")
    } else {
      set_check_branch_groups("minus")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaign Details")}
            backLink={`/v1/campaigns/${params.id}`}
            breadcrumbItems={breadcrumbItems}
          />

          {groups && groups ? (
            <Row>
              <Col md={6}>
                <Card>
                  <CardHeader className="bg-white border-bottom">
                    <div className="group-item">
                      <CustomCheckbox
                        label={props.t("Branch Groups")}
                        status={check_branch_groups}
                        onChange={v => {
                          set_check_branch_groups(v)
                          let temp = [...groups]
                          let global_status = ""
                          for (let i = 0; i < temp.length; i++) {
                            if (v == "true" && temp[i].status == "false") {
                              temp[i].status = "true"
                              global_status = "true"
                            } else if (
                              v == "false" &&
                              temp[i].status == "true"
                            ) {
                              temp[i].status = "false"
                              global_status = "false"
                            }
                          }
                          setGroups(temp)

                          if (global_status == "true") {
                            let l1 = branchesForGroups
                            for (let i = 0; i < l1.length; i++) {
                              l1[i].status = "true"
                            }
                            setBranches(l1)
                          } else if (global_status == "false") {
                            setBranches([])
                          }
                        }}
                      />
                    </div>
                  </CardHeader>
                  <CardBody className="group-list">
                    {groups.map((item, idx) => (
                      <div key={idx} className="m-2 group-item">
                        <CustomCheckbox
                          label={item.name}
                          status={item.status}
                          onChange={v => {
                            if (v) {
                              let temp = [...groups]
                              temp[idx].status = v
                              setGroups(temp)

                              if (v == "true") {
                                let l1 = branchesForGroups.filter(
                                  x =>
                                    x.Group.toString() ===
                                    temp[idx]._id.toString()
                                )
                                for (let i = 0; i < l1.length; i++) {
                                  l1[i].status = "true"
                                }
                                // for (let b of branches) {
                                //   let dIndex = l1.findIndex(x => x._id == b._id)
                                //   if (dIndex != -1) {
                                //     //exist -- Delete item
                                //     l1 = l1.filter(x => x._id != b._id)
                                //   }
                                // }
                                setBranches(currentObject =>
                                  currentObject.concat(l1)
                                )
                              } else {
                                setBranches(
                                  branches.filter(
                                    data =>
                                      data.Group.toString() !==
                                      temp[idx]._id.toString()
                                  )
                                )
                              }
                              branchGroupCheckAllControl()
                              branchCheckAllControl()
                            }
                          }}
                        />
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardHeader className="bg-white border-bottom">
                    <div className="group-item">
                      <Label className="ms-2">{props.t("Branches")}</Label>
                      {/* <CustomCheckbox
                        label={props.t("Branches")}
                        status={'true-passive'}
                        // onChange={v => {
                        //   set_check_branches(v)
                        //   let temp = [...branches]
                        //   for (let i = 0; i < temp.length; i++) {
                        //     if (v == "true" && temp[i].status == "false")
                        //       temp[i].status = "true"
                        //     else if (v == "false" && temp[i].status == "true")
                        //       temp[i].status = "false"
                        //   }
                        //   setBranches(temp)
                        // }}
                      /> */}
                    </div>
                  </CardHeader>
                  <CardBody className="group-list">
                    {branches.map((item, idx) => (
                      <div key={idx} className="m-2 group-item">
                        <CustomCheckbox
                          label={item.name}
                          status={item.status}
                          onChange={v => {
                            if (v) {
                              let tempGroup = [...groups]
                              let temp = [...branches]
                              temp[idx].status = v
                              for (let i = 0; i < temp.length; i++) {
                                if (temp[i]._id == temp[idx]._id) {
                                  temp[i].status = v
                                }
                              }
                              //group control
                              let index = tempGroup.findIndex(
                                x => x._id == item.Group
                              )
                              if (v == "false" && index != -1) {
                                if (
                                  temp.findIndex(
                                    x =>
                                      x.Group == item.Group &&
                                      x.status == "true"
                                  ) == -1
                                ) {
                                  tempGroup[index].status = "false"
                                  setGroups(tempGroup)
                                } else {
                                  tempGroup[index].status = "minus"
                                  setGroups(tempGroup)
                                }
                              } else if (v == "true" && index != -1) {
                                if (
                                  temp.findIndex(
                                    x =>
                                      x.Group == item.Group &&
                                      x.status == "false"
                                  ) == -1
                                ) {
                                  tempGroup[index].status = "true"
                                  setGroups(tempGroup)
                                } else {
                                  tempGroup[index].status = "minus"
                                  setGroups(tempGroup)
                                }
                              }
                              setBranches(temp)

                              branchCheckAllControl()
                            }
                          }}
                        />
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignBranches.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CampaignBranches)
