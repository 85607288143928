import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ChangePassword from "./auth/changepwd/reducer"
import Profile from "./auth/profile/reducer"
import Notification from "./auth/notification/reducer"

import Companies from "./companies/reducer"
import Brands from "./brands/reducer"
import Campaigns from "./campaigns/reducer"
import Products from "./products/reducer"
import ClassNames from "./classNames/reducer"
import Categories from "./categories/reducer"
import SubCategories from "./subCategories/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ChangePassword,
  Companies,
  Brands,
  Campaigns,
  Products,
  ClassNames,
  Categories,
  SubCategories,
  Profile,
  Notification,
  Dashboard,
})

export default rootReducer
