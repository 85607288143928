import {
  NOTIFICATION_API_ERROR,
  NOTIFICATION,
  NOTIFICATION_LIST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_MINI,
  NOTIFICATION_MINI_SUCCESS,
  NOTIFICATION_SUCCESS,
} from "./actionTypes"

export const notificationUser = (data, history) => {
  return {
    type: NOTIFICATION,
    payload: { data, history },
  }
}

export const notificationUserSuccess = data => {
  return {
    type: NOTIFICATION_SUCCESS,
    payload: data,
  }
}

export const notificationListUser = (data, history) => {
  return {
    type: NOTIFICATION_LIST,
    payload: { data, history },
  }
}

export const notificationListUserSuccess = data => {
  return {
    type: NOTIFICATION_LIST_SUCCESS,
    payload: data,
  }
}

export const notificationMiniUser = (data, history) => {
  return {
    type: NOTIFICATION_MINI,
    payload: { data, history },
  }
}

export const notificationMiniUserSuccess = data => {
  return {
    type: NOTIFICATION_MINI_SUCCESS,
    payload: data,
  }
}

export const notificationApiError = error => {
  return {
    type: NOTIFICATION_API_ERROR,
    payload: error,
  }
}
