import { 
  SUBCATEGORIES_RESET_ERROR,
  SUBCATEGORIES_RESET, 
  SUBCATEGORIES_RESET_SUCCESS,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORIES_FAIL, 
  ADD_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_FAIL,
  UPDATE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY_FAIL,
  GET_SUBCATEGORY_SUCCESS,
  GET_SUBCATEGORY_FAIL,
  SUBCATEGORY_RESET,
  SUBCATEGORY_LOADING, 
} from "./actionTypes"

const INIT_STATE = {
  data: [], 
  subCategory: {}, 
  error: null,
  success: null,
  loading: true,
}

const SubCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload?.data, 
      }
    case GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: action.payload?.data,
      }
    case ADD_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }  
    case GET_SUBCATEGORIES_FAIL: 
    case GET_SUBCATEGORY_FAIL:
    case ADD_SUBCATEGORY_FAIL:
    case UPDATE_SUBCATEGORY_FAIL:
    case DELETE_SUBCATEGORY_FAIL: 
      return {
        ...state,
        error: action.payload,
      }
    case SUBCATEGORIES_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case SUBCATEGORIES_RESET_ERROR:
      return {
        ...state,
        error: null,
      } 
    case SUBCATEGORY_RESET:
      return {
        ...state,
        subCategory: {},
        branch: {},
      }
    case SUBCATEGORIES_RESET:
      return {
        ...state,
        data: [],
        subCategory: null, 
        error: null,
        success: null, 
      }
    case SUBCATEGORY_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default SubCategories
