import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  getCampaignNewData as onGetNewData,
  getCampaign as onGet,
  updateCampaignNewData as onUpdate,
  postCampaignNewData as onPost,
  getBrands as onGetBrands,
  brandsResetError as onClearError,
  clearCampaign as onClearCampaign,
  campaignSuccess as onSuccess,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { ErrorMessage, useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"
import InputMask from "react-input-mask"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import img_empty from "./../../../assets/images/jobs.png"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import { Role, authorize } from "helpers/authorize"

// import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js"
// import draftToHtml from "draftjs-to-html"
// import htmlToDraft from "html-to-draftjs"

const CampaignNewDataList = props => {
  //meta title
  document.title = props.t("New Data List")

  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const [data, setData] = useState([])
  const [nData, setNData] = useState()
  const [list, setList] = useState()
  const [listOptions, setListOptions] = useState(null)
  const [loading2, setLoading2] = useState(false)
  const [listParameters, setListParameters] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 10,
    page: 0,
  })
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const [text, setText] = useState()

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE

  const [imageZoom, setimageZoom] = useState([img_empty])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("New Data"), link: "#" },
  ]

  const dispatch = useDispatch()
  const { error, campaign, loading, success } = useSelector(state => ({
    error: state.Campaigns?.error,
    campaign: state.Campaigns?.campaign,
    loading: state.Campaigns?.loading || state.Brands?.loading || false,
    success: state.Campaigns?.success,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(campaign)) {
      dispatch(onGet(params.id))
    }
  }, [])

  useEffect(() => {
    GetList()
  }, [listParameters])

  useEffect(() => {
    if (!isEmpty(campaign)) {
      if (!isEmpty(campaign.image)) {
        setimageZoom([IMAGE_BASE + campaign.image])
      }
      const temp_data = []
      for (let info of campaign.requestInfo) {
        let infoIndex = requestList.findIndex(x => x.value == info)
        if (infoIndex > -1) {
          temp_data.push({
            id: requestList[infoIndex].value,
            name: requestList[infoIndex].value,
            label: requestList[infoIndex].label,
            value: "",
            type: requestList[infoIndex].type,
          })
        }
      }
      setData(temp_data)
    }
  }, [campaign])

  useEffect(() => {
    if (!isEmpty(success)) {
      Swal.fire(success.title, success.message, success.status).then(
        ({ isConfirmed }) => {
          dispatch(onSuccess(null))
        }
      )
    }
  }, [success])

  const requestList = [
    { label: "Seri Numarası", value: "seri-numarasi", type: "text" },
    { label: "Barkod", value: "barcode", type: "text" },
    { label: "Müşteri Adı", value: "musteri-adi", type: "text" },
    { label: "Müşteri Soyadı", value: "musteri-soyadi", type: "text" },
    { label: "Müşteri TC", value: "musteri-tc", type: "number" },
    { label: "Müşteri Numarası", value: "musteri-numarasi", type: "text" },
    { label: "Müşteri Telefon No", value: "musteri-telefon-no", type: "phone" },
    { label: "Müşteri E-Posta", value: "musteri-e-posta", type: "text" },
    { label: "Fatura No", value: "fatura-no", type: "text" },
    { label: "Fatura Tarihi", value: "fatura-tarihi", type: "date" },
  ]

  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Campaign_Continues")}
          </span>
        )
      case "wait":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Wait")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Campaign_Finish")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-light">
            {props.t("Campaign_Other")}
          </span>
        )
    }
  }

  const dataStatusText = status => {
    switch (status) {
      case "wait":
        return (
          <span className="badge badge-soft-warning">{props.t("wait")}</span>
        )
      case "confirmed":
        return (
          <span className="badge badge-soft-success">
            {props.t("confirmed")}
          </span>
        )
      case "rejected":
        return (
          <span className="badge badge-soft-danger">{props.t("rejected")}</span>
        )
      default:
        return (
          <span className="badge badge-soft-danger">
            {props.t("new-data-status-other")}
          </span>
        )
    }
  }

  const GetList = async () => {
    setLoading2(true)
    let data = JSON.stringify(listParameters)

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/campaigns/new-data-list/${params.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          let p = response.data.data
          setList(p)
          let pageCount = response.data.pageCount
          let paging = [1]
          if (pageCount > 1 && pageCount < 8) {
            for (let i = 2; i <= pageCount; i++) {
              paging.push(i)
            }
          } else if (
            pageCount > 1 &&
            pageCount >= 8 &&
            (listParameters.page == 0 || listParameters.page == pageCount - 1)
          ) {
            let center = pageCount / 2
            paging.push(center - 1)
            paging.push(center)
            paging.push(center + 1)
            paging.push(pageCount)
          } else if (pageCount > 1 && pageCount >= 8) {
            let center = listParameters.page + 1
            if (!paging.includes(center - 1)) paging.push(center - 1)
            if (!paging.includes(center)) paging.push(center)
            if (!paging.includes(center + 1)) paging.push(center + 1)
            if (!paging.includes(pageCount)) paging.push(pageCount)
          }

          setListOptions({
            productCount: response.data.productCount,
            pageCount: response.data.pageCount,
            productTotal: response.data.productTotal,
            paging: paging,
          })
        } else {
          setList(null)
          setListOptions(null)
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setList(null)
        setListOptions(null)
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  const onDelete = async id => {
    setLoading2(true)

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/campaigns/new-data/${id}/${params.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
    }

    await axios
      .request(config)
      .then(response => {
        console.log(response.data.message)
        console.log(JSON.stringify(response.data))
        if (response.data.status == true) {
          let id = response.data.data
          if (id) {
            let temp = [...list]
            temp = temp.filter(x => x._id != id)
            setList(temp)
          }
        } else {
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaigns")}
            backLink={"/v1/campaigns"}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading2} />
          {isGalleryZoom ? (
            <Lightbox
              mainSrc={imageZoom[photoIndex]}
              nextSrc={imageZoom[(photoIndex + 1) % imageZoom.length]}
              prevSrc={
                imageZoom[
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                ]
              }
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % imageZoom.length)
              }}
            />
          ) : null}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New Data List")}</h4>
                  <p className="card-title-desc">
                    {props.t("New Data List Description")}
                  </p>

                  <Row>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          {campaign.image ? (
                            <img
                              src={IMAGE_BASE + campaign.image}
                              className="campaign-img"
                              onClick={() => {
                                setisGalleryZoom(true)
                                setphotoIndex(0)
                              }}
                            />
                          ) : (
                            <img src={img_empty} className="campaign-img" />
                          )}
                        </Col>
                        <Col md={12}>
                          <span className="title">{campaign.Brand?.name}</span>
                        </Col>
                        <Col className="text-end">
                          {cardStatusText(campaign.status)}
                        </Col>
                        <Col md={12} className="text-end">
                          <div className="date-div">
                            <div className="date-title">
                              <span>{props.t("Start Date")} : </span>
                            </div>
                            <div className="date">
                              {moment(campaign.startDate).format("DD.MM.YYYY")}
                            </div>
                          </div>
                          <div className="date-div">
                            <div className="date-title">
                              <span>{props.t("End Date")} : </span>
                            </div>
                            <div className="date">
                              {moment(campaign.finishDate).format("DD.MM.YYYY")}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="mt-2">
                          <span className="title">{campaign.name}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      <div>
                        <div className="mt-1 float-start">
                          {props.t("Rec Total", {
                            val: listOptions?.productTotal || 0,
                          })}
                        </div>
                      </div>
                      <div className="mb-2 d-flex flex-row-reverse">
                        <select
                          className="ms-2"
                          value={listParameters.limit}
                          onChange={e => {
                            let temp = { ...listParameters }
                            temp.limit = parseInt(e.target.value)
                            setListParameters(temp)
                          }}
                        >
                          {[10, 20, 50, 100, 200].map((item, idx) => (
                            <option key={idx}>{item}</option>
                          ))}
                        </select>
                        {authorize([
                          Role.Admin,
                          Role.Coordinator,
                          Role.Manager,
                          Role.User,
                        ]) ? (
                          <a
                            className="btn btn-sm btn-success"
                            href={`/v1/campaigns/new-data/${params.id}`}
                          >
                            <i className="bx bx-plus"></i> {props.t("New Data")}
                          </a>
                        ) : null}
                      </div>

                      {list ? (
                        <>
                          <div className="table-responsive">
                            <table className="react-table table table-bordered">
                              <thead>
                                <tr>
                                  <th>{props.t("Status")}</th>
                                  <th>{props.t("Product")}</th>
                                  {list[0]?.requestInfo
                                    .filter(
                                      x =>
                                        x.key != "Product" && x.key != "Variant"
                                    )
                                    .map((item, idx) => (
                                      <th key={idx}>{props.t(item.key)}</th>
                                    ))}

                                  <th>{props.t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list.map((item, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      {item.confirmMessage ||
                                      item.autoMessage ? (
                                        <a
                                          onClick={() => {
                                            Swal.fire(
                                              props.t(item.status),
                                              item.confirmMessage ||
                                                item.autoMessage,
                                              item.status == "rejected"
                                                ? "error"
                                                : item.status == "confirmed"
                                                ? "success"
                                                : "warning"
                                            )
                                          }}
                                        >
                                          {dataStatusText(item.status)}
                                        </a>
                                      ) : (
                                        dataStatusText(item.status)
                                      )}{" "}
                                    </td>
                                    <td>
                                      {item.Product?.name}
                                      {item.Variant
                                        ? ` - ${item.Variant?.name}`
                                        : ""}
                                    </td>
                                    {item.requestInfo
                                      .filter(
                                        x =>
                                          x.key != "Product" &&
                                          x.key != "Variant"
                                      )
                                      .map((info, index) => (
                                        <td key={index}>{info.value}</td>
                                      ))}
                                    <td>
                                      {item.status == "wait" ? (
                                        <>
                                          <a
                                            title={props.t("Edit")}
                                            className="me-2"
                                            href={`/v1/campaigns/new-data/${params.id}?id=${item._id}`}
                                          >
                                            <i className="mdi mdi-grease-pencil text-primary"></i>
                                          </a>
                                          <a
                                            title={props.t("Delete")}
                                            onClick={() => {
                                              Swal.fire({
                                                title: props.t("Are You Sure"),
                                                text: props.t("DeleteWarning"),
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  props.t("Yes, delete it!"),
                                                cancelButtonText:
                                                  props.t("Cancel"),
                                              }).then(result => {
                                                if (result.isConfirmed) {
                                                  dispatch(onDelete(item._id))
                                                }
                                              })
                                            }}
                                          >
                                            <i className="mdi mdi-close-thick text-danger"></i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {listOptions ? (
                              <>
                                <div className="d-flex flex-row float-end">
                                  <Button
                                    className="me-1 btn-sm"
                                    color="light"
                                    disabled={
                                      listParameters.page == 0 ||
                                      listOptions.pageCount == 1
                                    }
                                    onClick={() => {
                                      let temp = { ...listParameters }
                                      temp.page = listParameters.page - 1
                                      setListParameters(temp)
                                    }}
                                  >
                                    <i className="bx bx-chevron-left"></i>
                                  </Button>
                                  {listOptions.paging.map((item, idx) => {
                                    if (
                                      idx > 0 &&
                                      listOptions.paging[idx] -
                                        listOptions.paging[idx - 1] >
                                        1
                                    ) {
                                      return (
                                        <div
                                          key={idx}
                                          className="d-flex flex-row"
                                        >
                                          <Button
                                            className="me-1 btn-sm"
                                            color="light"
                                            disabled={true}
                                          >
                                            ...
                                          </Button>
                                          <Button
                                            className="me-1 btn-sm"
                                            color="light"
                                            onClick={() => {
                                              let temp = { ...listParameters }
                                              temp.page = item - 1
                                              setListParameters(temp)
                                            }}
                                          >
                                            {item}
                                          </Button>
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <Button
                                          key={idx}
                                          className="me-1 btn-sm"
                                          color="light"
                                          onClick={() => {
                                            let temp = { ...listParameters }
                                            temp.page = item - 1
                                            setListParameters(temp)
                                          }}
                                        >
                                          {item}
                                        </Button>
                                      )
                                    }
                                  })}
                                  <Button
                                    className="me-1 btn-sm"
                                    color="light"
                                    disabled={
                                      listParameters.page ==
                                        listOptions.pageCount - 1 ||
                                      listOptions.pageCount == 1
                                    }
                                    onClick={() => {
                                      let temp = { ...listParameters }
                                      temp.page = listParameters.page + 1
                                      setListParameters(temp)
                                    }}
                                  >
                                    <i className="bx bx-chevron-right"></i>
                                  </Button>
                                </div>
                              </>
                            ) : null}
                          </div>
                          {/* {listParameters.page}
                          {listOptions.pageCount - 1}
                          {JSON.stringify(listOptions)} 
                          {JSON.stringify(listParameters)} */}
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignNewDataList.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignNewDataList)
