export const NOTIFICATION_LIST = "NOTIFICATION_LIST"
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS"

export const NOTIFICATION = "NOTIFICATION"
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS"

export const NOTIFICATION_MINI = "NOTIFICATION_MINI"
export const NOTIFICATION_MINI_SUCCESS = "NOTIFICATION_MINI_SUCCESS"
 
export const NOTIFICATION_API_ERROR = "NOTIFICATION_API_ERROR"
 
