import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useDispatch, useSelector } from "react-redux"
import { companies } from "./../../../common/data/companies"
import { getCompanies as onList } from "store/actions"
import TableContainerServerSide from "components/Common/TableContainerServerSide"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import TableContainer from "../../../components/Common/TableContainer"
import { isEmpty } from "lodash"
import { Role, authorize } from "helpers/authorize"

const Companies = props => {
  //meta title
  document.title = props.t("Companies")

  const dispatch = useDispatch()
  const { error, data, tableOptions, loading } = useSelector(state => ({
    error: state.Companies?.error,
    data: state.Companies?.data || [],
    tableOptions: state.Companies?.tableOptions || null,
    loading: state.Companies?.loading || false,
  }))

  const breadcrumbItems = [{ title: props.t("Companies"), link: "/" }]

  const [selectedItem, setSelectedItem] = useState("all")
  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 100,
    page: 0,
    select: "name city",
    search: "",
  })

  //const [data, setData] = useState(companies)

  useEffect(() => {
    dispatch(onList(tableSetting))
  }, [tableSetting])

  const columns = useMemo(
    () => [
      {
        Header: props.t("Company Name"),
        accessor: "name",
        search: false,
      },
      {
        Header: props.t("City"),
        accessor: "city",
        search: false,
      },
      {
        Header: "",
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {authorize([Role.Dev, Role.Admin]) ? (
              <>
                <Link
                  to={`/v1/companies/branches/${value}`}
                  className="btn btn-sm btn-warning text-white me-2"
                >
                  {props.t("Branches")}
                </Link>
                <Link
                  to={`/v1/companies/persons?cid=${value}`}
                  className="btn btn-sm btn-warning text-white me-2"
                >
                  {props.t("Persons")}
                </Link>
                <Link
                  to={`/v1/companies/edit/${value}`}
                  className="btn btn-sm btn-warning text-white me-2"
                >
                  {props.t("Edit")}
                </Link>
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItems={[]}
            isAdd={authorize([Role.Dev, Role.Admin])}
            addTitle={props.t("Create Company")}
            addHandle={() => {
              props.history.push("/v1/companies/create")
            }}
            className={"mb-0 pb-0"}
          />

          <TableContainerServerSide
            loading={loading}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            tableSetting={tableSetting}
            customPageSize={tableSetting.limit}
            className="custom-header-css"
            totalCount={tableOptions?.productTotal || 0}
            queryPageIndex={tableSetting.page}
            queryPageSize={tableSetting.limit}
            tableOptions={tableOptions}
            action={(pageIndex, pageSize, search) => {
              setTableSetting({
                orderBy: "createdAt",
                ascDesc: -1,
                limit: pageSize,
                page: pageIndex,
                select: tableSetting.select,
                search: search,
              })
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Companies.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(Companies)
