import axios from "axios"
import { del, get, post, postX, postXFileOnly, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)
const postJwtLoginMulti = data => post(url.POST_JWT_LOGIN_MULTI, data)
const postJwtLoginMultiToken = data => post(url.POST_JWT_LOGIN_MULTI_TOKEN, data)



const getNotifications = (page, limit) => get(`${url.GET_NOTIFICATIONS}?page=${page}&limit=${limit}`)
//?page=0&limit=5

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)

// postChangePwd
const postJwtChangePwd = data => post(url.POST_PASSWORD_CHANGE, data)
 
// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

//GLOBAL SETTINGS
export const getCompanies = data => post(`${url.BASE_API}/companies/list`, data)
export const getCompany = id => get(`${url.BASE_API}/companies/${id}`)
export const addCompany = data => post(`${url.BASE_API}/companies/add`, data)
export const updateCompany = (id, data) =>
  put(`${url.BASE_API}/companies/update/${id}`, data)
export const deleteCompany = id => del(`${url.BASE_API}/companies/del/${id}`)

 

export const getBranches = (id, data) =>
  post(`${url.BASE_API}/companies/branches/list/${id}`, data)
export const getBranch = id => get(`${url.BASE_API}/companies/branch/${id}`)
export const addBranch = (cid, data) =>
  post(`${url.BASE_API}/companies/branch/add/${cid}`, data)
export const updateBranch = (id, cid, data) =>
  put(`${url.BASE_API}/companies/branch/update/${cid}/${id}`, data)
export const deleteBranch = (id, cid) =>
  del(`${url.BASE_API}/companies/branch/del/${cid}/${id}`)


  export const getBranchGroups = (data) =>
  post(`${url.BASE_API}/branch-groups/list`, data)
export const getBranchGroup = id => get(`${url.BASE_API}/branch-groups/${id}`)
export const addBranchGroup = (data) =>
  post(`${url.BASE_API}/branch-groups/add`, data)
export const updateBranchGroup = (id, data) =>
  put(`${url.BASE_API}/branch-groups/update/${id}`, data)
export const deleteBranchGroup = (id) =>
  del(`${url.BASE_API}/branch-groups/del/${id}`)


export const getBanks = () => get(`${url.BASE_API}/companies/banks`)

export const getCities = () => get(`${url.BASE_API}/locations/cities`)
export const getDistricts = id =>
  get(`${url.BASE_API}/locations/districts/${id}`)

//PERSONS
export const getPersons = (bid, cid, data) =>
  post(`${url.BASE_API}/companies/persons/list?bid=${bid}&cid=${cid}`, data)
export const getPerson = id => get(`${url.BASE_API}/companies/person/${id}`)
export const addPerson = data =>
  post(`${url.BASE_API}/companies/person/add`, data)
export const updatePerson = (id, data) =>
  put(`${url.BASE_API}/companies/person/update/${id}`, data)
export const deletePerson = (id, cid) =>
  del(`${url.BASE_API}/companies/person/del/${cid}/${id}`)

//BRANDS
export const getBrands = () => get(`${url.BASE_API}/brands`)
export const getBrand = id => get(`${url.BASE_API}/brands/${id}`)
export const addBrand = data => post(`${url.BASE_API}/brands/add`, data)
export const updateBrand = (id, data) =>
  put(`${url.BASE_API}/brands/update/${id}`, data)
export const deleteBrand = id => del(`${url.BASE_API}/brands/del/${id}`)

//CAMPAIGNS
export const getCampaigns = () => get(`${url.BASE_API}/campaigns`)
export const getCampaign = id => get(`${url.BASE_API}/campaigns/${id}`)
export const addCampaign = (data, file) =>
  postX(`${url.BASE_API}/campaigns/add`, data, file)
export const updateCampaign = (id, data, file) =>
  postX(`${url.BASE_API}/campaigns/update/${id}`, data, file)
export const deleteCampaign = id => del(`${url.BASE_API}/campaigns/del/${id}`)


export const postCampaignProductType_Step4 = (id, data) => post(`${url.BASE_API}/campaigns/post-product-type/${id}`, data)
export const postCampaignStatus = (id, data) => post(`${url.BASE_API}/campaigns/status-change/${id}`, data)

export const postCampaignNewData = (id, data, dataId) => post(`${url.BASE_API}/campaigns/new-data/${id}${dataId!=null?`?id=${dataId}`:''}`, data)
export const getCampaignNewData = (id, campaignId) => get(`${url.BASE_API}/campaigns/new-data/${id}/${campaignId}`)


export const getBranchGroupByCampaign = id => get(`${url.BASE_API}/branch-groups/status/group/${id}`)
export const updateBranchGroupByCampaign = (id, data) => put(`${url.BASE_API}/branch-groups/status/group/${id}`, data)
 


export const getTermsCampaign = (id, level) => get(`${url.BASE_API}/campaigns/terms/${id}`) 
export const getTermCampaign = (id, level) => get(`${url.BASE_API}/campaigns/terms/${id}/${level}`) 
export const addTermCampaign = (id, data) => post(`${url.BASE_API}/campaigns/terms/${id}/new`, data) 
export const updateTermCampaign = (id, level, data) => post(`${url.BASE_API}/campaigns/terms/${id}/${level}`, data) 
export const deleteTermCampaign = (id, level) => del(`${url.BASE_API}/campaigns/terms/${id}/${level}`) 

// get Products
export const getProductsAll = () => get(`${url.BASE_API}/products/list-all`)
export const getProducts = data => post(`${url.BASE_API}/products/list`, data)
export const getProduct = id => get(`${url.BASE_API}/products/${id}`)
export const addProduct = data => post(`${url.BASE_API}/products/add`, data)
export const updateProduct = (id, data) =>
  put(`${url.BASE_API}/products/update/${id}`, data)
export const deleteProduct = id => del(`${url.BASE_API}/products/del/${id}`)

export const importProductXLSX = (file) => postXFileOnly(`${url.BASE_API}/products/xlsx-product-import`, file)

//VARIANT

export const getVariants = (pid, data) => post(`${url.BASE_API}/products/variants/list/${pid}`, data)
export const getVariants_v2 = (pid, data) => post(`${url.BASE_API}/products/variants/v2/list/${pid}`, data)
export const getVariant = (id) => get(`${url.BASE_API}/products/variants/${id}`)
export const addVariant = data => post(`${url.BASE_API}/products/variants/add`, data)
export const updateVariant = (id, data) =>
  put(`${url.BASE_API}/products/variants/update/${id}`, data)
export const deleteVariant = id => del(`${url.BASE_API}/products/variants/del/${id}`)

export const getSeriesList = (pid, vid, data) => post(`${url.BASE_API}/products/variants/series/list/${pid}/${vid}`, data)
export const getSeries = (pid, vid, id) => get(`${url.BASE_API}/products/variants/series/${pid}/${vid}/${id}`)
export const addSeries = data => post(`${url.BASE_API}/products/variants/series/add`, data)
export const updateSeries = (id, data) =>
  put(`${url.BASE_API}/products/variants/series/update/${id}`, data)
export const deleteSeries = (pid, vid, id) => del(`${url.BASE_API}/products/variants/series/del/${id}?pid=${pid}&vid=${vid}`)

//BRANDS
export const getClassNames = () => get(`${url.BASE_API}/class-names`) 
export const getClassName = id => get(`${url.BASE_API}/class-names/${id}`)
export const addClassName = data => post(`${url.BASE_API}/class-names/add`, data)
export const updateClassName = (id, data) =>
  put(`${url.BASE_API}/class-names/update/${id}`, data)
export const deleteClassName = id => del(`${url.BASE_API}/class-names/del/${id}`)
 
export const getCategories = () => get(`${url.BASE_API}/categories/list`) 
export const getCategory = id => get(`${url.BASE_API}/categories/${id}`)
export const addCategory = data => post(`${url.BASE_API}/categories/add`, data)
export const updateCategory = (id, data) =>
  put(`${url.BASE_API}/categories/update/${id}`, data)
export const deleteCategory = id => del(`${url.BASE_API}/categories/del/${id}`)


export const getSubCategories = id => get(`${url.BASE_API}/categories/sub/list/${id}`)
export const getSubCategory = id => get(`${url.BASE_API}/categories/sub/${id}`)
export const addSubCategory = data => post(`${url.BASE_API}/categories/sub/add`, data)
export const updateSubCategory = (id, data) =>
  put(`${url.BASE_API}/categories/sub/update/${id}`, data)
export const deleteSubCategory = id => del(`${url.BASE_API}/categories/sub/del/${id}`)

 
// get Product detail
// export const getProductDetail = id =>
//   get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories 

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST)

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB)

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job)

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job)

// delete jobs
export const deleteJobList = job =>
  del(url.DELETE_JOB_LIST, { headers: { job } })

// Delete Apply Jobs
export const deleteApplyJob = data =>
  del(url.DELETE_APPLY_JOB, { headers: { data } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,postJwtLoginMulti, postJwtLoginMultiToken,getNotifications,
  postJwtForgetPwd,postJwtChangePwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
