const terms_step_1 = [
  { value: "1", label: "X Adetten Fazla Sat Adet Başı Y PuanK Kazan" },
  { value: "2", label: "X Adet Sat  Toplam Y PuanK Kazan" },
]
const terms_step_2 = [
  {
    value: "1",
    label: "Personel Hedefi",
    description:
      "Aşağıda verilen hedef koşulları sadece personelin kendi sattığı adetleri kapsar",
  },
  {
    value: "2",
    label: "Şube Hedefi",
    description: "Aşağıda verilen hedef koşulları şubenin tamamı içindir",
  },
  {
    value: "3",
    label: "Şirket Hedefi",
    description: "Aşağıda verilen hedef koşulları şirketin tamamı içindir",
  },
]

const default_term_step_1_object = {
    startCount: "1",
    endCount: "",
    gainPerson: "",
    gainManager: "",
    gainCoordinator: "",
    gainBranch: "",
  };
const default_term_step_2_object = {
    count: "0",
    gainPerson: "",
    gainManager: "",
    gainCoordinator: "",
    gainBranch: "",
  };
const default_variant = {
  _id:"",
  name:"",
  barcode:"", 
}


const terms_product_step_1 = [
  { value: "all", label: "Ürünlerin Hepsi", description: "Sisteme Kayıtlı Tüm Ürünler Dahil Olur", },
  { value: "brand-categories", label: "Marka ve/veya Kategoriye Göre", description: "Seçilen Marka ve Kategorilere Ait Ürünler Dahil Olur" }, 
  { value: "custom", label: "Bazı Ürünler", description: "Sadece Seçilen Ürünler Dahil Olur",},
]

export { terms_step_1, terms_step_2, default_term_step_1_object, default_term_step_2_object, default_variant, terms_product_step_1}