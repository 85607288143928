import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY, 
  CATEGORIES_RESET,
  CATEGORIES_RESET_ERROR, 
  CATEGORIES_RESET_SUCCESS, 
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  CATEGORY_RESET,
  CATEGORY_LOADING, 
} from "./actionTypes"

export const categoryLoading = status => ({
  type: CATEGORY_LOADING,
  payload: { status },
})

//CATEGORIES
export const getCategoriesSuccess = (actionType, data, tableOptions) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getCategoriesFail = (actionType, error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: { actionType, error },
})

export const getCategories = () => ({
  type: GET_CATEGORIES,
  payload: { },
})

export const getCategorySuccess = (actionType, data) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const getCategoryFail = (actionType, error) => ({
  type: GET_CATEGORY_FAIL,
  payload: { actionType, error },
})

export const getCategory = id => ({
  type: GET_CATEGORY,
  payload: { id },
})

export const addCategorySuccess = (actionType, data) => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const addCategoryFail = (actionType, error) => ({
  type: ADD_CATEGORY_FAIL,
  payload: { actionType, error },
})

export const addCategory = (data, history) => ({
  type: ADD_CATEGORY,
  payload: { data, history },
})

export const updateCategorySuccess = (actionType, data) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const updateCategoryFail = (actionType, error) => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: { actionType, error },
})

export const updateCategory = (id, data, history) => ({
  type: UPDATE_CATEGORY,
  payload: { id, data, history },
})

export const deleteCategorySuccess = (actionType, data) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const deleteCategoryFail = (actionType, error) => ({
  type: DELETE_CATEGORY_FAIL,
  payload: { actionType, error },
})

export const deleteCategory = (id, history) => ({
  type: DELETE_CATEGORY,
  payload: { id, history },
})
    

export const clearCategory = () => ({
  type: CATEGORY_RESET,
})
export const categoriesResetAll = () => ({
  type: CATEGORIES_RESET,
})

export const categoriesResetError = () => ({
  type: CATEGORIES_RESET_ERROR,
})

export const categoriesResetSuccess = () => ({
  type: CATEGORIES_RESET_SUCCESS,
})
