import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Label } from "reactstrap"
import {
  img_check_box,
  img_check_box_on,
  img_check_box_on_passive,
  img_minus_on,
} from "./../../common/data/custom-icons"

import "./custom.scss"
const CustomCheckbox = props => {
  const [status, setStatus] = useState(props.status || "false")
  const getImg = status => {
    switch (status) {
      case "false":
        return img_check_box
      case "true":
        return img_check_box_on
      case "true-passive":
        return img_check_box_on_passive
      default:
        return img_minus_on
    }
  }
  return (
    <div
      className="custom-checkbox"
      onClick={() => {
        console.log('click')
        if (status != "true-passive") {
          let newStatus = status == "false" ? "true" : "false";
          setStatus(newStatus)
          props.onChange(newStatus);
        }
      }}
    >
      <img className="custom-checkbox-img" src={getImg(props.status || status)} />
      <Label className="custom-checkbox-label">{props.label}</Label>
    </div>
  )
}

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func
}

export default CustomCheckbox
