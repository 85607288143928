import {
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORIES_FAIL,
  GET_SUBCATEGORIES,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_FAIL,
  ADD_SUBCATEGORY,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAIL,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY, 
  SUBCATEGORIES_RESET,
  SUBCATEGORIES_RESET_ERROR, 
  SUBCATEGORIES_RESET_SUCCESS, 
  GET_SUBCATEGORY,
  GET_SUBCATEGORY_FAIL,
  GET_SUBCATEGORY_SUCCESS,
  SUBCATEGORY_RESET,
  SUBCATEGORY_LOADING, 
} from "./actionTypes"

export const subCategoryLoading = status => ({
  type: SUBCATEGORY_LOADING,
  payload: { status },
})

//SUBCATEGORIES
export const getSubCategoriesSuccess = (actionType, data, tableOptions) => ({
  type: GET_SUBCATEGORIES_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getSubCategoriesFail = (actionType, error) => ({
  type: GET_SUBCATEGORIES_FAIL,
  payload: { actionType, error },
})

export const getSubCategories = (id) => ({
  type: GET_SUBCATEGORIES,
  payload: { id },
})

export const getSubCategorySuccess = (actionType, data) => ({
  type: GET_SUBCATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const getSubCategoryFail = (actionType, error) => ({
  type: GET_SUBCATEGORY_FAIL,
  payload: { actionType, error },
})

export const getSubCategory = id => ({
  type: GET_SUBCATEGORY,
  payload: { id },
})

export const addSubCategorySuccess = (actionType, data) => ({
  type: ADD_SUBCATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const addSubCategoryFail = (actionType, error) => ({
  type: ADD_SUBCATEGORY_FAIL,
  payload: { actionType, error },
})

export const addSubCategory = (data, history) => ({
  type: ADD_SUBCATEGORY,
  payload: { data, history },
})

export const updateSubCategorySuccess = (actionType, data) => ({
  type: UPDATE_SUBCATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const updateSubCategoryFail = (actionType, error) => ({
  type: UPDATE_SUBCATEGORY_FAIL,
  payload: { actionType, error },
})

export const updateSubCategory = (id, data, history) => ({
  type: UPDATE_SUBCATEGORY,
  payload: { id, data, history },
})

export const deleteSubCategorySuccess = (actionType, data) => ({
  type: DELETE_SUBCATEGORY_SUCCESS,
  payload: { actionType, data },
})

export const deleteSubCategoryFail = (actionType, error) => ({
  type: DELETE_SUBCATEGORY_FAIL,
  payload: { actionType, error },
})

export const deleteSubCategory = (id,categoryId, history) => ({
  type: DELETE_SUBCATEGORY,
  payload: { id,categoryId, history },
})
    

export const clearSubCategory = () => ({
  type: SUBCATEGORY_RESET,
})
export const subCategoriesResetAll = () => ({
  type: SUBCATEGORIES_RESET,
})

export const subCategoriesResetError = () => ({
  type: SUBCATEGORIES_RESET_ERROR,
})

export const subCategoriesResetSuccess = () => ({
  type: SUBCATEGORIES_RESET_SUCCESS,
})
