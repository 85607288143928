import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_TOKEN_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  loginTokenSuccess,
  logoutUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postJwtLoginMulti,
  postJwtLoginMultiToken,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
      history.push("/v1/campaigns")
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      // const response = yield call(postJwtLogin, {
      //   email: user.email,
      //   password: user.password,
      // })
      //localStorage.setItem("authUser", JSON.stringify(response))

      const response = yield call(postJwtLoginMulti, {
        email: user.email,
        password: user.password,
      })
      if (response.status == true && response?.accounts?.length > 0) {
        localStorage.setItem("branches", JSON.stringify(response.accounts))
        history.push("/login-branch")
        yield put(loginSuccess(response))
      } else {
        yield put(apiError("Şube Bulunamadı"))
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // const response = yield call(postFakeLogin, {
      //   email: user.email,
      //   password: user.password,
      // });
      // localStorage.setItem("authUser", JSON.stringify(response));
      // yield put(loginSuccess(response));
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
      history.push("/v1/campaigns")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* loginUserToken({ payload: { user, history, goto } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLoginMultiToken, {
        uid: user.uid,
        bid: user.bid,
        cid: user.cid,
      })
      if (response.status == true && response.accessToken) {
        localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginTokenSuccess(response))
        if (goto) {
          if (document.location.pathname == goto) {
            location.reload()
          } else {
            history.push(goto)
          }
        } else {
          history.push("/v1/campaigns")
        }
      } else {
        yield put(apiError("Şube Bulunamadı"))
      }
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
    location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_TOKEN_USER, loginUserToken)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
