import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import Loading from "./Loading"
import './custom.scss'
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={6}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Arama İşlemi
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`Ara ...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

function DateFilter({ dateFilter, setDateFilter, handle }) {
  const [value, setValue] = React.useState(dateFilter)
  const onChange = useAsyncDebounce(value => {
    setDateFilter(value || undefined)
  }, 200)

  const addDay = count => {
    let isVal = moment(value).add(count, "days").toDate()
    console.log(isVal)
    setValue(isVal)
    handle(moment(isVal).format("DD-MM-YYYY"))
  }

  return (
    <React.Fragment>
      <Col md={2}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block d-flex">
          <a
            onClick={() => {
              addDay(-1)
            }}
            className="d-flex align-items-center me-2"
            style={{ marginTop: "-3px", fontSize: "16px" }}
          >
            {"<"}
          </a>
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Arama İşlemi
              </span>
              <DatePicker
                onChange={value => {
                  setValue(value)
                  handle(moment(value).format("DD-MM-YYYY"))
                }}
                id="search-bar-1"
                className="form-control"
                placeholderText={`GG-AA-YYYY`}
                selected={value}
                dateFormat="dd-MM-yyyy"
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
          <a
            onClick={() => {
              addDay(1)
            }}
            className="d-flex align-items-center ms-2"
            style={{ marginTop: "-3px", fontSize: "16px" }}
          >
            {">"}
          </a>
        </div>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  handleDateFilter,
  handleBranchId,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  isDateFilter,
  branches,
  loading,
  rowClick,
  handleRow,
  columnSearch = true,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setDateFilter,
    setBranchId,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        dateFilter: moment().toDate(),
        branchId: 1,
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInSelectBranch = event => {
    state.branchId = Number(event.target.value)
    handleBranchId(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  return (
    <Fragment>
      <Row className="mb-1">
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        <Col md={5}></Col>
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
            style={{textAlign:'end', width:'75%'}}
          >
            {[10, 50, 100, 250, 500].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {branches ? (
          <Col md={2}>
            <select
              className="form-select"
              value={state.branchId}
              onChange={onChangeInSelectBranch}
            >
              {branches.map((val, idx) => (
                <option key={idx} value={val.id}>
                  {val.name}
                </option>
              ))}
            </select>
          </Col>
        ) : null}
        {/**
         * dateFilter, setDateFilter, handle
         */}
        {isDateFilter && (
          <DateFilter
            dateFilter={state.dateFilter}
            setDateFilter={setDateFilter}
            handle={handleDateFilter}
          />
        )}

        {isAddOptions && (
          <Col sm="1">
            <div
              className="text-sm-end"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Yeni Kayit
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Loading status={loading} />
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div
                      className={column.search == false ? "mb-0" : "mb-2"}
                      {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {columnSearch == false || column.search == false ? null : (
                      <Filter column={column} />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    onClick={() => {
                      if (rowClick) {
                        handleRow(row)
                      }
                    }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center mt-2">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              className="btn-sm"
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              className="btn-sm"
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Sayfa{" "}
          <strong>
            {pageIndex + 1} - {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            className="form-control-sm"
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              className="btn-sm"
              color="primary"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              className="btn-sm"
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
