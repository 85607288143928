import {
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  GET_CAMPAIGNS,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN,
  CAMPAIGNS_RESET,
  CAMPAIGNS_RESET_ERROR,
  CAMPAIGNS_RESET_SUCCESS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_FAIL,
  GET_CAMPAIGN_SUCCESS,
  CAMPAIGN_RESET,
  CAMPAIGN_LOADING,
  TERMS_CAMPAIGN_SUCCESS,
  TERMS_CAMPAIGN,
  TERMS_CAMPAIGN_FAIL,
  TERM_CAMPAIGN,
  TERM_CAMPAIGN_FAIL,
  TERM_CAMPAIGN_SUCCESS,
  ADD_TERM_CAMPAIGN_SUCCESS,
  ADD_TERM_CAMPAIGN_FAIL,
  ADD_TERM_CAMPAIGN,
  UPDATE_TERM_CAMPAIGN,
  UPDATE_TERM_CAMPAIGN_FAIL,
  UPDATE_TERM_CAMPAIGN_SUCCESS,
  DELETE_TERM_CAMPAIGN_SUCCESS,
  DELETE_TERM_CAMPAIGN_FAIL,
  DELETE_TERM_CAMPAIGN,
  GET_BRANCHGROUPS_BY_CAMPAIGN,
  GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL,
  GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_STEP3_SUCCESS,
  UPDATE_CAMPAIGN_STEP3_FAIL,
  UPDATE_CAMPAIGN_STEP3,
  POST_CAMPAIGN_PRODUCT_TYPE,
  POST_CAMPAIGN_PRODUCT_TYPE_FAIL,
  POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS,
  POST_CAMPAIGN_STATUS,
  POST_CAMPAIGN_STATUS_FAIL,
  POST_CAMPAIGN_STATUS_SUCCESS,
  CAMPAIGN_SUCCESS,
  POST_CAMPAIGN_NEW_DATA,
  POST_CAMPAIGN_NEW_DATA_FAIL,
  POST_CAMPAIGN_NEW_DATA_SUCCESS,
  GET_CAMPAIGN_NEW_DATA,
  GET_CAMPAIGN_NEW_DATA_FAIL,
  GET_CAMPAIGN_NEW_DATA_SUCCESS,
  UPDATE_CAMPAIGN_NEW_DATA_STATUS,
  UPDATE_CAMPAIGN_NEW_DATA,
  UPDATE_CAMPAIGN_NEW_DATA_FAIL,
  UPDATE_CAMPAIGN_NEW_DATA_SUCCESS,
  DELETE_CAMPAIGN_NEW_DATA,
  DELETE_CAMPAIGN_NEW_DATA_FAIL,
  DELETE_CAMPAIGN_NEW_DATA_SUCCESS,
  UPDATE_CAMPAIGN_NEW_DATA_STATUS_FAIL,
  UPDATE_CAMPAIGN_NEW_DATA_STATUS_SUCCESS,
  GET_CAMPAIGN_NEW_DATA_LIST,
  GET_CAMPAIGN_NEW_DATA_LIST_SUCCESS,
  GET_CAMPAIGN_NEW_DATA_LIST_FAIL,
} from "./actionTypes"

export const campaignLoading = status => ({
  type: CAMPAIGN_LOADING,
  payload: { status },
})

//CAMPAIGNS
export const getCampaignsSuccess = (actionType, data, tableOptions) => ({
  type: GET_CAMPAIGNS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getCampaignsFail = (actionType, error) => ({
  type: GET_CAMPAIGNS_FAIL,
  payload: { actionType, error },
})

export const getCampaigns = () => ({
  type: GET_CAMPAIGNS,
  payload: {},
})

export const getCampaignSuccess = (actionType, data) => ({
  type: GET_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const getCampaignFail = (actionType, error) => ({
  type: GET_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const getCampaign = id => ({
  type: GET_CAMPAIGN,
  payload: { id },
})

export const addCampaignSuccess = (actionType, data) => ({
  type: ADD_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const addCampaignFail = (actionType, error) => ({
  type: ADD_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const addCampaign = (data, file, history) => ({
  type: ADD_CAMPAIGN,
  payload: { data, file, history },
})

export const updateCampaignSuccess = (actionType, data) => ({
  type: UPDATE_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const updateCampaignFail = (actionType, error) => ({
  type: UPDATE_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const updateCampaign = (id, data, file, history) => ({
  type: UPDATE_CAMPAIGN,
  payload: { id, data, file, history },
})

export const deleteCampaignSuccess = (actionType, data) => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const deleteCampaignFail = (actionType, error) => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const deleteCampaign = (id, history) => ({
  type: DELETE_CAMPAIGN,
  payload: { id, history },
})

export const termsCampaignSuccess = (actionType, data) => ({
  type: TERMS_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const termsCampaignFail = (actionType, error) => ({
  type: TERMS_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const termsCampaign = id => ({
  type: TERMS_CAMPAIGN,
  payload: { id },
})

export const termCampaignSuccess = (actionType, data) => ({
  type: TERM_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const termCampaignFail = (actionType, error) => ({
  type: TERM_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const termCampaign = (id, level) => ({
  type: TERM_CAMPAIGN,
  payload: { id, level },
})

export const addTermCampaignSuccess = (actionType, data) => ({
  type: ADD_TERM_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const addTermCampaignFail = (actionType, error) => ({
  type: ADD_TERM_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const addTermCampaign = (id, data, history) => ({
  type: ADD_TERM_CAMPAIGN,
  payload: { id, data, history },
})

export const updateTermCampaignSuccess = (actionType, data) => ({
  type: UPDATE_TERM_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const updateTermCampaignFail = (actionType, error) => ({
  type: UPDATE_TERM_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const updateTermCampaign = (id, level, data, history) => ({
  type: UPDATE_TERM_CAMPAIGN,
  payload: { id, level, data, history },
})

export const deleteTermCampaignSuccess = (actionType, data) => ({
  type: DELETE_TERM_CAMPAIGN_SUCCESS,
  payload: { actionType, data },
})

export const deleteTermCampaignFail = (actionType, error) => ({
  type: DELETE_TERM_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const deleteTermCampaign = (id, level, history) => ({
  type: DELETE_TERM_CAMPAIGN,
  payload: { id, level, history },
})

//--
//Step3

export const getBranchGroupByCampaign = id => ({
  type: GET_BRANCHGROUPS_BY_CAMPAIGN,
  payload: { id },
})

export const getBranchGroupByCampaignSuccess = (
  actionType,
  data,
  groupList,
  branchList
) => ({
  type: GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS,
  payload: { actionType, data, groupList, branchList },
})

export const getBranchGroupByCampaignFail = (actionType, error) => ({
  type: GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL,
  payload: { actionType, error },
})

export const updateBranchGroupByCampaignSuccess = (actionType, data) => ({
  type: UPDATE_CAMPAIGN_STEP3_SUCCESS,
  payload: { actionType, data },
})

export const updateBranchGroupByCampaignFail = (actionType, error) => ({
  type: UPDATE_CAMPAIGN_STEP3_FAIL,
  payload: { actionType, error },
})

export const updateBranchGroupByCampaign = (id, data, history) => ({
  type: UPDATE_CAMPAIGN_STEP3,
  payload: { id, data, history },
})

//step4 product type
export const postCampaignProductType = (id, data, history) => ({
  type: POST_CAMPAIGN_PRODUCT_TYPE,
  payload: { id, data, history },
})

export const postCampaignProductTypeFail = (actionType, error) => ({
  type: POST_CAMPAIGN_PRODUCT_TYPE_FAIL,
  payload: { actionType, error },
})

export const postCampaignProductTypeSuccess = (id, data, history) => ({
  type: POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS,
  payload: { id, data, history },
})

//status change

export const postCampaignStatus = (id, data, history) => ({
  type: POST_CAMPAIGN_STATUS,
  payload: { id, data, history },
})

export const postCampaignStatusFail = (actionType, error) => ({
  type: POST_CAMPAIGN_STATUS_FAIL,
  payload: { actionType, error },
})

export const postCampaignStatusSuccess = (id, data, history) => ({
  type: POST_CAMPAIGN_STATUS_SUCCESS,
  payload: { id, data, history },
})

//NEW DATA

export const getCampaignNewDataList = (id, data, history) => ({
  type: GET_CAMPAIGN_NEW_DATA_LIST,
  payload: { id, data, history },
})

export const getCampaignNewDataListFail = (actionType, error) => ({
  type: GET_CAMPAIGN_NEW_DATA_LIST_FAIL,
  payload: { actionType, error },
})

export const getCampaignNewDataListSuccess = (id, data, history) => ({
  type: GET_CAMPAIGN_NEW_DATA_LIST_SUCCESS,
  payload: { id, data, history },
})

export const getCampaignNewData = (id, campaignId, history) => ({
  type: GET_CAMPAIGN_NEW_DATA,
  payload: { id, campaignId, history },
})

export const getCampaignNewDataFail = (actionType, error) => ({
  type: GET_CAMPAIGN_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const getCampaignNewDataSuccess = (id, data, history) => ({
  type: GET_CAMPAIGN_NEW_DATA_SUCCESS,
  payload: { id, data, history },
})

export const postCampaignNewData = (id, data, history, dataId) => ({
  type: POST_CAMPAIGN_NEW_DATA,
  payload: { id, data, history, dataId },
})

export const postCampaignNewDataFail = (actionType, error) => ({
  type: POST_CAMPAIGN_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const postCampaignNewDataSuccess = (id, data, history) => ({
  type: POST_CAMPAIGN_NEW_DATA_SUCCESS,
  payload: { id, data, history },
})

export const updateCampaignNewData = (id, data, history) => ({
  type: UPDATE_CAMPAIGN_NEW_DATA,
  payload: { id, data, history },
})

export const updateCampaignNewDataFail = (actionType, error) => ({
  type: UPDATE_CAMPAIGN_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const updateCampaignNewDataSuccess = (id, data, history) => ({
  type: UPDATE_CAMPAIGN_NEW_DATA_SUCCESS,
  payload: { id, data, history },
})

export const deleteCampaignNewData = (id, campaignId,  history) => ({
  type: DELETE_CAMPAIGN_NEW_DATA,
  payload: { id, campaignId, history },
})

export const deleteCampaignNewDataFail = (actionType, error) => ({
  type: DELETE_CAMPAIGN_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const deleteCampaignNewDataSuccess = (actionType, data) => ({
  type: DELETE_CAMPAIGN_NEW_DATA_SUCCESS,
  payload: { actionType, data },
})

export const updateCampaignNewDataStatus = (id, data, history) => ({
  type: UPDATE_CAMPAIGN_NEW_DATA_STATUS,
  payload: { id, data, history },
})

export const updateCampaignNewDataStatusFail = (actionType, error) => ({
  type: UPDATE_CAMPAIGN_NEW_DATA_STATUS_FAIL,
  payload: { actionType, error },
})

export const updateCampaignNewDataStatusSuccess = (id, data, history) => ({
  type: UPDATE_CAMPAIGN_NEW_DATA_STATUS_SUCCESS,
  payload: { id, data, history },
})

//-----
export const campaignSuccess = data => ({
  type: CAMPAIGN_SUCCESS,
  payload: { data },
})

export const clearCampaign = () => ({
  type: CAMPAIGN_RESET,
})
export const campaignsResetAll = () => ({
  type: CAMPAIGNS_RESET,
})

export const campaignsResetError = () => ({
  type: CAMPAIGNS_RESET_ERROR,
})

export const campaignsResetSuccess = () => ({
  type: CAMPAIGNS_RESET_SUCCESS,
})
