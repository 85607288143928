import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getBranchesSuccess,
  getBranchesFail,
  getPersonsSuccess,
  getPersonsFail,
  getCompaniesSuccess,
  getCompaniesFail,
  getDistrictsSuccess,
  getDistrictsFail,
  getCitiesSuccess,
  getCitiesFail,
  addCompanySuccess,
  addCompanyFail,
  getCompanySuccess,
  getCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  companyLoading,
  getBranchFail,
  getBranchSuccess,
  addBranchSuccess,
  addBranchFail,
  updateBranchSuccess,
  updateBranchFail,
  deleteBranchSuccess,
  deleteBranchFail,
  getPersonSuccess,
  getPersonFail,
  addPersonSuccess,
  addPersonFail,
  updatePersonSuccess,
  updatePersonFail,
  deletePersonSuccess,
  deletePersonFail,
  getBranchGroupsSuccess,
  getBranchGroupsFail,
  addBranchGroupSuccess,
  addBranchGroupFail,
  updateBranchGroupSuccess,
  updateBranchGroupFail,
  deleteBranchGroupSuccess,
  deleteBranchGroupFail,
  getBranchGroupSuccess,
  getBranchGroupFail,
  clearStatusGroup, 
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCompanies,
  addCompany,
  updateCompany,
  deleteCompany,
  getBranches,
  getPersons,
  getCities,
  getDistricts,
  getCompany,
  getBranch,
  addBranch,
  updateBranch,
  deleteBranch,
  getPerson,
  addPerson,
  updatePerson,
  deletePerson,
  getBranchGroups,
  addBranchGroup,
  updateBranchGroup,
  deleteBranchGroup,
  getBranchGroup,
} from "../../helpers/fakebackend_helper"
import {
  GET_COMPANIES,
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_PERSONS,
  GET_PERSONS_FAIL,
  GET_PERSONS_SUCCESS,
  GET_CITIES,
  GET_DISTRICTS,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAIL,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY,
  ADD_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAIL,
  GET_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  ADD_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  GET_PERSON_SUCCESS,
  GET_PERSON_FAIL,
  ADD_PERSON_SUCCESS,
  ADD_PERSON_FAIL,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAIL,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAIL,
  GET_PERSON,
  ADD_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON,
  GET_BRANCHGROUPS_SUCCESS,
  GET_BRANCHGROUPS_FAIL,
  ADD_BRANCHGROUP_SUCCESS,
  ADD_BRANCHGROUP_FAIL,
  UPDATE_BRANCHGROUP_SUCCESS,
  UPDATE_BRANCHGROUP_FAIL,
  DELETE_BRANCHGROUP_SUCCESS,
  DELETE_BRANCHGROUP_FAIL,
  GET_BRANCHGROUP_SUCCESS,
  GET_BRANCHGROUP_FAIL,
  GET_BRANCHGROUPS,
  GET_BRANCHGROUP,
  ADD_BRANCHGROUP,
  UPDATE_BRANCHGROUP,
  DELETE_BRANCHGROUP,
} from "./actionTypes"
import { isEmpty } from "lodash"

//COMPANIES
function* getCompaniesFetch({ payload: { data } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getCompanies, data)
    if (response.status) {
      yield put(
        getCompaniesSuccess(GET_COMPANIES_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getCompaniesFail(
          GET_COMPANIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(getCompaniesFail(GET_COMPANIES_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* addCompanyFetch({ payload: { data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(addCompany, data)
    if (response.status) {
      yield put(addCompanySuccess(ADD_COMPANY_SUCCESS, response.data))
      history.push("/v1/companies")
    } else {
      yield put(
        addCompanyFail(
          ADD_COMPANY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }

    yield put(companyLoading(false))
  } catch (error) {
    yield put(addCompanyFail(ADD_COMPANY_FAIL, error))
    yield put(companyLoading(false))
  }
}
function* updateCompanyFetch({ payload: { id, data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(updateCompany, id, data)
    if (response.status) {
      yield put(updateCompanySuccess(UPDATE_COMPANY_SUCCESS, response.data))
      history.push("/v1/companies")
    } else {
      yield put(
        updateCompanyFail(
          UPDATE_COMPANY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(updateCompanyFail(UPDATE_COMPANY_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* deleteCompanyFetch({ payload: { id, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(deleteCompany, id)
    if (response.status) {
      yield put(deleteCompanySuccess(DELETE_COMPANY_SUCCESS, response.data))
      history.push("/v1/companies")
    } else {
      yield put(
        deleteCompanyFail(
          DELETE_COMPANY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(deleteCompanyFail(DELETE_COMPANY_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* getCompanyByIdFetch({ payload: { id } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getCompany, id)
    if (response.status) {
      yield put(getCompanySuccess(GET_COMPANY_SUCCESS, response.data))
    } else {
      yield put(
        getCompanyFail(
          GET_COMPANY_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(getCompanyFail(GET_COMPANY_FAIL, error))
    yield put(companyLoading(false))
  }
}

//BRANCHES
function* getBranchesFetch({ payload: { id, data } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getBranches, id, data)
    if (response.status) {
      yield put(
        getBranchesSuccess(GET_BRANCHES_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getBranchesFail(
          GET_BRANCHES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(getBranchesFail(GET_BRANCHES_FAIL, error))
    yield put(companyLoading(false))
  }
}
function* getBranchByIdFetch({ payload: { id } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getBranch, id)
    if (response.status) {
      yield put(getBranchSuccess(GET_BRANCH_SUCCESS, response.data))
    } else {
      console.error(response)
      yield put(
        getBranchFail(
          GET_BRANCH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    console.error(error)
    yield put(getBranchFail(GET_BRANCH_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* addBranchFetch({ payload: { cid, data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(addBranch, cid, data)
    if (response.status) {
      yield put(addBranchSuccess(ADD_BRANCH_SUCCESS, response.data))
      history.push(`/v1/companies/branches/${cid}`)
    } else {
      yield put(
        addBranchFail(
          ADD_BRANCH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }

    yield put(companyLoading(false))
  } catch (error) {
    yield put(addBranchFail(ADD_BRANCH_FAIL, error))
    yield put(companyLoading(false))
  }
}
function* updateBranchFetch({ payload: { id, cid, data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(updateBranch, id, cid, data)
    if (response.status) {
      yield put(updateBranchSuccess(UPDATE_BRANCH_SUCCESS, response.data))
      history.push(`/v1/companies/branches/${cid}`)
    } else {
      yield put(
        updateBranchFail(
          UPDATE_BRANCH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(updateBranchFail(UPDATE_BRANCH_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* deleteBranchFetch({ payload: { id, cid, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(deleteBranch, id, cid)
    if (response.status) {
      yield put(deleteBranchSuccess(DELETE_BRANCH_SUCCESS, response.data))
      history.push(`/v1/companies/branches/${cid}`)
    } else {
      yield put(
        deleteBranchFail(
          DELETE_BRANCH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(deleteBranchFail(DELETE_BRANCH_FAIL, error))
    yield put(companyLoading(false))
  }
}

//PERSONS
function* getPersonsFetch({ payload: { bid, cid, data } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getPersons, bid, cid, data)
    if (response.status) {
      yield put(getPersonsSuccess(GET_PERSONS_SUCCESS, response.data, response))
    } else {
      yield put(
        getPersonsFail(
          GET_PERSONS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(getPersonsFail(GET_PERSONS_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* getPersonByIdFetch({ payload: { id } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getPerson, id)
    if (response.status) {
      yield put(getPersonSuccess(GET_PERSON_SUCCESS, response.data))
    } else {
      console.error(response)
      yield put(
        getPersonFail(
          GET_PERSON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    console.error(error)
    yield put(getPersonFail(GET_PERSON_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* addPersonFetch({ payload: { cid, bid, data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(addPerson, data)
    if (response.status) {
      yield put(addPersonSuccess(ADD_PERSON_SUCCESS, response.data))
      history.push(`/v1/companies/persons?cid=${cid}&bid=${bid}`)
    } else {
      yield put(
        addPersonFail(
          ADD_PERSON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }

    yield put(companyLoading(false))
  } catch (error) {
    yield put(addPersonFail(ADD_PERSON_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* updatePersonFetch({ payload: { id, cid, bid, data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(updatePerson, id, data)
    if (response.status) {
      yield put(updatePersonSuccess(UPDATE_PERSON_SUCCESS, response.data))
      history.push(`/v1/companies/persons?cid=${cid}&bid=${bid}`)
    } else {
      yield put(
        updatePersonFail(
          UPDATE_PERSON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(updatePersonFail(UPDATE_PERSON_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* deletePersonFetch({ payload: { id, cid, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(deletePerson, id, cid)
    if (response.status) {
      yield put(deletePersonSuccess(DELETE_PERSON_SUCCESS, response.data))
      history.push(`/v1/companies/persons/${cid}`)
    } else {
      yield put(
        deletePersonFail(
          DELETE_PERSON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(deletePersonFail(DELETE_PERSON_FAIL, error))
    yield put(companyLoading(false))
  }
}

//CITIES
function* getCitiesFetch({ payload: {} }) {
  try {
    var response = yield call(getCities)
    if (response.status) {
      yield put(getCitiesSuccess(GET_CITIES_SUCCESS, response.data))
    } else {
      yield put(
        getCitiesFail(
          GET_COMPANIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getCitiesFail(GET_CITIES_FAIL, error))
  }
}
function* getDistrictsFetch({ payload: { id } }) {
  try {
    var response = yield call(getDistricts, id)
    if (response.status) {
      yield put(getDistrictsSuccess(GET_DISTRICTS_SUCCESS, response.data))
    } else {
      yield put(
        getDistrictsFail(
          GET_COMPANIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getDistrictsFail(GET_DISTRICTS_FAIL, error))
  }
}



function* getBranchGroupsFetch({ payload: { data } }) {
  try {
    yield put(clearStatusGroup(true))
    yield put(companyLoading(true))
    var response = yield call(getBranchGroups, data)
    if (response.status) {
      yield put(
        getBranchGroupsSuccess(GET_BRANCHGROUPS_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getBranchGroupsFail(
          GET_BRANCHGROUPS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANCHGROUPS-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(getBranchGroupsFail(GET_BRANCHGROUPS_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* addBranchGroupFetch({ payload: { data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(addBranchGroup, data)
    if (response.status) {
      yield put(addBranchGroupSuccess(ADD_BRANCHGROUP_SUCCESS, response.data))
      history.push("/settings/branch-groups")
    } else {
      yield put(
        addBranchGroupFail(
          ADD_BRANCHGROUP_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANCHGROUPS-1001"
        )
      )
    }

    yield put(companyLoading(false))
  } catch (error) {
    yield put(addBranchGroupFail(ADD_BRANCHGROUP_FAIL, error))
    yield put(companyLoading(false))
  }
}
function* updateBranchGroupFetch({ payload: { id, data, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(updateBranchGroup, id, data)
    if (response.status) {
      yield put(updateBranchGroupSuccess(UPDATE_BRANCHGROUP_SUCCESS, response.data)) 
      history.push("/settings/branch-groups")
    } else { 
      yield put(
        updateBranchGroupFail(
          UPDATE_BRANCHGROUP_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANCHGROUPS-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) { 
    yield put(updateBranchGroupFail(UPDATE_BRANCHGROUP_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* deleteBranchGroupFetch({ payload: { id, history } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(deleteBranchGroup, id)
    if (response.status) {
      yield put(deleteBranchGroupSuccess(DELETE_BRANCHGROUP_SUCCESS, response.data))
      if(!isEmpty(history)){
        history.push("/settings/branch-groups")
      } 
    } else {
      yield put(
        deleteBranchGroupFail(
          DELETE_BRANCHGROUP_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANCHGROUPS-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(deleteBranchGroupFail(DELETE_BRANCHGROUP_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* getBranchGroupByIdFetch({ payload: { id } }) {
  try {
    yield put(companyLoading(true))
    var response = yield call(getBranchGroup, id)
    if (response.status) { 
      yield put(getBranchGroupSuccess(GET_BRANCHGROUP_SUCCESS, response.data, response.companyList, response.branchList))
    } else {
      yield put(
        getBranchGroupFail(
          GET_BRANCHGROUP_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-BRANCHGROUPS-1001"
        )
      )
    }
    yield put(companyLoading(false))
  } catch (error) {
    yield put(getBranchGroupFail(GET_BRANCHGROUP_FAIL, error))
    yield put(companyLoading(false))
  }
}

function* companiesSaga() {
  yield takeEvery(GET_COMPANIES, getCompaniesFetch)
  yield takeEvery(GET_COMPANY, getCompanyByIdFetch)
  yield takeEvery(ADD_COMPANY, addCompanyFetch)
  yield takeEvery(UPDATE_COMPANY, updateCompanyFetch)
  yield takeEvery(DELETE_COMPANY, deleteCompanyFetch)
  yield takeEvery(GET_BRANCHES, getBranchesFetch)
  yield takeEvery(GET_BRANCH, getBranchByIdFetch)
  yield takeEvery(ADD_BRANCH, addBranchFetch)
  yield takeEvery(UPDATE_BRANCH, updateBranchFetch)
  yield takeEvery(DELETE_BRANCH, deleteBranchFetch)
  yield takeEvery(GET_CITIES, getCitiesFetch)
  yield takeEvery(GET_DISTRICTS, getDistrictsFetch)
  yield takeEvery(GET_PERSONS, getPersonsFetch)
  yield takeEvery(GET_PERSON, getPersonByIdFetch)
  yield takeEvery(ADD_PERSON, addPersonFetch)
  yield takeEvery(UPDATE_PERSON, updatePersonFetch)
  yield takeEvery(DELETE_PERSON, deletePersonFetch)
  
  yield takeEvery(GET_BRANCHGROUPS, getBranchGroupsFetch)
  yield takeEvery(GET_BRANCHGROUP, getBranchGroupByIdFetch)
  yield takeEvery(ADD_BRANCHGROUP, addBranchGroupFetch)
  yield takeEvery(UPDATE_BRANCHGROUP, updateBranchGroupFetch)
  yield takeEvery(DELETE_BRANCHGROUP, deleteBranchGroupFetch)
}

export default companiesSaga
