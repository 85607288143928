import { 
  CATEGORIES_RESET_ERROR,
  CATEGORIES_RESET, 
  CATEGORIES_RESET_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL, 
  ADD_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  CATEGORY_RESET,
  CATEGORY_LOADING, 
} from "./actionTypes"

const INIT_STATE = {
  data: [], 
  category: {}, 
  error: null,
  success: null,
  loading: true,
}

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload?.data, 
      }
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload?.data,
      }
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }  
    case GET_CATEGORIES_FAIL: 
    case GET_CATEGORY_FAIL:
    case ADD_CATEGORY_FAIL:
    case UPDATE_CATEGORY_FAIL:
    case DELETE_CATEGORY_FAIL: 
      return {
        ...state,
        error: action.payload,
      }
    case CATEGORIES_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case CATEGORIES_RESET_ERROR:
      return {
        ...state,
        error: null,
      } 
    case CATEGORY_RESET:
      return {
        ...state,
        category: {},
        branch: {},
      }
    case CATEGORIES_RESET:
      return {
        ...state,
        data: [],
        category: null, 
        error: null,
        success: null, 
      }
    case CATEGORY_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default Categories
