import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb2"

import avatar from "../../assets/images/users/boy_1.png"
// actions
import {
  editProfile,
  resetProfileFlag,
  loginUserToken,
} from "../../store/actions"

import Loading from "components/Common/Loading"
import Swal from "sweetalert2"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"

const UserProfile = props => {
  //meta title
  document.title = "Profile | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [branch, setbranch] = useState("")
  const [idx, setidx] = useState(1)
  const [puank, setPuank] = useState(null)

  const [branchList, setBranchList] = useState(
    localStorage.getItem("branches")
      ? JSON.parse(localStorage.getItem("branches"))
      : []
  )

  const [selectedBranch, setSelectedBranch] = useState()

  const { error, success, loading } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    loading: state.Login.loading,
  }))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      onGetPuank(obj.uid)
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.userName)
        setemail(obj.email)
        setidx(obj.uid)
        let branches = localStorage.getItem("branches")
          ? JSON.parse(localStorage.getItem("branches"))
          : []
        if (branches.length > 0) {
          let selectedBranch = branches.find(
            x =>
              x.Branch == obj.bid && x.Company == obj.cid && x.User == obj.uid
          )
          if (selectedBranch) {
            setbranch(selectedBranch.title)
            setSelectedBranch(
              selectedBranch.Branch +
                "-" +
                selectedBranch.Company +
                "-" +
                selectedBranch.User
            )
          }
        }
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      email: email || "",
      idx: idx || "",
      branch: branch || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      // dispatch(editProfile(values));
      console.log(selectedBranch)
      let temp = [...branchList]
      let selectedArr = selectedBranch.split("-")
      if (selectedArr.length == 3) {
        let branchId = selectedArr[0]
        let companyId = selectedArr[1]
        let userId = selectedArr[2]
        let selectedItem = temp.find(
          x =>
            x.Branch == branchId.toString() &&
            x.Company == companyId.toString() &&
            x.User == userId.toString()
        )
        if (selectedItem) {
          console.log(selectedItem)
          dispatch(
            loginUserToken(
              {
                uid: selectedItem.User,
                cid: selectedItem.Company,
                bid: selectedItem.Branch,
              },
              props.history,
              "/profile"
            )
          )
        } else {
          Swal.fire(
            "Hata",
            "Seçim Tespit Edilemedi: " + JSON.stringify(selectedItem),
            "error"
          )
        }

        //dispatch(loginUser(values, props.history))
      } else {
        Swal.fire("Hata", JSON.stringify(values), "error")
      }
    },
  })

  const breadcrumbItems = [
    { title: props.t("APPNAME"), link: "/" },
    { title: props.t("Profile"), link: "/profile" },
  ]

  const onGetPuank = async uid => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/puank/get-total-user/${uid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true && uid == response.data?.data?.User) {
          let p = response.data.data
          setPuank(p.Total)
        } else {
          Swal.fire("Hata", response.data.message, "error")
        }
      })
      .catch(error => {
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("APPNAME")}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-1">Id no: #{idx}</p>
                        <p className="mb-0">
                          {props.t("Branch")}: {branch}
                        </p>
                      </div>
                    </div>
                    <div className="align-self-start">
                      <div className="text-muted">
                        <a href="/v1/profile/puank-list-user-history">{puank!=null ? <h3>PUANK: {puank}</h3> : null}</a>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{props.t("Profile")}</h4>

          <Card>
            <CardBody>
              <Loading status={loading} />
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">{props.t("User Name")}</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                    disabled={true}
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="form-group mt-2">
                  <Label className="form-label">{props.t("Email")}</Label>
                  <Input
                    name="email"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Email"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                    disabled={true}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>

                <FormGroup className="mt-2 mb-3 relative">
                  <Label htmlFor="validationBranch">{props.t("Branch")}</Label>
                  <select
                    id="validationBranch"
                    className={"form-select"}
                    name="branch"
                    onChange={e => {
                      setSelectedBranch(e.target.value)
                    }}
                    value={selectedBranch}
                  >
                    <option value="">{props.t("Select")}</option>
                    {branchList.map((item, idx) => (
                      <option
                        key={idx}
                        value={
                          item.Branch + "-" + item.Company + "-" + item.User
                        }
                      >
                        {item.title}
                      </option>
                    ))}
                  </select>
                </FormGroup>

                {branchList.length > 1 ? (
                  <div className="mt-4">
                    <Button type="submit" color="success">
                      {props.t("Change Branch")}
                    </Button>
                  </div>
                ) : null}
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
UserProfile.propTypes = {
  t: PropTypes.any,
}
//export default withRouter(UserProfile);

export default withTranslation()(UserProfile)
