import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { products } from "./../../../common/data/product"
import {
  getVariants_v2 as onGet,
  productsResetError as onClearError,
  productsResetSuccess as onSuccessReset,
  importXLSXProducts as onImportXLSX,
  deleteVariant as onDeleteVariant,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./../custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import TableContainer from "../../../../components/Common/TableContainer"
import Loading from "components/Common/Loading"
import { isEmpty } from "lodash"
import Swal from "sweetalert2"
import TableContainerServerSide from "components/Common/TableContainerServerSide"

const VariantsV2 = props => {
  //meta title
  document.title = props.t("Products")

  const breadcrumbItems = [
    { title: props.t("Products"), link: "/v1/products" },
    { title: props.t("Variant List"), link: "#" },
  ]

  const [selectedItem, setSelectedItem] = useState("all")

  // const [data, setData] = useState(products)

  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 100,
    page: 0,
    select: "code",
    search: "",
  })

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, loading, importData, variants, variantsTableOptions } =
    useSelector(state => ({
      error: state.Products?.error,
      loading: state.Products?.loading || false,
      importData: state.Products?.importData,
      variants: state.Products?.variants,
      variantsTableOptions: state.Products?.variantsTableOptions,
    }))

  useEffect(() => {
    if (isEmpty(variants)) {
      dispatch(onGet(params.id, tableSetting))
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(importData)) {
      let errorHTML = ""
      if (importData?.successCount > 0) {
        errorHTML = `${importData?.successCount} Adet Ürün Kaydedildi <br />`
      }
      errorHTML += `<ul class="nodot">`
      for (let err of importData?.failed) {
        errorHTML += `<li>${err.product["Barkod"]} - ${err.product["Seri No"]} - ${err.product["Ürün Adı"]}  <b>Hata: ${err.error}</b></li>`
      }
      errorHTML += `</ul>`
      Swal.fire(
        importData?.failed?.length > 0 ? props.t("Error") : props.t("Success"),
        errorHTML,
        importData?.failed?.length > 0 ? "error" : "success"
      ).then(result => {
        if (result.isConfirmed) {
          dispatch(onSuccessReset())
        }
      })
    }
  }, [importData])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      ).then((isConfirmed)=>{
        if(isConfirmed){
          location.reload();
        }
      })
      dispatch(onClearError())
    }
  }, [error])

  const columns = useMemo(
    () => [ 
      {
        Header: props.t("Variant"), 
        accessor: "Variant.name",
        search: false,
        Cell:({value})=>(<div>{value}</div>)
      },
      {
        Header: props.t("Barcode"),
        accessor: "Variant",
        search: false,
        Cell:({value})=>(<div>{value?.barcode}</div>)
      }, 
      {
        Header: props.t("SerialNumber"),
        accessor: "code",
        search: false,
      },
      {
        Header: props.t("Actions"),
        accessor: "_id",
        search: false,
        Cell: ({ row, value }) => (
          <div>
            <a
              className="btn btn-sm btn-warning text-white me-2"
              href={`/v1/products/variants/series/${row?.values?.Variant?._id}?pid=${params.id}`}
            >
              {props.t("Series No List")}
            </a>
            <a
              className="btn btn-sm btn-warning text-white me-2"
              href={`/v1/products/variants/edit/${row?.values?.Variant?._id}?pid=${params.id}`}
            >
              {props.t("Edit")}
            </a>
            <Button
              className="btn-sm btn-danger text-white"
              onClick={() => { 
                Swal.fire({
                  title: props.t("Are You Sure"),
                  text: props.t("DeleteWarning"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: props.t("Yes, delete it!"),
                  cancelButtonText: props.t("Cancel"),
                }).then(result => {
                  if (result.isConfirmed) {
                    dispatch(onDeleteVariant(row?.values?.Variant?._id, null))
                  }
                })
              }}
            >
              {props.t("Delete")}
            </Button>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItems={breadcrumbItems}
            isAdd={true}
            title={props.t("Serial No List")}
            backLink={`/v1/products`}
            isImportExcel={false}
            isDownloadLink={false}
            addTitle={props.t("Create Product")}
            addHandle={() => {
              props.history.push(`/v1/products/variants/create/${params.id}?&pid=${query.get('pid')}`)
            }}
            className={"mb-0 pb-0"}
          />
          <Loading status={loading} />
          {/* <TableContainer
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          /> */}
          <TableContainerServerSide
            loading={loading}
            columns={columns}
            data={variants}
            isGlobalFilter={true}
            isAddOptions={false}
            tableSetting={tableSetting}
            customPageSize={tableSetting.limit}
            className="custom-header-css"
            tableOptions={variantsTableOptions}
            action={(pageIndex, pageSize, search) => {
              setTableSetting({
                orderBy: "createdAt",
                ascDesc: -1,
                limit: pageSize,
                page: pageIndex,
                select: tableSetting.select,
                search: search,
              })
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

VariantsV2.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(VariantsV2)
