export const GET_CLASSNAMES = "GET_CLASSNAMES"
export const GET_CLASSNAMES_SUCCESS = "GET_CLASSNAMES_SUCCESS"
export const GET_CLASSNAMES_FAIL = "GET_CLASSNAMES_FAIL"

export const GET_CLASSNAME = "GET_CLASSNAME"
export const GET_CLASSNAME_SUCCESS = "GET_CLASSNAME_SUCCESS"
export const GET_CLASSNAME_FAIL = "GET_CLASSNAME_FAIL"

export const ADD_CLASSNAME = "ADD_CLASSNAME"
export const ADD_CLASSNAME_SUCCESS = "ADD_CLASSNAME_SUCCESS"
export const ADD_CLASSNAME_FAIL = "ADD_CLASSNAME_FAIL"

export const UPDATE_CLASSNAME = "UPDATE_CLASSNAME"
export const UPDATE_CLASSNAME_SUCCESS = "UPDATE_CLASSNAME_SUCCESS"
export const UPDATE_CLASSNAME_FAIL = "UPDATE_CLASSNAME_FAIL"

export const DELETE_CLASSNAME = "DELETE_CLASSNAME"
export const DELETE_CLASSNAME_SUCCESS = "DELETE_CLASSNAME_SUCCESS"
export const DELETE_CLASSNAME_FAIL = "DELETE_CLASSNAME_FAIL"
 
export const CLASSNAME_RESET = "CLASSNAME_RESET"
export const CLASSNAMES_RESET_ERROR = "CLASSNAMES_RESET_ERROR"
export const CLASSNAMES_RESET = "CLASSNAMES_RESET"
export const CLASSNAMES_RESET_SUCCESS = "CLASSNAMES_RESET_SUCCESS"


export const CLASSNAME_LOADING = "CLASSNAME_LOADING"
