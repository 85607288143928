import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { products } from "./../../../common/data/product"
import {
  getCampaign as onGetCampaign,
  getProductsAll as onGetAll,
  getBrands as onBrandsAll,
  getCategories as onCategoriesAll,
  postCampaignProductType as onSave,
  productsResetError as onClearError,
  productsResetSuccess as onSuccessReset,
  importXLSXProducts as onImportXLSX,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import Loading from "components/Common/Loading"
import { isEmpty } from "lodash"
import Swal from "sweetalert2"
import TableContainerServerSide from "components/Common/TableContainerServerSide"
import { terms_product_step_1 } from "common/data/_terms"

import * as Yup from "yup"
import { useFormik } from "formik"
import IndeterminateCheckbox from "components/Common/IndeterminateCheckbox"
import TableCheckContainer from "components/Common/TableCheckContainer"

import Select from "react-select"
// import makeAnimated from "react-select/animated"

// const animatedComponents = makeAnimated()

const CampaignProductsStep4 = props => {
  //meta title
  document.title = props.t("Campaign Select Products")

  const [selectedFlatRows, setSelectedFlatRows] = useState([])
  const [selectedMulti, setselectedMulti] = useState(null)

  const [categoryList, setCategoryList] = useState([])
  const [brandList, setBrandList] = useState([])
  const [productCountFilter, setProductCountFilter] = useState(0)
  const [productCountFilterBrand, setProductCountFilterBrand] = useState(0)
  const [productCountFilterCategory, setProductCountFilterCategory] =
    useState(0)

  const [productList, setProductList] = useState()
  const [selectedList, setSelectedList] = useState([])
  const [campaignData, setCampaignData] = useState()
  const [getDataStatus, setGetDataStatus] = useState(false)

  // const [data, setData] = useState(products)

  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 5,
    page: 0,
    select: "name",
    search: "",
  })

  const dispatch = useDispatch()
  const {
    error,
    loading,
    importData,
    products,
    tableOptions,
    brands,
    categories,
    campaign,
  } = useSelector(state => ({
    error: state.Products?.error,
    loading: (state.Campaigns?.loading || state.Products?.loading) || false,
    importData: state.Products?.importData,
    products: state.Products?.data,
    tableOptions: state.Products?.tableOptions,
    brands: state.Brands?.data,
    categories: state.Categories?.data,
    campaign: state.Campaigns?.campaign,
  }))

  const {
    match: { params },
  } = props

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Campaign"), link: `/v1/campaigns/${params.id}` },
    { title: props.t("Select Product"), link: `#` },
  ]

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      step_1: "all",
      Categories: [],
      Brands: [],
      Products: [],
    },
    validationSchema: Yup.object({
      // brand: Yup.string().required(props.t("Required")),
      // name: Yup.string().required(props.t("Required")),
      // publishDate: Yup.string().required(props.t("Required")),
      // startDate: Yup.string().required(props.t("Required")),
      // finishDate: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      let newValues = {
        searchType: values.step_1,
        Categories: values.Categories.map(x => x.value),
        Brands: values.Brands.map(x => x.value),
        Products: selectedFlatRows.map(x => x._id),
      }
      console.log("values", selectedFlatRows, newValues)
      dispatch(onSave(params.id, newValues, props.history))
    },
  })

  useEffect(() => {
    if (
      !isEmpty(params.id) &&
      isEmpty(campaignData) &&
      isEmpty(campaign) &&
      getDataStatus === false
    ) {
      dispatch(onGetCampaign(params.id))
    } else if (!isEmpty(params.id) && isEmpty(campaignData)) {
      setCampaignData(campaign)
      validation.setFieldValue(
        "step_1",
        campaign.ProductSelection?.searchType || "all"
      )
      setSelectedList(campaign.ProductSelection?.Products || [])
      setGetDataStatus(true)
    }
  }, [campaign])

  useEffect(() => {
    if (isEmpty(products) && getDataStatus === false) {
      dispatch(onGetAll())
    } else if (
      !isEmpty(campaignData) &&
      !isEmpty(products) &&
      getDataStatus === true
    ) {
      let newList = []
      for (let p of products) {
        if (selectedList.indexOf(p._id) > -1) {
          //isSelectedx yerine isSelected gelirse Checkbox kilitlenir
          newList.push({ ...p, isSelectedx: true })
        } else {
          newList.push({ ...p })
        }
      }
      setProductList(newList)
    }
  }, [products, getDataStatus])

  useEffect(() => {
    if (isEmpty(categories) && getDataStatus === false) {
      dispatch(onCategoriesAll())
    } else if (
      getDataStatus === true &&
      !isEmpty(products) &&
      !isEmpty(campaign)
    ) {
      let temp = [...categories]
      let list = []
      for (let c of temp) {
        list.push({ label: c.name, value: c._id })
      }
      setCategoryList(list)
      if (!isEmpty(campaign?.ProductSelection?.Categories)) {
        let newList = []
        for (let id of campaign?.ProductSelection?.Categories) {
          let tempDataIND = list.findIndex(x => x.value == id)
          if (tempDataIND > -1) {
            newList.push(list[tempDataIND])
          }
        }
        validation.setFieldValue("Categories", newList || [])

        //count set
        let tempList = [...products]
        setProductCountFilter(
          tempList?.filter(
            p =>
              newList?.findIndex(x => x.value == p.Category?._id) > -1 ||
              validation.values.Brands?.findIndex(
                x => x.value == p.Brand?._id
              ) > -1
          ).length
        )
        setProductCountFilterCategory(
          tempList?.filter(
            p => newList?.findIndex(x => x.value == p.Category?._id) > -1
          ).length
        )
      }
    }
  }, [categories, campaign, getDataStatus, getDataStatus])

  useEffect(() => {
    if (isEmpty(brands) && getDataStatus === false) {
      dispatch(onBrandsAll())
    } else if (
      getDataStatus === true &&
      !isEmpty(products) &&
      !isEmpty(campaign)
    ) {
      let temp = [...brands]
      let list = []
      for (let c of temp) {
        list.push({ label: c.name, value: c._id })
      }
      setBrandList(list)
      if (!isEmpty(campaign?.ProductSelection?.Brands)) {
        let newList = []
        for (let id of campaign?.ProductSelection?.Brands) {
          let tempDataIND = list.findIndex(x => x.value == id)
          if (tempDataIND > -1) {
            newList.push(list[tempDataIND])
          }
        }
        validation.setFieldValue("Brands", newList || [])

        //count set
        let tempList = [...products]
        setProductCountFilter(
          tempList?.filter(
            p =>
              newList?.findIndex(x => x.value == p.Brand?._id) > -1 ||
              validation.values.Categories?.findIndex(
                x => x.value == p.Category?._id
              ) > -1
          ).length
        )
        setProductCountFilterBrand(
          tempList?.filter(
            p => newList?.findIndex(x => x.value == p.Brand?._id) > -1
          ).length
        )
      }
    }
  }, [brands, products, campaign, getDataStatus])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      ).then(isConfirmed => {
        if (isConfirmed) {
          location.reload()
        }
      })
      // dispatch(onClearError())
    }
  }, [error])

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  // const columns = useMemo(
  //   () => [
  //     {
  //       id: "select",
  //       header: ({ table }) => (
  //         <IndeterminateCheckbox
  //           {...{
  //             name: "all-check",
  //             ref: React.createRef(),
  //             checked: table.getIsAllRowsSelected(),
  //             indeterminate: table.getIsSomeRowsSelected(),
  //             onChange: table.getToggleAllRowsSelectedHandler(),
  //           }}
  //         />
  //       ),
  //       cell: ({ row }) => (
  //         <div className="px-1">
  //           <IndeterminateCheckbox
  //             {...{
  //               name: row.id,
  //               ref: React.createRef(),
  //               checked: row.getIsSelected(),
  //               disabled: !row.getCanSelect(),
  //               indeterminate: row.getIsSomeSelected(),
  //               onChange: row.getToggleSelectedHandler(),
  //             }}
  //           />
  //         </div>
  //       ),
  //     },
  //     {
  //       header: props.t("Product Name"),
  //       accessorKey: "name",
  //       search: false,
  //     },
  //     {
  //       header: props.t("Brand"),
  //       accessorKey: "Brand",
  //       search: false,
  //       cell: ({ row }) => <span>{row?.original?.Brand?.name}</span>,
  //     },
  //     {
  //       header: props.t("ClassName"),
  //       accessorKey: "ClassName",
  //       search: false,
  //       cell: ({ row }) => <span>{row?.original?.ClassName?.name}</span>,
  //     },
  //     {
  //       header: props.t("Category"),
  //       accessorKey: "Category",
  //       search: false,
  //       cell: ({ value }) => <span>{value?.name}</span>,
  //     },
  //     {
  //       header: props.t("SubCategory"),
  //       accessorKey: "SubCategory",
  //       search: false,
  //       cell: ({ value }) => <span>{value?.name}</span>,
  //     },
  //     {
  //       header: "",
  //       accessorKey: "_id",
  //       search: false,
  //       cell: ({ value }) => (
  //         <div>
  //           <a
  //             className="btn btn-sm btn-warning text-white me-2"
  //             href={`/v1/products/edit/${value}`}
  //           >
  //             {props.t("Edit")}
  //           </a>
  //           <a
  //             className="btn btn-sm btn-warning text-white"
  //             href={`/v1/products/variants/${value}`}
  //           >
  //             {props.t("Variant List")}
  //           </a>
  //         </div>
  //       ),
  //     },
  //   ],
  //   []
  // )

  const columns = useMemo(
    () => [
      {
        id: "selector",
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllPageRowsSelectedProps()}
              onClick={e => {
                let status = e.target.checked
                if (getToggleAllPageRowsSelectedProps().indeterminate == true) {
                  status = true
                } else if (
                  status == true &&
                  getToggleAllPageRowsSelectedProps().indeterminate == false
                ) {
                  status = false
                } else if (
                  status == false &&
                  getToggleAllPageRowsSelectedProps().indeterminate == false
                ) {
                  status = true
                }
                getToggleAllPageRowsSelectedProps().indeterminate = status
                getToggleAllPageRowsSelectedProps().checked = status
                getToggleAllPageRowsSelectedProps().onChange({
                  target: { checked: status },
                })
              }}
            />
            {/* <CustomCheckbox
              onChange={status => {
                getToggleAllPageRowsSelectedProps().indeterminate =
                  status == "minus" ? true : false
                getToggleAllPageRowsSelectedProps().checked =
                  status == "true" ? true : false
                getToggleAllPageRowsSelectedProps().onChange({
                  target: { checked: status == "true" ? true : false },
                })
              }}
              status={
                getToggleAllPageRowsSelectedProps().indeterminate == true
                  ? "true-passive"
                  : null
              }
              {...getToggleAllPageRowsSelectedProps()}
            /> */}
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              onClick={e => {
                let status = !e.target.checked
                row.isSelected = status
                row.isSomeSelected = status
                row.getToggleRowSelectedProps().onChange({
                  target: { checked: status },
                })
              }}
            />
            {/* <CustomCheckbox
              onChange={status => {
                row.isSelected = status == "true" ? true : false
                row.isSomeSelected = status == "true" ? true : false
                row.getToggleRowSelectedProps().onChange({
                  target: { checked: status == "true" ? true : false },
                })
              }}
            /> */}
          </div>
        ),
      },
      {
        Header: props.t("Product Name"),
        accessor: "name",
        search: false,
      },
      {
        Header: props.t("Brand"),
        accessor: "Brand.name",
        search: false,
        Cell: ({ value }) => <span>{value || "--"}</span>,
      },
      {
        Header: props.t("ClassName"),
        accessor: "ClassName.name",
        search: false,
        Cell: ({ value }) => <span>{value || "--"}</span>,
      },
      {
        Header: props.t("Category"),
        accessor: "Category.name",
        search: false,
        Cell: ({ value }) => <span>{value || "--"}</span>,
      },
      {
        Header: props.t("SubCategory"),
        accessor: "SubCategory.name",
        search: false,
        Cell: ({ value }) => <span>{value || "--"}</span>,
      },
    ],
    []
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItems={breadcrumbItems}
            title={props.t("Select Products")}
            className={"mb-3 pb-0"}
            backLink={`/v1/campaigns/${params.id}`}
          />
          <Loading status={loading} />
          <Row className="mb-3 border border-success border-radius-20 d-flex align-items-center">
            {terms_product_step_1.map((item, idx) => (
              <Col
                md="4"
                className={"mt-3 mb-3 ps-3 pe-3 term-btn"}
                key={idx}
                onClick={() => {
                  if (validation.values.step_1 != item.value) {
                    validation.setFieldValue("step_1", item.value) 
                    // validation.setFieldValue("Brands", []) 
                    // validation.setFieldValue("Categories", [])
                    // setSelectedList([])
                  }
                }}
              >
                <div className="term-container">
                  <img
                    className={
                      validation.values.step_1 == item.value ? "active" : ""
                    }
                  />
                  <div>
                    <Label>{item.label}</Label>
                    <p>{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          {validation.values.step_1 == "brand-categories" ? (
            <Row>
              <Col md="12">
                {props.t("Match Product Count") + ": " + productCountFilter}
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <label className="control-label">
                    {props.t("Brands")}{" "}
                    {productCountFilterBrand > 0
                      ? ` - ${productCountFilterBrand} Adet`
                      : ""}
                  </label>
                  <Select
                    placeholder={props.t("Select") + "..."}
                    value={validation.values.Brands}
                    isMulti={true}
                    onChange={newList => {
                      validation.setFieldValue("Brands", newList)
                      let tempList = [...products]
                      setProductCountFilter(
                        tempList?.filter(
                          p =>
                            newList?.findIndex(x => x.value == p.Brand?._id) >
                              -1 ||
                            validation.values.Categories?.findIndex(
                              x => x.value == p.Category?._id
                            ) > -1
                        ).length
                      )
                      setProductCountFilterBrand(
                        tempList?.filter(
                          p =>
                            newList?.findIndex(x => x.value == p.Brand?._id) >
                            -1
                        ).length
                      )
                    }}
                    options={brandList}
                    className="select2-selection"
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <label className="control-label">
                    {props.t("Categories")}{" "}
                    {productCountFilterCategory > 0
                      ? ` - ${productCountFilterCategory} Adet`
                      : ""}
                  </label>
                  <Select
                    placeholder={props.t("Select") + "..."}
                    value={validation.values.Categories}
                    isMulti={true}
                    onChange={newList => {
                      validation.setFieldValue("Categories", newList)
                      let tempList = [...products]
                      setProductCountFilter(
                        tempList?.filter(
                          p =>
                            newList?.findIndex(
                              x => x.value == p.Category?._id
                            ) > -1 ||
                            validation.values.Brands?.findIndex(
                              x => x.value == p.Brand?._id
                            ) > -1
                        ).length
                      )
                      setProductCountFilterCategory(
                        tempList?.filter(
                          p =>
                            newList?.findIndex(
                              x => x.value == p.Category?._id
                            ) > -1
                        ).length
                      )
                    }}
                    options={categoryList}
                    className="select2-selection"
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                  />
                </div>
              </Col>
            </Row>
          ) : null}

          {validation.values.step_1 == "custom" && productList ? (
            <TableCheckContainer
              loading={loading}
              columns={columns}
              data={productList}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              className="custom-header-css"
              tableSetting={tableSetting}
              handleSelectedRow={setSelectedFlatRows}
              defaultSelectedRows={[
                "6411b1b2e89d9d27c0a2fb78",
                "6411681e48a6c0225464cd93",
                "6407c040a0eb5a0da877674f",
              ]}
            />
          ) : null}

          <Form
            className="needs-validation mb-3"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Button
              color="primary"
              type="submit"
              style={
                validation.values.step_1 == "custom"
                  ? { marginTop: "-70px" }
                  : {}
              }
            >
              {props.t("Save")}
            </Button>
          </Form>

          {/* {validation.values.step_1 == "custom" && data ? (
            <TableCheckContainerServerSide
              loading={loading}
              columns={columns}
              data={data}
              isGlobalFilter={true}
              isAddOptions={false}
              tableSetting={tableSetting}
              customPageSize={tableSetting.limit}
              className="custom-header-css"
              tableOptions={tableOptions}
              action={(pageIndex, pageSize, search) => {
                setTableSetting({
                  orderBy: "createdAt",
                  ascDesc: -1,
                  limit: pageSize,
                  page: pageIndex,
                  select: tableSetting.select,
                  search: search,
                })
              }}
            />
          ) : null} */}
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignProductsStep4.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignProductsStep4)
