import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ChangeSaga from "./auth/changepwd/saga"
import ProfileSaga from "./auth/profile/saga" 
import NotificationSaga from "./auth/notification/saga"


import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"
 
import companiesSaga from "./companies/saga"
import brandsSaga from "./brands/saga"
import campaignsSaga from "./campaigns/saga"
import productsSaga from "./products/saga"
import classNamesSaga from "./classNames/saga"
import categoriesSaga from "./categories/saga"
import subCategoriesSaga from "./subCategories/saga"
 

export default function* rootSaga() {

  
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ChangeSaga),
    fork(ProfileSaga),
    fork(NotificationSaga),
    fork(LayoutSaga),
    fork(dashboardSaga), 
    fork(companiesSaga), 
    fork(brandsSaga), 
    fork(campaignsSaga), 
    fork(productsSaga), 
    fork(classNamesSaga),
    fork(categoriesSaga),
    fork(subCategoriesSaga),
  ])
}
