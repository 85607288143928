import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"
import { isEmpty } from "lodash"

//pass new generated access token here

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_API

const axiosApi = axios.create({
  baseURL: API_URL,
})

if (localStorage.getItem("v") !== process.env.REACT_APP_VERSION) {
  console.log("logout")
  localStorage.removeItem("authUser")
  localStorage.removeItem("branch")
  localStorage.removeItem("date")
  localStorage.setItem("v", process.env.REACT_APP_VERSION)
  location.reload()
}

// if (!isEmpty(authHeader()?.Authorization)) {
//   axiosApi.defaults.headers.common["Authorization"] = token
// }

axiosApi.interceptors.request.use(function (config) {
  const token = authHeader()
  if (token) {
    config.headers.common["Authorization"] = authHeader().Authorization
  }
  return config
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message
        } else {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              logout()
              break
            default:
              message = err[1]
              break
          }
        }
      }
      throw message
    })
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message
        } else {
          switch (err.response.status) {
            case 404:
              message =
                "Sorry! the page you are looking for could not be foundddd"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              logout()
              break
            default:
              message = err[1]
              break
          }
        }
      }
      throw message
    })
}

export async function put(url, data, config = {}) {
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message
        } else {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              logout()
              break
            default:
              message = err[1]
              break
          }
        }
      }
      throw message
    })
}

export async function del(url, config = {}) {
  return axiosApi
    .delete(url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message
        } else {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              logout()
              break
            default:
              message = err[1]
              break
          }
        }
      }
      throw message
    })
}

export async function postX(url, data, files, config = {}) {
  var formData = new FormData()

  try {
    console.log("postX try")
    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key])
      }
    }
    console.log("postX end")
  } catch (err) {
    console.log("postX Err", err)
  }
  for (var key in files) {
    formData.append("files", files[key], files[key].path)
  }
  return await axiosApi
    .post(url, formData, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message
        } else {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              logout()
              break
            default:
              message = err[1]
              break
          }
        }
      }
      throw message
    })
}
export async function postXFileOnly(url, file, config = {}) {
  var formData = new FormData()
  if (file) {
    formData.append("files", file, file.path)
  }
  return await axiosApi
    .post(url, formData, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message
        } else {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              logout()
              break
            default:
              message = err[1]
              break
          }
        }
      }
      throw message
    })
}

const logout = () => {
  setTimeout(() => {
    location.href = "/logout"
  }, 600)
}
