import {
  GET_CLASSNAMES_SUCCESS,
  GET_CLASSNAMES_FAIL,
  GET_CLASSNAMES,
  ADD_CLASSNAME_SUCCESS,
  ADD_CLASSNAME_FAIL,
  ADD_CLASSNAME,
  UPDATE_CLASSNAME_SUCCESS,
  UPDATE_CLASSNAME_FAIL,
  UPDATE_CLASSNAME,
  DELETE_CLASSNAME_SUCCESS,
  DELETE_CLASSNAME_FAIL,
  DELETE_CLASSNAME, 
  CLASSNAMES_RESET,
  CLASSNAMES_RESET_ERROR, 
  CLASSNAMES_RESET_SUCCESS, 
  GET_CLASSNAME,
  GET_CLASSNAME_FAIL,
  GET_CLASSNAME_SUCCESS,
  CLASSNAME_RESET,
  CLASSNAME_LOADING, 
} from "./actionTypes"

export const classNameLoading = status => ({
  type: CLASSNAME_LOADING,
  payload: { status },
})

//CLASSNAMES
export const getClassNamesSuccess = (actionType, data, tableOptions) => ({
  type: GET_CLASSNAMES_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getClassNamesFail = (actionType, error) => ({
  type: GET_CLASSNAMES_FAIL,
  payload: { actionType, error },
})

export const getClassNames = () => ({
  type: GET_CLASSNAMES,
  payload: { },
})

export const getClassNameSuccess = (actionType, data) => ({
  type: GET_CLASSNAME_SUCCESS,
  payload: { actionType, data },
})

export const getClassNameFail = (actionType, error) => ({
  type: GET_CLASSNAME_FAIL,
  payload: { actionType, error },
})

export const getClassName = id => ({
  type: GET_CLASSNAME,
  payload: { id },
})

export const addClassNameSuccess = (actionType, data) => ({
  type: ADD_CLASSNAME_SUCCESS,
  payload: { actionType, data },
})

export const addClassNameFail = (actionType, error) => ({
  type: ADD_CLASSNAME_FAIL,
  payload: { actionType, error },
})

export const addClassName = (data, history) => ({
  type: ADD_CLASSNAME,
  payload: { data, history },
})

export const updateClassNameSuccess = (actionType, data) => ({
  type: UPDATE_CLASSNAME_SUCCESS,
  payload: { actionType, data },
})

export const updateClassNameFail = (actionType, error) => ({
  type: UPDATE_CLASSNAME_FAIL,
  payload: { actionType, error },
})

export const updateClassName = (id, data, history) => ({
  type: UPDATE_CLASSNAME,
  payload: { id, data, history },
})

export const deleteClassNameSuccess = (actionType, data) => ({
  type: DELETE_CLASSNAME_SUCCESS,
  payload: { actionType, data },
})

export const deleteClassNameFail = (actionType, error) => ({
  type: DELETE_CLASSNAME_FAIL,
  payload: { actionType, error },
})

export const deleteClassName = (id, history) => ({
  type: DELETE_CLASSNAME,
  payload: { id, history },
})
    

export const clearClassName = () => ({
  type: CLASSNAME_RESET,
})
export const classNamesResetAll = () => ({
  type: CLASSNAMES_RESET,
})

export const classNamesResetError = () => ({
  type: CLASSNAMES_RESET_ERROR,
})

export const classNamesResetSuccess = () => ({
  type: CLASSNAMES_RESET_SUCCESS,
})
