import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import {
  getCities as onCities,
  getDistricts as onDistricts,
  districtReset as onDistrictReset,
  getCompany as onGet,
  clearCompany as onClearCompany,
  updateCompany as onSave,
  deleteCompany as onDelete,
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"

const CompanyEdit = props => {
  //meta title
  document.title = props.t("Edit Company")

  const query = new URLSearchParams(props.location.search)

  const dispatch = useDispatch()
  const { error, cities, districts, company, loading } = useSelector(state => ({
    error: state.Companies?.error,
    cities: state.Companies?.cities || [],
    districts: state.Companies?.districts || [],
    company: state.Companies?.company,
    loading: state.Companies?.loading || false,
  }))

  const [data, setData] = useState(null)
  const [loading2, setLoading2] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Companies"), link: "/v1/companies" },
    { title: props.t("Edit Company"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(cities)) {
      dispatch(onCities())
    }
    if (params.id) {
      if (isEmpty(company) && isEmpty(data)) {
        dispatch(onGet(params.id))
      } else if (!isEmpty(company) && isEmpty(data)) {
        const temp_data = { ...company }
        setData(temp_data)
        for (let key of Object.keys(validation.values)) {
          try {
            // console.log(key, temp_data[key], typeof temp_data[key])
            validation.setFieldValue(key, temp_data[key])
          } catch (err) {
            console.error("key converter failed", key, err)
          }
        }
        if (
          Object.keys(validation.values).includes("e_invoice") &&
          temp_data["e_invoice"] === true
        ) {
          document.getElementById("e_invoice").checked = true
        }
        setTimeout(() => {
          dispatch(onClearCompany())
        }, 300)
      }
    }
  }, [company])
  /*
  Anonim Şirket.
Limited Şirket.
Kollektif Şirket.
Komandit Şirket.
Kooperatif Şirket
  */
  const companyTypes = [
    { label: "Anonim Şirket", value: "anonim-sirket" },
    { label: "Limited Şirket", value: "limited-sirket" },
    { label: "Kollektif Şirket", value: "kollektif-sirket" },
    { label: "Komandit Şirket", value: "komandit-sirket" },
    { label: "Kooperatif Şirket", value: "kooperatif-sirket" },
  ]

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      companyType: "",
      address: "",
      zipCode: "",
      city: "",
      state: "",
      phone: "",
      email: "",
      taxOffice: "",
      taxId: "",
      e_invoice: false,
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required")),
      taxId: Yup.string().required(props.t("Required")),
      taxOffice: Yup.string().required(props.t("Required")),
      city: Yup.string().required(props.t("Required")),
      state: Yup.string().required(props.t("Required")),
      // className: Yup.string().required(props.t("Required")),
      // category: Yup.string().required(props.t("Required")),
      // subCategory: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", params.id, values)
      //props.history.push(`/v1/products/create/step2/01`)
      dispatch(onSave(params.id, values, props.history))
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  const onGetTax = async tax => {
    setLoading2(true)

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/companies/get-by-tax/${tax}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          for (let key of Object.keys(validation.values)) {
            if (key != "taxId" && key != "city") {
              validation.setFieldValue(key, response.data.data[key])
            } else if (key == "city") {
              validation.setFieldValue(key, response.data.data[key])
              let _cities = [...cities]
              let city = _cities.find(
                x => x.name == response.data.data[key].toString()
              )
              if (city) {
                console.log(city)
                dispatch(onDistricts(city.id))
              } else {
                dispatch(onDistrictReset())
                validation.setFieldValue("state", "")
              }
            }
          }
        } else {
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Company")}
            backLink={"/v1/companies"}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading || loading2} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("Edit Company")}</h4>
                  <p className="card-title-desc">
                    {props.t("Edit Company Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationTaxId">
                            {props.t("TaxId")}
                          </Label>
                          <Input
                            name="taxId"
                            placeholder={props.t("TaxId")}
                            type="text"
                            className="form-control"
                            id="validationTaxId"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.taxId || ""}
                            invalid={
                              validation.touched.taxId &&
                              validation.errors.taxId
                                ? true
                                : false
                            }
                            onBlurCapture={async e => {
                              await onGetTax(e.target.value)
                            }}
                            onKeyDown={async e => {
                              if (e.key === "Tab") {
                                //e.preventDefault();
                                await onGetTax(e.target.value)
                              }
                            }}
                          />
                          {validation.touched.taxId &&
                          validation.errors.taxId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.taxId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationTaxOffice">
                            {props.t("TaxOffice")}
                          </Label>
                          <Input
                            name="taxOffice"
                            placeholder={props.t("TaxOffice")}
                            type="text"
                            className="form-control"
                            id="validationTaxOffice"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.taxOffice || ""}
                            invalid={
                              validation.touched.taxOffice &&
                              validation.errors.taxOffice
                                ? true
                                : false
                            }
                          />
                          {validation.touched.taxOffice &&
                          validation.errors.taxOffice ? (
                            <FormFeedback type="invalid">
                              {validation.errors.taxOffice}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="e_invoice"></Label>
                          <div className="form-check pt-3">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="e_invoice"
                              name="e_invoice"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.e_invoice}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="e_invoice"
                            >
                              {props.t("e_invoice")}
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Company Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("Company Name")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="validationCompanyType">
                            {props.t("CompanyType")}
                          </Label>
                          <select
                            id="validationCompanyType"
                            className={
                              validation.touched.companyType &&
                              validation.errors.companyType
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="companyType"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.companyType || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {companyTypes.map((item, idx) => (
                              <option key={idx} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.companyType &&
                          validation.errors.companyType ? (
                            <FormFeedback type="invalid">
                              {validation.errors.companyType}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationAddress">
                            {props.t("Address")}
                          </Label>
                          <Input
                            name="address"
                            placeholder={props.t("Address")}
                            type="textarea"
                            className="form-control"
                            id="validationAddress"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationZipCode">
                            {props.t("ZipCode")}
                          </Label>
                          <Input
                            name="zipCode"
                            placeholder={props.t("ZipCode")}
                            type="text"
                            className="form-control"
                            id="validationZipCode"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zipCode || ""}
                            invalid={
                              validation.touched.zipCode &&
                              validation.errors.zipCode
                                ? true
                                : false
                            }
                          />
                          {validation.touched.zipCode &&
                          validation.errors.zipCode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.zipCode}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCity">
                            {props.t("City")}
                          </Label>
                          <select
                            id={`city`}
                            className={
                              validation.touched.city && validation.errors.city
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name={`city`}
                            onChange={e => {
                              validation.handleChange(e)
                              let _cities = [...cities]
                              let city = _cities.find(
                                x => x.name == e.target.value
                              )
                              if (city) {
                                console.log(city)
                                dispatch(onDistricts(city.id))
                              } else {
                                dispatch(onDistrictReset())
                                validation.setFieldValue("state", "")
                              }
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {cities.map((item, idx) => (
                              <option key={idx} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationState">
                            {props.t("State")}
                          </Label>
                          <select
                            id={`state`}
                            className={
                              validation.touched.state &&
                              validation.errors.state
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name={`state`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {districts.map((item, idx) => (
                              <option key={idx} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCompanyPhone">
                            {props.t("Company Phone")}
                          </Label>
                          <Input
                            tag={InputMask}
                            mask={"0(999) 999-9999"}
                            name="phone"
                            placeholder={props.t("Company Phone")}
                            type="text"
                            className="form-control"
                            id="validationCompanyPhone"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationEmail">
                            {props.t("Company Email")}
                          </Label>
                          <Input
                            name="email"
                            placeholder={props.t("Company Email")}
                            type="text"
                            className="form-control"
                            id="validationEmail"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationDescription">
                            {props.t("Description")}
                          </Label>
                          <Input
                            name="description"
                            placeholder={props.t("Description")}
                            type="textarea"
                            className="form-control"
                            id="validationDescription"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                    <Button
                      color="danger"
                      className="ms-2"
                      onClick={() => {
                        Swal.fire({
                          title: props.t("Are You Sure"),
                          text: props.t("DeleteWarning"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: props.t("Yes, delete it!"),
                          cancelButtonText: props.t("Cancel"),
                        }).then(result => {
                          if (result.isConfirmed) {
                            dispatch(onDelete(params.id, props.history))
                          }
                        })
                      }}
                    >
                      {props.t("Delete")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CompanyEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CompanyEdit)
