import ss1 from "../../assets/images/dummy/companies/ss1.png"
import ss2 from "../../assets/images/dummy/companies/ss2.png"

import samsung from "../../assets/images/dummy/brands/samsung.png"
import general_mobile from "../../assets/images/dummy/brands/general-mobile.png"

const campaigns = [
  {
    _id: "c01",
    Brand: {
      _id: "b01",
      name: "General Mobile",
      image: general_mobile,
    },
    image: ss1,
    status: "continue",
    title: "1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi",
    description:
      "1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234",
    startDate: new Date().setDate(new Date().getDate() - 5),
    endDate: new Date().setDate(new Date().getDate() + 18),
    requestList:[
      "Seri Numarası",
      "Fatura Numarası",
      "Müşteri Telefon No"
    ]
  },
  {
    _id: "c02",
    Brand: {
      _id: "b02",
      name: "Samsung",
      image: samsung,
    },
    image: ss2,
    status: "wait",
    title: "1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi",
    description:
      "1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234",
    startDate: new Date().setDate(new Date().getDate() + 4),
    endDate: new Date().setDate(new Date().getDate() + 20),
    requestList:[ 
      "Fatura Numarası",
      "Müşteri Telefon No"
    ]
  },

  {
    _id: "c03",
    Brand: {
      _id: "b01",
      name: "General Mobile",
      image: general_mobile,
    },
    image: ss1,
    status: "finish",
    title: "1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi",
    description:
      "1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi1234 kişi 1234 kişi1234",
    startDate: new Date().setDate(new Date().getDate() - 5),
    endDate: new Date().setDate(new Date().getDate()),
    requestList:[
      "Seri Numarası", 
      "Müşteri Telefon No"
    ]
  },
]

const branchGroup = [
  {
    _id:"01",
    name:"Ankara", 
    status:'false'
  },
  {
    _id:"02",
    name:"Turkcell Bayi", 
    status:'false'
  },
  {
    _id:"03",
    name:"İyi Bayiler", 
    status:'false'
  },
  {
    _id:"04",
    name:"İç Anadolu", 
    status:'false'
  },
]

const branchesForGroups = [
  {
    _id:"01",
    Group:"01",
    name:"Ankara T1",
    status:'false'
  },
  {
    _id:"02",
    Group:"01",
    name:"Ankara T2",
    status:'false'
  },
  {
    _id:"03",
    Group:"01",
    name:"Ankara T3",
    status:'false' 
  },
  {
    _id:"04",
    Group:"01",
    name:"Ankara T4",
    status:'false' 
  },
  {
    _id:"11",
    Group:"02",
    name:"Turkcell Bayi T1",
    status:'false' 
  },
  {
    _id:"12",
    Group:"02",
    name:"Turkcell Bayi T2",
    status:'false' 
  },
  {
    _id:"13",
    Group:"02",
    name:"Turkcell Bayi T3",
    status:'false' 
  },
  {
    _id:"14",
    Group:"02",
    name:"Turkcell Bayi T4",
    status:'false' 
  },
  {
    _id:"21",
    Group:"03",
    name:"İyi Bayiler T1",
    status:'false' 
  },
  {
    _id:"22",
    Group:"03",
    name:"İyi Bayiler T2",
    status:'false' 
  },
  {
    _id:"23",
    Group:"03",
    name:"İyi Bayiler T3",
    status:'false' 
  },
  {
    _id:"24",
    Group:"03",
    name:"İyi Bayiler T4",
    status:'false' 
  },
  {
    _id:"31",
    Group:"04",
    name:"İç Anadolu T1",
    status:'false' 
  },
  {
    _id:"32",
    Group:"04",
    name:"İç Anadolu T2",
    status:'false' 
  },
  {
    _id:"33",
    Group:"04",
    name:"İç Anadolu T3",
    status:'false' 
  },
  {
    _id:"34",
    Group:"04",
    name:"İç Anadolu T4",
    status:'false' 
  },
  {
    _id:"02",
    Group:"04",
    name:"Ankara T2",
    status:'false' 
  },
]

export { campaigns, branchGroup, branchesForGroups }
