export const GET_BRANDS = "GET_BRANDS"
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS"
export const GET_BRANDS_FAIL = "GET_BRANDS_FAIL"

export const GET_BRAND = "GET_BRAND"
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS"
export const GET_BRAND_FAIL = "GET_BRAND_FAIL"

export const ADD_BRAND = "ADD_BRAND"
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS"
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL"

export const UPDATE_BRAND = "UPDATE_BRAND"
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS"
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL"

export const DELETE_BRAND = "DELETE_BRAND"
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS"
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL"
 
export const BRAND_RESET = "BRAND_RESET"
export const BRANDS_RESET_ERROR = "BRANDS_RESET_ERROR"
export const BRANDS_RESET = "BRANDS_RESET"
export const BRANDS_RESET_SUCCESS = "BRANDS_RESET_SUCCESS"


export const BRAND_LOADING = "BRAND_LOADING"
