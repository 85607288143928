import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { 
  addSubCategory as onSave,
  getCategory as onCategory,
  subCategoriesResetError as onClearError,
  subCategoryLoading as onLoading
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"

const SubCategoryCreate = props => {
  //meta title
  document.title = props.t("Create SubCategory")

  const query = new URLSearchParams(props.location.search)

  const dispatch = useDispatch()
  const { error, loading, category } = useSelector(state => ({
    error: state.SubCategories?.error, 
    category: state.Categories?.category,
    loading: state.SubCategories?.loading || false
  })) 

  const breadcrumbItems = [
    { title: props.t("Settings"), link: "/settings" },
    { title: props.t("Categories"), link: "/settings/categories" },
    { title: props.t("Create SubCategory"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  useEffect(() => {
    if(isEmpty(category)){
      dispatch(onCategory(params.id))
    }
    dispatch(onLoading(false))
  }, [])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error || error?.message || JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])


  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "", 
      Category: params.id
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required")),  
    }),
    onSubmit: values => {
      console.log("values", values) 
      dispatch(onSave(values, props.history))
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("SubCategories") + `${category? ` (${category?.name})`:''}`}
            backLink={'/settings/categories'}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New SubCategory")}</h4>
                  <p className="card-title-desc">
                    {props.t("New SubCategory Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">
                            {props.t("SubCategory Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("SubCategory Name")}
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col> 
                    </Row> 
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SubCategoryCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(SubCategoryCreate)
