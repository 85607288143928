import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { branches } from "./../../../../common/data/companies"
import { useDispatch, useSelector } from "react-redux"
import { getBranchGroups as onList } from "store/actions"
import TableContainerServerSide from "components/Common/TableContainerServerSide"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import TableContainer from "../../../../components/Common/TableContainer"
import { isEmpty } from "lodash"

const BranchGroups = props => {
  //meta title
  document.title = props.t("BranchGroups")

  const dispatch = useDispatch()
  const { error, branchGroups, branchGroupsTableOptions, loading } = useSelector(
    state => ({
      error: state.Companies?.error,
      branchGroups: state.Companies?.branchGroups || [],
      branchGroupsTableOptions: state.Companies?.branchGroupsTableOptions || null,
      loading: state.Companies?.loading || false,
    })
  )

  const breadcrumbItems = [
    { title: props.t("Companies"), link: "/v1/companies" },
    { title: props.t("BranchGroups"), link: "/" },
  ]

  const [selectedItem, setSelectedItem] = useState("all")
  const [tableSetting, setTableSetting] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 100,
    page: 0,
    select: "name",
    search: "",
  })
  const [data, setData] = useState([])

  const {
    match: { params },
  } = props

  useEffect(() => {
    dispatch(onList(tableSetting))
  }, [tableSetting])

  useEffect(() => {
    setData(branchGroups)
  }, [branchGroups])

  // useEffect(()=>{
  //   if(isEmpty(data) && !isEmpty(params.id)){
  //     setData(branches.filter(x=>x.companyId == params.id))
  //   }
  // },[])

  const columns = useMemo(
    () => [
      {
        Header: props.t("BranchGroup Name"),
        accessor: "name",
        search: false,
      }, 
      {
        Header: '',
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {/* <Link
              to={`/v1/companies/branch-groups/${value}`}
              className="btn btn-sm btn-warning text-white me-2"
            >
              {props.t("Persons")}
            </Link> */}
            <Link
              to={`/settings/branch-groups/edit/${value}`}
              className="btn btn-sm btn-warning text-white me-2"
            >
              {props.t("Edit")}
            </Link>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("BranchGroups")}
            backLink={"/settings"}
            breadcrumbItems={breadcrumbItems}
            isAdd={true}
            addTitle={props.t("Create BranchGroup")}
            addHandle={() => {
              props.history.push("/settings/branch-groups/create")
            }}
            className={"mb-0 pb-0"}
          />

          <TableContainerServerSide
            loading={loading}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            tableSetting={tableSetting}
            customPageSize={tableSetting.limit}
            className="custom-header-css"
            totalCount={branchGroupsTableOptions?.productTotal || 0}
            queryPageIndex={tableSetting.page}
            queryPageSize={tableSetting.limit}
            tableOptions={branchGroupsTableOptions}
            action={(pageIndex, pageSize, search) => {
              setTableSetting({
                orderBy: "createdAt",
                ascDesc: -1,
                limit: pageSize,
                page: pageIndex,
                select: tableSetting.select,
                search: search,
              })
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

BranchGroups.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(BranchGroups)
