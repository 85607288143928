import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  getCampaignNewData as onGetNewData,
  getCampaign as onGet,
  updateCampaignNewData as onUpdate,
  postCampaignNewData as onPost,
  getBrands as onGetBrands,
  brandsResetError as onClearError,
  clearCampaign as onClearCampaign,
  campaignSuccess as onSuccess,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { ErrorMessage, useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"
import InputMask from "react-input-mask"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import img_empty from "./../../../assets/images/jobs.png"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import { Role, authorize } from "helpers/authorize"

// import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js"
// import draftToHtml from "draftjs-to-html"
// import htmlToDraft from "html-to-draftjs"

const CampaignNewDataListControl = props => {
  //meta title
  document.title = props.t("New Data List Control")

  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const [data, setData] = useState([])
  const [nData, setNData] = useState()
  const [list, setList] = useState()
  const [listOptions, setListOptions] = useState(null)
  const [loading2, setLoading2] = useState(false)
  const [calculateStatus, setCalculateStatus] = useState(null)
  const [listParameters, setListParameters] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 10,
    page: 0,
    onlyWait: false,
  })
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const [text, setText] = useState()

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE

  const [imageZoom, setimageZoom] = useState([img_empty])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Campaign"), link: `/v1/campaigns/${params.id}` },
    { title: props.t("New Data List Control"), link: "#" },
  ]

  const dispatch = useDispatch()
  const { error, campaign, loading, success } = useSelector(state => ({
    error: state.Campaigns?.error,
    campaign: state.Campaigns?.campaign,
    loading: state.Campaigns?.loading || state.Brands?.loading || false,
    success: state.Campaigns?.success,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(campaign)) {
      dispatch(onGet(params.id))
    }
  }, [])

  useEffect(() => {
    GetList()
  }, [listParameters])

  useEffect(() => {
    if (!isEmpty(campaign)) {
      setCalculateStatus(campaign.calculateStatus)
      if (!isEmpty(campaign.image)) {
        setimageZoom([IMAGE_BASE + campaign.image])
      }
      const temp_data = []
      for (let info of campaign.requestInfo) {
        let infoIndex = requestList.findIndex(x => x.value == info)
        if (infoIndex > -1) {
          temp_data.push({
            id: requestList[infoIndex].value,
            name: requestList[infoIndex].value,
            label: requestList[infoIndex].label,
            value: "",
            type: requestList[infoIndex].type,
          })
        }
      }
      setData(temp_data)
    }
  }, [campaign])

  useEffect(() => {
    if (!isEmpty(success)) {
      Swal.fire(success.title, success.message, success.status).then(
        ({ isConfirmed }) => {
          dispatch(onSuccess(null))
        }
      )
    }
  }, [success])

  const requestList = [
    { label: "Seri Numarası", value: "seri-numarasi", type: "text" },
    { label: "Barkod", value: "barcode", type: "text" },
    { label: "Müşteri Adı", value: "musteri-adi", type: "text" },
    { label: "Müşteri Soyadı", value: "musteri-soyadi", type: "text" },
    { label: "Müşteri TC", value: "musteri-tc", type: "number" },
    { label: "Müşteri Numarası", value: "musteri-numarasi", type: "text" },
    { label: "Müşteri Telefon No", value: "musteri-telefon-no", type: "phone" },
    { label: "Müşteri E-Posta", value: "musteri-e-posta", type: "text" },
    { label: "Fatura No", value: "fatura-no", type: "text" },
    { label: "Fatura Tarihi", value: "fatura-tarihi", type: "date" },
  ]

  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Campaign_Continues")}
          </span>
        )
      case "wait":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Wait")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Campaign_Finish")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-light">
            {props.t("Campaign_Other")}
          </span>
        )
    }
  }

  const dataStatusText = status => {
    switch (status) {
      case "wait":
        return (
          <span className="badge badge-soft-warning">{props.t("wait")}</span>
        )
      case "confirmed":
        return (
          <span className="badge badge-soft-success">
            {props.t("confirmed")}
          </span>
        )
      case "rejected":
        return (
          <span className="badge badge-soft-danger">{props.t("rejected")}</span>
        )
      default:
        return (
          <span className="badge badge-soft-danger">
            {props.t("new-data-status-other")}
          </span>
        )
    }
  }

  const html_text_table = item => {
    let html_text = "<div class='modal-table-container'><table>"
    // html_text += `<tr><td class='modal-table-left'><b>${props.t(
    //   "Status"
    // )} :</b></td><td class='modal-table-left'>${props.t(item.status)}</td></tr>`

    html_text += `<tr><td class='modal-table-left'><b>${props.t(
      "User"
    )} :</b></td><td class='modal-table-left'>${item.User?.name}</td></tr>`

    html_text += `<tr><td class='modal-table-left'><b>${props.t(
      "Branch2"
    )} :</b></td><td class='modal-table-left'>${item.Company?.name} - ${
      item.Branch?.name
    } - ${item.Branch?.city}/${item.Branch?.state}</td></tr>`
    html_text += `<tr><td class='modal-table-left' colSpan='2'><hr style="border-top: 1px solid #b5b5b5;margin: 5px;"/></td></tr>`

    html_text += `<tr><td class='modal-table-left'><b>${props.t(
      "Product"
    )} :</b></td><td class='modal-table-left'>${item.Product?.name} ${
      item.Variant ? ` - ${item.Variant?.name}` : ""
    }</td></tr>`

    for (let info of item.requestInfo.filter(
      x => x.key != "Product" && x.key != "Variant"
    )) {
      html_text += `<tr><td class='modal-table-left'><b>${props.t(
        info.key
      )}:</b></td><td class='modal-table-left'>${info.value}</td></tr>`
    }
    html_text += `<tr><td class='modal-table-left'><b>${props.t(
      "Auto Reason for Rejection"
    )}: </b></td><td class='modal-table-left'>${
      item.autoMessage
        ? `<div class='alert alert-danger'>${item.autoMessage}</div>`
        : `<div class='alert alert-success'>${props.t(
            "Registration Appears Eligible"
          )}</div>`
    }</td></tr>`
    html_text += "</table></div>"
    return html_text
  }

  const GetList = async () => {
    setLoading2(true)
    let data = JSON.stringify(listParameters)

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/campaigns/new-data-list-control/${params.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          let p = response.data.data
          setList(p)
          let pageCount = response.data.pageCount
          let paging = [1]
          if (pageCount > 1 && pageCount < 8) {
            for (let i = 2; i <= pageCount; i++) {
              paging.push(i)
            }
          } else if (
            pageCount > 1 &&
            pageCount >= 8 &&
            (listParameters.page == 0 || listParameters.page == pageCount - 1)
          ) {
            let center = pageCount / 2
            paging.push(center - 1)
            paging.push(center)
            paging.push(center + 1)
            paging.push(pageCount)
          } else if (pageCount > 1 && pageCount >= 8) {
            let center = listParameters.page + 1
            if (!paging.includes(center - 1)) paging.push(center - 1)
            if (!paging.includes(center)) paging.push(center)
            if (!paging.includes(center + 1)) paging.push(center + 1)
            if (!paging.includes(pageCount)) paging.push(pageCount)
          }

          setListOptions({
            productCount: response.data.productCount,
            pageCount: response.data.pageCount,
            productTotal: response.data.productTotal,
            paging: paging,
          })
        } else {
          setList(null)
          setListOptions(null)
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setList(null)
        setListOptions(null)
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  const onReject = async (item, rejectMessage) => {
    setLoading2(true)

    let data = JSON.stringify({
      status: "rejected",
      confirmMessage: rejectMessage,
    })
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/campaigns/new-data-status/${item._id}/${params.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          let id = response.data.data._id
          if (id) {
            let temp = [...list]
            let index = temp.findIndex(x => x._id == id)
            if (index != -1) {
              temp[index].status = "rejected"
              temp[index].confirmMessage = response.data.data.confirmMessage
              setList(temp)
            }
          }
        } else {
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  const onConfirmed = async item => {
    setLoading2(true)

    let data = JSON.stringify({
      status: "confirmed",
      confirmMessage: "Onaylandı",
    })
    console.log("data", data)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/campaigns/new-data-status/${item._id}/${params.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          let id = response.data.data._id
          if (id) {
            let temp = [...list]
            let index = temp.findIndex(x => x._id == id)
            if (index != -1) {
              temp[index].status = "confirmed"
              temp[index].confirmMessage = "Onaylandı"
              setList(temp)
            }
          }
        } else {
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  const onCalculating = async () => {
    setLoading2(true)
    setCalculateStatus("calculating")
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/campaigns/new-data-calculate/${params.id}?save=true&clear=true`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          setCalculateStatus("calculated")
          Swal.fire(
            props.t("Success"),
            props.t("Calculate Successful"),
            "success"
          ).then(() => {
            location.href=`/v1/campaigns/puank-list/${params.id}`
          })
        } else {
          Swal.fire("Hata", response.data.message, "error")
          setCalculateStatus(null)
        }
        setLoading2(false)
      })
      .catch(error => {
        setCalculateStatus(null)
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaigns Data List Control")}
            backLink={`/v1/campaigns/${params.id}`}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading2} />
          {isGalleryZoom ? (
            <Lightbox
              mainSrc={imageZoom[photoIndex]}
              nextSrc={imageZoom[(photoIndex + 1) % imageZoom.length]}
              prevSrc={
                imageZoom[
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                ]
              }
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % imageZoom.length)
              }}
            />
          ) : null}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    {props.t("New Data List Control")}
                  </h4>
                  <p className="card-title-desc">
                    {props.t("New Data List Control Description")}
                  </p>

                  <Row>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          {campaign.image ? (
                            <img
                              src={IMAGE_BASE + campaign.image}
                              className="campaign-img"
                              onClick={() => {
                                setisGalleryZoom(true)
                                setphotoIndex(0)
                              }}
                            />
                          ) : (
                            <img src={img_empty} className="campaign-img" />
                          )}
                        </Col>
                        <Col md={12}>
                          <span className="title">{campaign.Brand?.name}</span>
                        </Col>
                        <Col className="text-end">
                          {cardStatusText(campaign.status)}
                        </Col>
                        <Col md={12} className="text-end">
                          <div className="date-div">
                            <div className="date-title">
                              <span>{props.t("Start Date")} : </span>
                            </div>
                            <div className="date">
                              {moment(campaign.startDate).format("DD.MM.YYYY")}
                            </div>
                          </div>
                          <div className="date-div">
                            <div className="date-title">
                              <span>{props.t("End Date")} : </span>
                            </div>
                            <div className="date">
                              {moment(campaign.finishDate).format("DD.MM.YYYY")}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="mt-2">
                          <span className="title">{campaign.name}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      {authorize([Role.Dev]) ? (
                        <>
                          <div>
                            <div className="mt-1 float-start">
                              {props.t("Rec Total", {
                                val: listOptions?.productTotal || 0,
                              })}
                            </div>
                          </div>
                          <div className="mb-2 d-flex flex-row-reverse">
                            <select
                              className="ms-2"
                              value={listParameters.limit}
                              onChange={e => {
                                let temp = { ...listParameters }
                                temp.limit = parseInt(e.target.value)
                                setListParameters(temp)
                              }}
                            >
                              {[10, 20, 50, 100, 200].map((item, idx) => (
                                <option key={idx}>{item}</option>
                              ))}
                            </select>
                            <a
                              className={`ms-2 btn btn-sm ${
                                listParameters.onlyWait == true
                                  ? "btn-success"
                                  : "btn-warning"
                              }`}
                              onClick={() => {
                                let temp = { ...listParameters }
                                temp.page = 0
                                temp.onlyWait = !temp.onlyWait
                                setListParameters(temp)
                              }}
                            >
                              {listParameters.onlyWait == true
                                ? props.t("All")
                                : props.t("Only Wait Data")}
                            </a>

                            {authorize([Role.Dev]) ? (
                              <a
                                className={`btn btn-sm btn-light ms-2`}
                                disabled={
                                  calculateStatus == "calculating"
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  onCalculating()
                                }}
                              >
                                {calculateStatus == "calculating" ? (
                                  <>
                                    <Spinner
                                      className="me-2"
                                      color="primary"
                                      style={{ width: "10px", height: "10px" }}
                                    />
                                    {props.t("Calculating")}
                                  </>
                                ) : (
                                  props.t("Calculate")
                                )}
                              </a>
                            ) : null}
                            {calculateStatus == "calculated" ? (
                              <a
                                className={`btn btn-sm btn-light`}
                                href={`/v1/campaigns/puank-list/${params.id}`}
                              >
                                {props.t("Puank List")}
                              </a>
                            ) : null}
                          </div>

                          {list ? (
                            <>
                              <div className="table-responsive">
                                <table className="react-table table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>{props.t("Status")}</th>
                                      <th>{props.t("Product")}</th>
                                      <th>{props.t("User")}</th>
                                      {list[0]?.requestInfo
                                        .filter(
                                          x =>
                                            x.key != "Product" &&
                                            x.key != "Variant"
                                        )
                                        .map((item, idx) => (
                                          <th key={idx}>{props.t(item.key)}</th>
                                        ))}

                                      <th>{props.t("Ok-Reject")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {list.map((item, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {item.confirmMessage ||
                                          item.autoMessage ? (
                                            <a
                                              onClick={() => {
                                                Swal.fire(
                                                  props.t(item.status),
                                                  item.confirmMessage ||
                                                    item.autoMessage,
                                                  item.status == "rejected"
                                                    ? "error"
                                                    : item.status == "confirmed"
                                                    ? "success"
                                                    : "warning"
                                                )
                                              }}
                                            >
                                              {dataStatusText(item.status)}
                                            </a>
                                          ) : (
                                            dataStatusText(item.status)
                                          )}{" "}
                                        </td>
                                        <td>
                                          {item.Product?.name}
                                          {item.Variant
                                            ? ` - ${item.Variant?.name}`
                                            : ""}
                                        </td>
                                        <td>
                                          <div>{item.User?.name}</div>
                                          <div>
                                            <small>
                                              {item.Company?.name} -{" "}
                                              {item.Branch?.name} -{" "}
                                              {item.Branch?.city}/
                                              {item.Branch?.state}
                                            </small>
                                          </div>
                                        </td>
                                        {item.requestInfo
                                          .filter(
                                            x =>
                                              x.key != "Product" &&
                                              x.key != "Variant"
                                          )
                                          .map((info, index) => (
                                            <td key={index}>{info.value}</td>
                                          ))}
                                        <td>
                                          {item.status == "wait" ? (
                                            <>
                                              <a
                                                className="me-2"
                                                onClick={() => {
                                                  let html_text =
                                                    html_text_table(item)
                                                  Swal.fire({
                                                    title:
                                                      props.t("Are You Sure") +
                                                      " " +
                                                      props.t(
                                                        "You cannot undo this action"
                                                      ),
                                                    icon: "info",
                                                    html: html_text,
                                                    showCloseButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText:
                                                      props.t("Cancel"),
                                                    confirmButtonText:
                                                      props.t("Confirm"),
                                                    confirmButtonColor:
                                                      "#0ECB00",
                                                  }).then(({ isConfirmed }) => {
                                                    if (isConfirmed) {
                                                      onConfirmed(item)
                                                    }
                                                  })
                                                }}
                                              >
                                                <i className="mdi mdi-check text-success"></i>
                                              </a>
                                              <a
                                                onClick={() => {
                                                  let html_text =
                                                    html_text_table(item)
                                                  Swal.fire({
                                                    title:
                                                      props.t("Are You Sure") +
                                                      " " +
                                                      props.t(
                                                        "You cannot undo this action"
                                                      ),
                                                    icon: "info",
                                                    html: html_text,
                                                    showCloseButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText:
                                                      props.t("Cancel"),
                                                    focusConfirm: false,
                                                    input: "text",
                                                    inputLabel: props.t(
                                                      "Reason for Rejection"
                                                    ),
                                                    inputAttributes: {
                                                      autocapitalize: "off",
                                                    },
                                                    inputValue:
                                                      item.autoMessage || "",
                                                    confirmButtonText:
                                                      props.t("Reject"),
                                                    confirmButtonColor:
                                                      "#dd6b55",
                                                    preConfirm:
                                                      rejectMessage => {
                                                        onReject(
                                                          item,
                                                          rejectMessage
                                                        )
                                                      },
                                                  })
                                                }}
                                              >
                                                <i className="mdi mdi-close-thick text-danger"></i>
                                              </a>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                {listOptions ? (
                                  <>
                                    <div className="d-flex flex-row float-end">
                                      <Button
                                        className="me-1 btn-sm"
                                        color="light"
                                        disabled={
                                          listParameters.page == 0 ||
                                          listOptions.pageCount == 1
                                        }
                                        onClick={() => {
                                          let temp = { ...listParameters }
                                          temp.page = listParameters.page - 1
                                          setListParameters(temp)
                                        }}
                                      >
                                        <i className="bx bx-chevron-left"></i>
                                      </Button>
                                      {listOptions.paging.map((item, idx) => {
                                        if (
                                          idx > 0 &&
                                          listOptions.paging[idx] -
                                            listOptions.paging[idx - 1] >
                                            1
                                        ) {
                                          return (
                                            <div
                                              key={idx}
                                              className="d-flex flex-row"
                                            >
                                              <Button
                                                className="me-1 btn-sm"
                                                color={
                                                  listParameters.page + 1 ==
                                                  item
                                                    ? "dark"
                                                    : "light"
                                                }
                                                disabled={true}
                                              >
                                                ...
                                              </Button>
                                              <Button
                                                className="me-1 btn-sm"
                                                color="light"
                                                onClick={() => {
                                                  let temp = {
                                                    ...listParameters,
                                                  }
                                                  temp.page = item - 1
                                                  setListParameters(temp)
                                                }}
                                              >
                                                {item}
                                              </Button>
                                            </div>
                                          )
                                        } else {
                                          return (
                                            <Button
                                              key={idx}
                                              className="me-1 btn-sm"
                                              color={
                                                listParameters.page + 1 == item
                                                  ? "dark"
                                                  : "light"
                                              }
                                              onClick={() => {
                                                let temp = { ...listParameters }
                                                temp.page = item - 1
                                                setListParameters(temp)
                                              }}
                                            >
                                              {item}
                                            </Button>
                                          )
                                        }
                                      })}
                                      <Button
                                        className="me-1 btn-sm"
                                        color="light"
                                        disabled={
                                          listParameters.page ==
                                            listOptions.pageCount - 1 ||
                                          listOptions.pageCount == 1
                                        }
                                        onClick={() => {
                                          let temp = { ...listParameters }
                                          temp.page = listParameters.page + 1
                                          setListParameters(temp)
                                        }}
                                      >
                                        <i className="bx bx-chevron-right"></i>
                                      </Button>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              {/* {listParameters.page}
                          {listOptions.pageCount - 1}
                          {JSON.stringify(listOptions)} 
                          {JSON.stringify(listParameters)} */}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <div className="alert alert-danger">
                          {props.t("UnRole")}
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignNewDataListControl.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignNewDataListControl)
