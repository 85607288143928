import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getClassNamesSuccess,
  getClassNamesFail,
  addClassNameSuccess,
  addClassNameFail,
  getClassNameSuccess,
  getClassNameFail,
  updateClassNameSuccess,
  updateClassNameFail,
  deleteClassNameSuccess,
  deleteClassNameFail,
  classNameLoading,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClassNames,
  addClassName,
  updateClassName,
  deleteClassName,
  getClassName,
} from "../../helpers/fakebackend_helper"
import {
  GET_CLASSNAMES,
  GET_CLASSNAMES_FAIL,
  GET_CLASSNAMES_SUCCESS,
  ADD_CLASSNAME_SUCCESS,
  ADD_CLASSNAME,
  ADD_CLASSNAME_FAIL,
  GET_CLASSNAME_SUCCESS,
  GET_CLASSNAME_FAIL,
  GET_CLASSNAME,
  UPDATE_CLASSNAME_SUCCESS,
  UPDATE_CLASSNAME_FAIL,
  UPDATE_CLASSNAME,
  DELETE_CLASSNAME_SUCCESS,
  DELETE_CLASSNAME_FAIL,
  DELETE_CLASSNAME,
} from "./actionTypes"

//CLASSNAMES
function* getClassNamesFetch({ payload: {  } }) {
  try {
    yield put(classNameLoading(true))
    var response = yield call(getClassNames)
    if (response.status) {
      yield put(getClassNamesSuccess(GET_CLASSNAMES_SUCCESS, response.data, response))
    } else {
      yield put(
        getClassNamesFail(
          GET_CLASSNAMES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CLASSNAMES-1001"
        )
      )
    }
    yield put(classNameLoading(false))
  } catch (error) {
    yield put(getClassNamesFail(GET_CLASSNAMES_FAIL, error))
    yield put(classNameLoading(false))
  }
}

function* addClassNameFetch({ payload: { data, history } }) {
  try {
    yield put(classNameLoading(true))
    var response = yield call(addClassName, data)
    if (response.status) {
      yield put(addClassNameSuccess(ADD_CLASSNAME_SUCCESS, response.data))
      history.push("/settings/class-names")
    } else {
      yield put(
        addClassNameFail(
          ADD_CLASSNAME_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CLASSNAMES-1001"
        )
      )
    }

    yield put(classNameLoading(false))
  } catch (error) {
    yield put(addClassNameFail(ADD_CLASSNAME_FAIL, error))
    yield put(classNameLoading(false))
  }
}

function* updateClassNameFetch({ payload: { id, data, history } }) {
  try {
    yield put(classNameLoading(true))
    var response = yield call(updateClassName, id, data)
    if (response.status) {
      yield put(updateClassNameSuccess(UPDATE_CLASSNAME_SUCCESS, response.data))
      history.push("/settings/class-names")
    } else {
      yield put(
        updateClassNameFail(
          UPDATE_CLASSNAME_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CLASSNAMES-1001"
        )
      )
    }
    yield put(classNameLoading(false))
  } catch (error) {
    yield put(updateClassNameFail(UPDATE_CLASSNAME_FAIL, error))
    yield put(classNameLoading(false))
  }
}

function* deleteClassNameFetch({ payload: { id, history } }) {
  try {
    yield put(classNameLoading(true))
    var response = yield call(deleteClassName, id)
    if (response.status) {
      yield put(deleteClassNameSuccess(DELETE_CLASSNAME_SUCCESS, response.data))
      history.push("/settings/class-names")
    } else {
      yield put(
        deleteClassNameFail(
          DELETE_CLASSNAME_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CLASSNAMES-1001"
        )
      )
    }
    yield put(classNameLoading(false))
  } catch (error) {
    yield put(deleteClassNameFail(DELETE_CLASSNAME_FAIL, error))
    yield put(classNameLoading(false))
  }
}

function* getClassNameByIdFetch({ payload: { id } }) {
  try {
    yield put(classNameLoading(true))
    var response = yield call(getClassName, id)
    if (response.status) {
      yield put(getClassNameSuccess(GET_CLASSNAME_SUCCESS, response.data))
    } else {
      yield put(
        getClassNameFail(
          GET_CLASSNAME_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CLASSNAMES-1001"
        )
      )
    }
    yield put(classNameLoading(false))
  } catch (error) {
    yield put(getClassNameFail(GET_CLASSNAME_FAIL, error))
    yield put(classNameLoading(false))
  }
}

function* classNamesSaga() {
  yield takeEvery(GET_CLASSNAMES, getClassNamesFetch)
  yield takeEvery(GET_CLASSNAME, getClassNameByIdFetch)
  yield takeEvery(ADD_CLASSNAME, addClassNameFetch)
  yield takeEvery(UPDATE_CLASSNAME, updateClassNameFetch)
  yield takeEvery(DELETE_CLASSNAME, deleteClassNameFetch)
}

export default classNamesSaga
