import React, { forwardRef, useEffect, useRef } from "react"
const IndeterminateCheckbox = ({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      resolvedRef.current.indeterminate = !rest.checked && indeterminate
    }
    // resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
}
export default forwardRef(IndeterminateCheckbox)
