import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes"
import { userChangePasswordSuccess, userChangePasswordError } from "./actions"
import { postJwtChangePwd } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

 
//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changePasswordUser({ payload: { user, history } }) {
  
  try {
    const response = yield call(postJwtChangePwd, {
      id: user.id,
      hash: user.hash,
      password: user.password,
      re_password: user.re_password,
    })
    console.log(response)
    if (response.status == true) {
      yield put(userChangePasswordSuccess("Şifre Yenilendi"))
      Swal.fire("Başarılı", "Şifre Yenileme Başarılı. Giriş Yapın", "success")
      history.push("/login")
    } else {
      yield put(userChangePasswordError(response.data.message || "Hata Oluştu"))
    }
  } catch (error) {
    yield put(userChangePasswordError(error))
  }
}

function* ChangeSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordUser)
}

export default ChangeSaga
