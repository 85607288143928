import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { branches, companies } from "./../../../../common/data/companies"
import {
  getBranches as onGetBranches,
  clearCompany as onClearCompany,
  addPerson as onSave,
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"

const PersonCreate = props => {
  //meta title
  document.title = props.t("Create Person")

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, person, branches, loading } = useSelector(state => ({
    error: state.Companies?.error,
    person: state.Companies?.person,
    branches: state.Companies?.branches,
    loading: state.Companies?.loading || false,
  }))

  const query = new URLSearchParams(props.location.search)
  const [data, setData] = useState(null)
  const [branchList, setBranchList] = useState([])

  const breadcrumbItems = [
    { title: props.t("Companies"), link: "/v1/companies" },
    { title: props.t("Create Person"), link: "#" },
  ]

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(
        onGetBranches(query.get("cid") || params.id, {
          orderBy: "createdAt",
          ascDesc: -1,
          limit: 100,
          page: 0,
          select: "name city",
        })
      )
    }
  }, [])
  // useEffect(() => {
  //   if (isEmpty(branchList) && !isEmpty(branches) && !isEmpty(companies)) {
  //     let bList = branches.filter(x => x.companyId == params.id)
  //     let newList = []
  //     for (let b of bList) {
  //       let c = companies.filter(x => x._id == b.companyId)
  //       if (c) {
  //         b["Company"] = {
  //           _id: c[0]._id,
  //           name: c[0].name,
  //         }
  //         newList.push(b)
  //       }
  //     }
  //     setBranchList(newList)
  //   }
  // }, [])

  /*
  Anonim Şirket.
Limited Şirket.
Kollektif Şirket.
Komandit Şirket.
Kooperatif Şirket
  */
  const personTypes = [
    { label: "Yönetici", value: "61fed89e23193552689c3e57" },
    { label: "Koordinatör", value: "63f6fa4f26a7e0f349bdeb21" },
    { label: "Müdür", value: "63f6f95d26a7e0f349bdeb20" },
    { label: "Personel", value: "61fed8a723193552689c3e58" },
  ]

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      personType: "",
      address: "",
      zipCode: "",
      city: "",
      state: "",
      phone: "",
      email: "",
      taxOffice: "",
      taxId: "",
      description: "",
      Branches: [query.get("bid") ? query.get("bid") : ""],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required")),
      phone: Yup.string().required(props.t("Required")),
      email: Yup.string().required(props.t("Required")),
      // className: Yup.string().required(props.t("Required")),
      // category: Yup.string().required(props.t("Required")),
      // subCategory: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      let newValues = { ...values }
      //newValues["_id"] = params.id
      newValues["companyId"] = query.get("cid") || params.id
      console.log("values", newValues)
      dispatch(
        onSave(
          query.get("cid") || params.id,
          query.get("bid"),
          newValues,
          props.history
        )
      )
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Person")}
            backLink={
              !isEmpty(query.get("cid")) && !isEmpty(query.get("bid"))
                ? `/v1/companies/persons?cid=${query.get(
                    "cid"
                  )}&bid=${query.get("bid")}`
                : !isEmpty(query.get("cid"))
                ? `/v1/companies/persons?cid=${query.get("cid")}`
                : "/v1/companies"
            }
            // backLink={`/v1/companies/${query.bid?`persons/${query.bid}`:''}`}
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New Person")}</h4>
                  <p className="card-title-desc">
                    {props.t("New Person Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Person Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("Person Name")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="validationPersonType">
                            {props.t("PersonType")}
                          </Label>
                          <select
                            id="validationPersonType"
                            className={
                              validation.touched.personType &&
                              validation.errors.personType
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="personType"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.personType || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {personTypes.map((item, idx) => (
                              <option key={idx} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.personType &&
                          validation.errors.personType ? (
                            <FormFeedback type="invalid">
                              {validation.errors.personType}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationPersonPhone">
                            {props.t("Person Phone")}
                          </Label>
                          <Input
                            tag={InputMask}
                            mask={"0(999) 999-9999"}
                            name="phone"
                            placeholder={props.t("Person Phone")}
                            type="text"
                            className="form-control"
                            id="validationPersonPhone"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationEmail">
                            {props.t("Person Email")}
                          </Label>
                          <Input
                            name="email"
                            placeholder={props.t("Person Email")}
                            type="text"
                            className="form-control"
                            id="validationEmail"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationDescription">
                            {props.t("Description")}
                          </Label>
                          <Input
                            name="description"
                            placeholder={props.t("Description")}
                            type="textarea"
                            className="form-control"
                            id="validationDescription"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{marginBottom:'-20px'}}>
                      <Col md="4">
                        <Label>{props.t("Branches")}</Label>
                      </Col>
                      {/* <Col md="1">
                        <Label>{props.t("Add or Remove")}</Label>
                      </Col> */}
                    </Row>
                    {validation.values.Branches?.map((variant, idx) => (
                      <Row key={idx}>
                        <Col md="4" className="mb-0 mt-0">
                          <FormGroup className="mb-1 relative">
                            <Label> </Label>
                            <select
                              id={`Branches[${idx}]`}
                              className={
                                validation.touched.brand &&
                                validation.errors.brand
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                              name={`Branches[${idx}]`}
                              onChange={e => {
                                if (
                                  !validation.values.Branches.includes(
                                    e.target.value
                                  ) ||
                                  e.target.value == ""
                                ) {
                                  validation.handleChange(e)
                                }
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.Branches[idx] || ""}
                            >
                              <option value="">{props.t("Select")}</option>
                              {branches?.map((item, idx) => (
                                <option key={idx} value={item._id}>
                                  {item.Company?.name} - {item.name} - {item?.city}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="1" className="me-3 mb-0 mt-0">
                          <Label> </Label>
                          <div className="d-flex">
                            <a
                              className="me-2 btn-add"
                              onClick={() => {
                                let newObj = ""
                                let newList = insert(
                                  validation.values.Branches,
                                  idx + 1,
                                  newObj
                                )
                                validation.setFieldValue("Branches", newList)
                              }}
                            >
                              <img />
                            </a>
                            {idx > 0 ? (
                              <a
                                className="btn-remove"
                                onClick={() => {
                                  let newList = [...validation.values.Branches]
                                  newList.splice(idx, 1)
                                  validation.setFieldValue("Branches", newList)
                                }}
                              >
                                <img />
                              </a>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    ))}
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PersonCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(PersonCreate)
