import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  getCampaign as onGet,
  updateCampaign as onSave,
  getBrands as onGetBrands,
  brandsResetError as onClearError,
  clearCampaign as onClearCampaign,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import Dropzone from "react-dropzone"
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"

const CampaignUpdate = props => {
  //meta title
  document.title = props.t("Edit Campaign")

  const [data, setData] = useState(null)

  const [selectedFiles, setselectedFiles] = useState([])
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const [text, setText] = useState()

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE
  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Edit Campaign"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, brands, campaign, loading } = useSelector(state => ({
    error: state.Campaigns?.error,
    brands: state.Brands?.data,
    campaign: state.Campaigns?.campaign,
    loading: state.Campaigns?.loading || state.Brands?.loading || false,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(campaign)) {
      dispatch(onGet(params.id))
    }
    if (isEmpty(brands)) {
      dispatch(onGetBrands())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(campaign) && !isEmpty(brands)) {
      const temp_data = { ...campaign }
      //console.log("temp_data", temp_data)
      setData(temp_data)
      default_value_setting(temp_data)
      setTimeout(() => {
        dispatch(onClearCampaign())
      }, 300)
    }
  }, [campaign, brands])

  const default_value_setting = async temp_data => {
    for await (let key of Object.keys(validation.values)) {
      try {
        console.log("key", key, temp_data[key])
        if (key == "brand" && temp_data["Brand"]) {
          console.log("Brand", key, temp_data["Brand"]?._id?.toString())
          validation.setFieldValue(key, temp_data["Brand"]?._id?.toString())
        } else if (key == "requestInfo" && temp_data[key]) {
          console.log(key, temp_data[key], new Date())
          if (isEmpty(temp_data[key])) {
            validation.setFieldValue(key, ["", ""])
          } else {
            validation.setFieldValue(key, temp_data[key])
          }
        }
        else if(key == "description"){
          const val = temp_data[key] || "";
          validation.setFieldValue(key, val);
        }
        else if (
          (key == "startDate" || key == "finishDate" || key == "publishDate") &&
          temp_data[key] &&
          !isEmpty(temp_data[key])
        ) {
          validation.setFieldValue(
            key,
            moment(temp_data[key])?.format("DD.MM.YYYY HH:mm")
          )
        } else {
          validation.setFieldValue(key, temp_data[key])
        }
      } catch (err) {
        console.error("key converter failed", key, err)
      }
    }
    if (temp_data.image) {
      let fName = temp_data.image
      let formatArr = temp_data.image.split(".")
      console.log("formatArr", formatArr)
      let format = formatArr[formatArr.length - 1]
      setselectedFiles([
        {
          path: temp_data.image,
          preview: IMAGE_BASE + temp_data.image,
          name: temp_data.image,
          type: "image/" + format,
          formattedSize: "..",
        },
      ])
    }
  }
  // const brands = [
  //   { label: "Apple", value: "apple" },
  //   { label: "Samsung", value: "samsung" },
  //   { label: "Ttech", value: "ttech" },
  // ]

  const requestList = [
    { label: "Seri Numarası", value: "seri-numarasi" },
    { label: "Barkod", value: "barcode" },
    { label: "Müşteri Adı", value: "musteri-adi" },
    { label: "Müşteri Soyadı", value: "musteri-soyadi" },
    { label: "Müşteri TC", value: "musteri-tc" },
    { label: "Müşteri Numarası", value: "musteri-numarasi" },
    { label: "Müşteri Telefon No", value: "musteri-telefon-no" },
    { label: "Müşteri E-Posta", value: "musteri-e-posta" },
    { label: "Fatura No", value: "fatura-no" },
    { label: "Fatura Tarihi", value: "fatura-tarihi" },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      publishDate: "",
      Company: "",
      startDate: "",
      finishDate: "",
      description: "",
      brand: "",
      image: "",
      requestInfo: ["", ""],
    },
    validationSchema: Yup.object({
      brand: Yup.string().required(props.t("Required")),
      name: Yup.string().required(props.t("Required")),
      publishDate: Yup.string().required(props.t("Required")),
      startDate: Yup.string().required(props.t("Required")),
      finishDate: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values, selectedFiles)
      let tempSelectedFiles = []
      if (selectedFiles.length > 0) {
        if (selectedFiles[0].name != values.image) {
          tempSelectedFiles = selectedFiles
        }
      }
      dispatch(onSave(params.id, values, tempSelectedFiles, props.history))
      //props.history.push(`/v1/campaigns/create/step2/01`)
    },
  })

  // const onEditorStateChange = function (editorState) {
  //   setEditorState(editorState)
  //   const { blocks } = convertToRaw(editorState.getCurrentContent())
  //   /*let text = blocks.reduce((acc, item) => {
  //     acc = acc + item.text;
  //     return acc;
  //   }, "");*/
  //   let text = editorState.getCurrentContent().getPlainText("\u0001")
  //   setText(text)
  //   validation.setFieldValue(
  //     "description",
  //     draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //   )
  //   //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaigns")}
            backLink={"/v1/campaigns"}
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New Campaign")}</h4>
                  <p className="card-title-desc">
                    {props.t("New Campaign Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3 relative">
                          <Label htmlFor="brand">{props.t("Brand")}</Label>
                          <select
                            id="brand"
                            className={
                              validation.touched.brand &&
                              validation.errors.brand
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                            name="brand"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.brand || ""}
                          >
                            <option value="">{props.t("Select")}</option>
                            {brands?.map((item, idx) => (
                              <option key={idx} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <img className="form-select-img" />
                          {validation.touched.brand &&
                          validation.errors.brand ? (
                            <FormFeedback type="invalid">
                              {validation.errors.brand}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">
                            {props.t("Campaign Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("Campaign Name")}
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6" className="mb-3">
                        <Row className="relative">
                          <Dropzone
                            multiple={false}
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{props.t("Campaign Image Drop here")}</h4>
                                  <h5>{props.t("Max Size")} 2 MB</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          {!isEmpty(selectedFiles) ? (
                            <div
                              className="dropzone-previews"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mb-0 shadow-none border-dashed dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="text-end">
                                          <img alt={f.name} src={f.preview} />
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                              <div
                                className="dropzone-close"
                                onClick={() => {
                                  validation.setFieldValue("image", null)
                                  setselectedFiles([])
                                }}
                              >
                                <img />
                              </div>
                            </div>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="publishDate">
                            {props.t("Campaign Publish Date")}
                          </Label>
                          <InputGroup className="relative">
                            <Flatpickr
                              id="publishDate"
                              name="publishDate"
                              className={
                                validation.touched.publishDate &&
                                validation.errors.publishDate
                                  ? "form-control d-block is-invalid"
                                  : "form-control d-block"
                              }
                              placeholder="gg.aa.yyyy ss:dd"
                              options={{
                                locale: Turkish,
                                enableTime: true,
                                dateFormat: "d.m.Y H:i",
                              }}
                              onChange={v => {
                                validation.setFieldValue(
                                  "publishDate",
                                  v.length > 0
                                    ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                    : ""
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.publishDate || ""}
                            />

                            <img className="form-date-img" />
                            {validation.touched.publishDate &&
                            validation.errors.publishDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.publishDate}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="startDate">
                            {props.t("Campaign Start Date")}
                          </Label>
                          <InputGroup className="relative">
                            <Flatpickr
                              id="startDate"
                              className={
                                validation.touched.startDate &&
                                validation.errors.startDate
                                  ? "form-control d-block is-invalid"
                                  : "form-control d-block"
                              }
                              placeholder="gg.aa.yyyy ss:dd"
                              options={{
                                locale: Turkish,
                                enableTime: true,
                                dateFormat: "d.m.Y H:i",
                              }}
                              onChange={v => {
                                validation.setFieldValue(
                                  "startDate",
                                  v.length > 0
                                    ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                    : ""
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.startDate || ""}
                            />
                            <img className="form-date-img" />
                            {validation.touched.startDate &&
                            validation.errors.startDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.startDate}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="finishDate">
                            {props.t("Campaign Finish Date")}
                          </Label>
                          <InputGroup className="relative">
                            <Flatpickr
                              id="finishDate"
                              className={
                                validation.touched.finishDate &&
                                validation.errors.finishDate
                                  ? "form-control d-block is-invalid"
                                  : "form-control d-block"
                              }
                              placeholder="gg.aa.yyyy ss:dd"
                              options={{
                                locale: Turkish,
                                enableTime: true,
                                dateFormat: "d.m.Y H:i",
                              }}
                              onChange={v => {
                                validation.setFieldValue(
                                  "finishDate",
                                  v.length > 0
                                    ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                    : ""
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.finishDate || ""}
                            />
                            <img className="form-date-img" />
                            {validation.touched.finishDate &&
                            validation.errors.finishDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.finishDate}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="12">
                        <Label htmlFor="description">
                          {props.t("Campaign Description")}
                        </Label>
                        {/* <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChange}
                        />
                        <textarea
                          disabled
                          value={draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )}
                        /> */}
                        <CKEditor
                          id="description"
                          name="description"
                          editor={ClassicEditor}
                          data={validation.values.description}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor)
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("description", data)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label>
                            {props.t("Info to be Requested in the Campaign")}
                          </Label>
                          <Row>
                            {validation.values.requestInfo?.map((item, idx) => (
                              <Col md="4" className="mb-3 relative" key={idx}>
                                <select
                                  id={`requestInfo[${idx}]`}
                                  name={`requestInfo[${idx}]`}
                                  className="form-select"
                                  onChange={e => {
                                    console.log(
                                      validation.values.requestInfo.length,
                                      idx,
                                      e.target.value
                                    )
                                    //validation.handleChange(e)

                                    if (
                                      validation.values.requestInfo.length -
                                        1 ==
                                        idx &&
                                      e.target.value != ""
                                    ) {
                                      validation.values.requestInfo.push("")
                                      validation.setFieldValue(
                                        `requestInfo[${idx}]`,
                                        e.target.value
                                      )
                                    } else if (
                                      validation.values.requestInfo.length -
                                        1 !=
                                        idx &&
                                      validation.values.requestInfo.length !=
                                        1 &&
                                      e.target.value == ""
                                    ) {
                                      validation.values.requestInfo.splice(
                                        idx,
                                        1
                                      )
                                    } else if (
                                      !validation.values.requestInfo.includes(
                                        e.target.value
                                      ) ||
                                      e.target.value == ""
                                    ) {
                                      validation.setFieldValue(
                                        `requestInfo[${idx}]`,
                                        e.target.value
                                      )
                                    }
                                  }}
                                  value={
                                    validation.values.requestInfo[idx] || ""
                                  }
                                >
                                  <option value="">{props.t("Select")}</option>
                                  {idx == 0
                                    ? requestList
                                        .filter(
                                          x =>
                                            x.value == "seri-numarasi" ||
                                            x.value == "barcode"
                                        )
                                        .map((_item, _idx) => (
                                          <option
                                            key={_idx}
                                            value={_item.value}
                                          >
                                            {_item.label}
                                          </option>
                                        ))
                                    : requestList.map((_item, _idx) => (
                                        <option key={_idx} value={_item.value}>
                                          {_item.label}
                                        </option>
                                      ))}
                                </select>
                                <img className="form-select-img-9" />
                              </Col>
                            ))}
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      {props.t("Continue")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignUpdate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignUpdate)
