import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  getCampaignNewData as onGetNewData,
  getCampaign as onGet,
  updateCampaignNewData as onUpdate,
  postCampaignNewData as onPost,
  getBrands as onGetBrands,
  brandsResetError as onClearError,
  clearCampaign as onClearCampaign,
  campaignSuccess as onSuccess,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { ErrorMessage, useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"
import _ from "lodash"
import Loading from "components/Common/Loading"
import InputMask from "react-input-mask"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import img_empty from "./../../../assets/images/jobs.png"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"

// import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js"
// import draftToHtml from "draftjs-to-html"
// import htmlToDraft from "html-to-draftjs"

const CampaignNewData = props => {
  //meta title
  document.title = props.t("New Data")

  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const [data, setData] = useState([])
  const [nData, setNData] = useState()
  const [product, setProduct] = useState()
  const [isEdit, setIsEdit] = useState(query.get("id") ? true : false)

  const [loading2, setLoading2] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const [text, setText] = useState()

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE

  const [imageZoom, setimageZoom] = useState([img_empty])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("New Data"), link: "#" },
  ]

  const dispatch = useDispatch()
  const { error, brands, campaign, loading, success, newData } = useSelector(
    state => ({
      error: state.Campaigns?.error,
      brands: state.Brands?.data,
      campaign: state.Campaigns?.campaign,
      loading: state.Campaigns?.loading || state.Brands?.loading || false,
      success: state.Campaigns?.success,
      newData: state.Campaigns?.newData,
    })
  )

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    // validationSchema: Yup.object({
    //   Product: Yup.string().required(props.t("Required")),
    //   // name: Yup.string().required(props.t("Required")),
    //   // publishDate: Yup.string().required(props.t("Required")),
    //   // startDate: Yup.string().required(props.t("Required")),
    //   // finishDate: Yup.string().required(props.t("Required")),
    // }),
    onSubmit: values => {
      console.log("values", values, selectedFiles)
      let tempSelectedFiles = []
      let isValid = true
      for (let key of Object.keys(values)) {
        if (isEmpty(values[key])) {
          isValid = false
          if (key == "Product" || key == "SerialNumber" || key == "Variant") {
            validation.setFieldError(
              key,
              props.t(key) + " " + props.t("Not Found")
            )
          } else {
            validation.setFieldError(key, props.t("Required"))
          }
        }
      }

      console.log("validate", isValid && !isEmpty(product))
      let newData = { ...values }
      if (isValid && !isEmpty(product)) {
        //onSubmit
        newData["Product"] = product._id
        newData["Variant"] = product.variantID
        if (isEdit) {
          dispatch(onPost(params.id, newData, props.history, query.get("id")))
          console.log("update Data", newData)
        } else {
          dispatch(onPost(params.id, newData, props.history, null))
          console.log("newData", newData)
        }
      }
      // if (selectedFiles.length > 0) {
      //   if (selectedFiles[0].name != values.image) {
      //     tempSelectedFiles = selectedFiles
      //   }
      // }
      //dispatch(onSave(params.id, values, tempSelectedFiles, props.history))
    },
  })

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(campaign)) {
      dispatch(onGet(params.id))
    }
    if (isEmpty(brands)) {
      dispatch(onGetBrands())
    }
    if (!isEmpty(query.get("id"))) {
      dispatch(onGetNewData(query.get("id"), params.id))
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(campaign) && !isEmpty(newData) && isEdit) {
      for (let key of campaign.requestInfo) {
        if (!isEmpty(key)) {
          let d = newData.requestInfo.find(x => x.key == key)
          if (d) {
            validation.setFieldValue(key, d.value)
          }
        }
      }
      if (campaign.requestInfo.includes("seri-numarasi")) {
        let d = newData.requestInfo.find(x => x.key == "seri-numarasi")
        if (d) {
          Search(d.value, "seri-numarasi")
        }
      } else if (campaign.requestInfo.includes("barcode")) {
        let d = newData.requestInfo.find(x => x.key == "barcode")
        if (d) {
          Search(d.value, "barcode")
        }
      }
    }
  }, [newData])

  useEffect(() => {
    if (!isEmpty(campaign)) {
      if (!isEmpty(campaign.image)) {
        setimageZoom([IMAGE_BASE + campaign.image])
      }
      const temp_data = []
      for (let info of campaign.requestInfo) {
        let infoIndex = requestList.findIndex(x => x.value == info)
        if (infoIndex > -1) {
          temp_data.push({
            id: requestList[infoIndex].value,
            name: requestList[infoIndex].value,
            label: requestList[infoIndex].label,
            value: "",
            type: requestList[infoIndex].type,
          })
        }
      }
      setData(temp_data)
    }
  }, [campaign])

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(campaign)) {
      const temp_data = []
      let yup_obj = {}
      for (let info of campaign.requestInfo) {
        let infoIndex = requestList.findIndex(x => x.value == info)
        if (infoIndex > -1) {
          validation.setFieldValue(requestList[infoIndex].value, "", true)
        }
      }

      // validation.setFieldValue("Product", null, true)
      // if (campaign.requestInfo.includes("seri-numarasi")) {
      //   validation.setFieldValue("SerialNumber", null, true)
      //   validation.setFieldValue("Variant", null, true)
      // }

      // setTimeout(() => {
      //   dispatch(onClearCampaign())
      // }, 300)
    }
  }, [data])

  useEffect(() => {
    if (!isEmpty(success)) {
      Swal.fire(success.title, success.message, success.status).then(
        ({ isConfirmed }) => {
          dispatch(onSuccess(null))
        }
      )
    }
  }, [success])

  const requestList = [
    { label: "Seri Numarası", value: "seri-numarasi", type: "text" },
    { label: "Barkod", value: "barcode", type: "text" },
    { label: "Müşteri Adı", value: "musteri-adi", type: "text" },
    { label: "Müşteri Soyadı", value: "musteri-soyadi", type: "text" },
    { label: "Müşteri TC", value: "musteri-tc", type: "number" },
    { label: "Müşteri Numarası", value: "musteri-numarasi", type: "text" },
    { label: "Müşteri Telefon No", value: "musteri-telefon-no", type: "phone" },
    { label: "Müşteri E-Posta", value: "musteri-e-posta", type: "text" },
    { label: "Fatura No", value: "fatura-no", type: "text" },
    { label: "Fatura Tarihi", value: "fatura-tarihi", type: "date" },
  ]

  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Campaign_Continues")}
          </span>
        )
      case "wait":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Wait")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Campaign_Finish")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-light">
            {props.t("Campaign_Other")}
          </span>
        )
    }
  }

  const Search = async (search, searchType) => {
    console.log(searchType, search)

    setLoading2(true)
    let data = JSON.stringify({
      searchType: searchType,
      search: search,
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/products/search-code/${params.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => { 
        console.log(JSON.stringify(response.data))
        if (response.data.status == true) {
          let p = response.data.data
          setProduct(p)
          console.log(Object.keys(validation.values))
          if (
            searchType == "seri-numarasi" &&
            Object.keys(validation.values).includes("barcode") &&
            !isEmpty(p.barcode)
          ) {
            validation.setFieldValue("barcode", p.barcode)
          }
        } else {
          setProduct(null)
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setProduct(null)
        setLoading2(false)
        console.error(error)
        if (
          searchType == "seri-numarasi" &&
          Object.keys(validation.values).includes("barcode")
        ) {
          validation.setFieldValue("barcode", "")
        }
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("New Data")}
            backLink={`/v1/campaigns/${params.id}`}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading || loading2} />
          {isGalleryZoom ? (
            <Lightbox
              mainSrc={imageZoom[photoIndex]}
              nextSrc={imageZoom[(photoIndex + 1) % imageZoom.length]}
              prevSrc={
                imageZoom[
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                ]
              }
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % imageZoom.length)
              }}
            />
          ) : null}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New Data")}</h4>
                  <p className="card-title-desc">
                    {props.t("New Data Description")}
                  </p>

                  <Row>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          {campaign.image ? (
                            <img
                              src={IMAGE_BASE + campaign.image}
                              className="campaign-img"
                              onClick={() => {
                                setisGalleryZoom(true)
                                setphotoIndex(0)
                              }}
                            />
                          ) : (
                            <img src={img_empty} className="campaign-img" />
                          )}
                        </Col>
                        <Col md={12}>
                          <span className="title">{campaign.Brand?.name}</span>
                        </Col>
                        <Col className="text-end">
                          {cardStatusText(campaign.status)}
                        </Col>
                        <Col md={12} className="text-end">
                          <div className="date-div">
                            <div className="date-title">
                              <span>{props.t("Start Date")} : </span>
                            </div>
                            <div className="date">
                              {moment(campaign.startDate).format("DD.MM.YYYY")}
                            </div>
                          </div>
                          <div className="date-div">
                            <div className="date-title">
                              <span>{props.t("End Date")} : </span>
                            </div>
                            <div className="date">
                              {moment(campaign.finishDate).format("DD.MM.YYYY")}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="mt-2">
                          <span className="title">{campaign.name}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      {!isEmpty(product) ? (
                        <div className="alert alert-info">
                          <div>
                            {product.name}
                            {!isEmpty(product.variant)
                              ? " - " + product.variant
                              : null}{" "}
                            <code className="ms-3 me-3">{product.barcode}</code>{" "}
                            <kbd>{product.sku}</kbd>
                          </div>
                        </div>
                      ) : null}
                      <Form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          {data &&
                            data.map((item, idx) => (
                              <Col key={idx} md="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor={item.id}>{item.label}</Label>
                                  {item.type == "text" ? (
                                    <>
                                      <Input
                                        disabled={
                                          !(
                                            item.id == "seri-numarasi" ||
                                            item.id == "barcode"
                                          ) && isEmpty(product)
                                        }
                                        name={item.name}
                                        placeholder={item.label}
                                        type={item.type}
                                        className="form-control"
                                        id={item.id}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values[item.id] || ""}
                                        invalid={
                                          validation.touched[item.id] &&
                                          validation.errors[item.id]
                                            ? true
                                            : false
                                        }
                                        onBlurCapture={async e => {
                                          if (
                                            item.id == "seri-numarasi" ||
                                            item.id == "barcode"
                                          )
                                            await Search(
                                              validation.values[item.name],
                                              item.id
                                            )
                                        }}
                                        onKeyDown={async e => {
                                          if (
                                            (e.key === "Tab" ||
                                              e.key === "Enter") &&
                                            (item.id == "seri-numarasi" ||
                                              item.id == "barcode")
                                          ) {
                                            //e.preventDefault();
                                            if (
                                              item.id == "seri-numarasi" ||
                                              item.id == "barcode"
                                            )
                                              await Search(
                                                validation.values[item.name],
                                                item.id
                                              )
                                          }
                                        }}
                                      />
                                      {item.id == "seri-numarasi" ||
                                      item.id == "barcode" ? (
                                        <a
                                          onBlurCapture={async e => {
                                            await Search(
                                              validation.values[item.name],
                                              item.id
                                            )
                                          }}
                                          onClick={async () => {
                                            await Search(
                                              validation.values[item.name],
                                              item.id
                                            )
                                          }}
                                        >
                                          <img className="form-code-img" />
                                        </a>
                                      ) : null}
                                      {validation.touched[item.id] &&
                                      validation.errors[item.id] ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors[item.id]}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  ) : item.type == "date" ? (
                                    <>
                                      <InputGroup className="relative">
                                        <Flatpickr
                                          id={item.id}
                                          name={item.name}
                                          disabled={isEmpty(product)}
                                          className={
                                            validation.touched[item.id] &&
                                            validation.errors[item.id]
                                              ? "form-control d-block is-invalid"
                                              : "form-control d-block"
                                          }
                                          placeholder="gg.aa.yyyy"
                                          options={{
                                            locale: Turkish,
                                            enableTime: false,
                                            dateFormat: "d.m.Y",
                                          }}
                                          onChange={v => {
                                            validation.setFieldValue(
                                              item.id,
                                              v.length > 0
                                                ? moment(v[0]).format(
                                                    "DD.MM.YYYY"
                                                  )
                                                : ""
                                            )
                                          }}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values[item.id] || ""
                                          }
                                        />
                                        <img className="form-date-img" />
                                        {validation.touched[item.id] &&
                                        validation.errors[item.id] ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors[item.id]}
                                          </FormFeedback>
                                        ) : null}
                                      </InputGroup>
                                    </>
                                  ) : item.type == "phone" ? (
                                    <>
                                      <Input
                                        tag={InputMask}
                                        disabled={isEmpty(product)}
                                        mask={"0(999) 999-9999"}
                                        name={item.name}
                                        placeholder={item.label}
                                        type={item.type}
                                        className="form-control"
                                        id={item.id}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values[item.id] || ""}
                                        invalid={
                                          validation.touched[item.id] &&
                                          validation.errors[item.id]
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched[item.id] &&
                                      validation.errors[item.id] ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors[item.id]}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            ))}
                        </Row>
                        {!isEmpty(validation.errors) ? (
                          <Row>
                            <Col md={12}>
                              <div className="alert alert-danger">
                                {Object.keys(validation.errors)?.map(
                                  (key, idx) => (
                                    <div key={idx}>
                                      {requestList.find(x => x.value == key)
                                        ?.label || props.t(key)}{" "}
                                      : {validation.errors[key]}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          </Row>
                        ) : null}

                        <Button
                          color="primary"
                          type="submit"
                          disabled={isEmpty(product)}
                        >
                          {props.t("Save")}
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignNewData.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignNewData)
