import { 
  NOTIFICATION,
  NOTIFICATION_API_ERROR,
  NOTIFICATION_LIST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_MINI,
  NOTIFICATION_MINI_SUCCESS,
  NOTIFICATION_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  unReadCount: null,
  detail: {},
  data: [],
  miniData: [],
}

const Notification = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION:
    case NOTIFICATION_LIST:
    case NOTIFICATION_MINI:
      state = {
        ...state,
        loading: true,
      }
      break
    case NOTIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        detail: state.payload.data,
      }
      break
    case NOTIFICATION_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: state.payload.data,
      }
      break
    case NOTIFICATION_MINI_SUCCESS:
      state = {
        ...state,
        loading: false,
        miniData: state.payload.data,
        unReadCount: state.payload.unReadCount,
      }
      break
    case NOTIFICATION_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        data: [],
        miniData: [],
        unReadCount: null
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Notification
